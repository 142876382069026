import { useEffect, useState } from "react";
import { ITranslation } from "../../../interfaces";
import { loggerService, networkService } from "../../../services";

interface DeleteBtnInterface {
  translationId: number;
  getData: () => void;
  setTextAreaValue: (value) => void;
}

const DeleteBtn = ({
  translationId,
  getData,
  setTextAreaValue,
}: DeleteBtnInterface) => {
  const handleDelete = async () => {
    try {
      const data = await networkService.delete<ITranslation>(
        `/translations/${translationId}`
      );
      setTextAreaValue("");
      getData();
    } catch (error) {
      loggerService.error(error);
    }
  };
  return (
    <button onClick={handleDelete}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-5 h-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
        />
      </svg>
    </button>
  );
};

interface IClickToApprovBtnProps {
  value: string;
  labelId: number;
  languageId: number;
  setButtonType: (value: 0 | 1 | 2) => void;
  translationId: number;
  getData: () => void;
}

const ClickToApprovBtn = ({
  value,
  labelId,
  languageId,
  setButtonType,
  translationId,
  getData,
}: IClickToApprovBtnProps) => {
  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      if (translationId !== undefined) {
        const data = await networkService.put<ITranslation>(
          `/translations/${translationId}`,
          {
            labelId,
            languageId,
            value,
          }
        );
      } else {
        const data = await networkService.post<ITranslation>(`/translations`, {
          labelId,
          languageId,
          value,
        });
      }
      getData();

      setButtonType(1);
    } catch (error) {
      loggerService.error(error);
    }
  };
  return (
    <button onClick={handleUpdate} className="flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="#45e500"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.5 12.75l6 6 9-13.5"
        />
      </svg>

      <span className="pl-3 text-green-400">Click to Approve & Lock</span>
    </button>
  );
};

const ApproveAndLockedBtn = () => {
  return (
    <div className="flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="#a2a2a2"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
        />
      </svg>

      <span className="pl-3 text-gray-400">Approved & Locked</span>
    </div>
  );
};

const handleTranslationAddOrUpdate = async (
  value,
  labelId,
  languageId,
  setButtonType,
  translationId,
  getData
) => {
  try {
    if (value && value.trim() !== "") {
      if (translationId !== undefined) {
        const data = await networkService.put<ITranslation>(
          `/translations/${translationId}`,
          {
            labelId,
            languageId,
            value,
          }
        );
      } else {
        const data = await networkService.post<ITranslation>(`/translations`, {
          labelId,
          languageId,
          value,
        });
      }
      getData();

      setButtonType(1);
    }
  } catch (error) {
    loggerService.error(error);
  }
};

interface LanguageInputInterface {
  value: string;
  placeholder: string;
  // 0 - deletebtn, 1 -> ApproveAndLock, 2-> ClicktoApprove
  btnType: 0 | 1 | 2;
  lan: string;
  translationId: number;
  labelId: number;
  languageId: number;
  getData: () => void;
  fieldMaxLength: number;
}

const LabelInput: React.FC<LanguageInputInterface> = ({
  value,
  placeholder,
  btnType,
  lan,
  translationId,
  labelId,
  languageId,
  getData,
  fieldMaxLength,
}) => {
  const [textAreaValue, setTextAreaValue] = useState(value || "");

  useEffect(() => {
    setTextAreaValue(value || "");
  }, [value]);

  const [buttonType, setButtonType] = useState<0 | 1 | 2>(btnType);
  const [visible, setVisible] = useState<boolean>(false);
  const handleSelect = (term: string) => {
    setTextAreaValue(term);
    setVisible(false);
    setButtonType(2);
  };

  return (
    <div
      className={`w-full relative ${
        visible ? "border-blue-600 rounded-t-md" : "border-gray-300"
      } border `}
    >
      <div className="grid grid-cols-[1fr_3fr] divide-x justify-center items-center">
        <div className="pl-3 flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            // fill={value === textAreaValue ? "#00AC47" : "#B7B9C1"}
            fill={translationId && value.trim() !== "" ? "#00AC47" : "#B7B9C1"}
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
              clipRule="evenodd"
            />
          </svg>

          <span className="pl-3">{lan}</span>
        </div>
        <div>
          <textarea
            placeholder={placeholder?.length > 0 ? placeholder : "Click To Add"}
            maxLength={fieldMaxLength}
            className={`w-full border-none  placeholder-black align-middle h-10 rounded-tr-md ${
              value?.length > 0 ? "" : "placeholder-gray-300"
            }`}
            value={textAreaValue}
            onChange={(e) => {
              setTextAreaValue(e.target.value);
              setButtonType(2);
              if (e.target.value === value) {
                setButtonType(btnType);
              }
            }}
            onFocus={(e) => {
              // setVisible(true);
            }}
            onBlur={(e) => {
              // if (textAreaValue?.length === 0) {
              //   setTextAreaValue(value);
              //   setButtonType(btnType);
              // }
              handleTranslationAddOrUpdate(
                textAreaValue,
                labelId,
                languageId,
                setButtonType,
                translationId,
                getData
              );
              // setTimeout(() => {
              //   setVisible(false);
              // }, 200);
            }}
          />

          {value?.length > 0 ? (
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <DeleteBtn
                translationId={translationId}
                getData={getData}
                setTextAreaValue={setTextAreaValue}
              />

              {/* {buttonType === 0 ? (
                <DeleteBtn translationId={translationId} getData={getData} />
              ) : buttonType === 1 ? (
                <ApproveAndLockedBtn />
              ) : (
                <ClickToApprovBtn
                  value={textAreaValue}
                  labelId={labelId}
                  languageId={languageId}
                  setButtonType={setButtonType}
                  translationId={translationId}
                  getData={getData}
                />
              )} */}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* <GlossarySearch
        visible={visible}
        searchText={textAreaValue}
        handleSelect={handleSelect}
      /> */}
    </div>
  );
};

export default LabelInput;

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { ICollaborator } from "../../../interfaces";
import { loggerService, networkService } from "../../../services";
import { AddOverlayLayout, FieldError } from "../../shared/components";
import { CollaboratorsInterface } from "../loc-collaborators.component";

const newCollaboratorsSchema = Yup.object().shape({
  email: Yup.string().email().required("Please enter Email."),
  name: Yup.string().required("Please enter Name."),
});

interface AddCollaboratorsInterface {
  collaboratorsCreateOpen: boolean;
  setCollaboratorsCreateOpne: (value: boolean) => void;
  setCollaboratorsData: (value: CollaboratorsInterface[]) => void;
  collaboratorsData: CollaboratorsInterface[];
}

const AddCollaborators: React.FC<AddCollaboratorsInterface> = ({
  collaboratorsCreateOpen,
  setCollaboratorsCreateOpne,
  setCollaboratorsData,
  collaboratorsData,
}) => {
  const handleAddCollaborators = async (values, { resetForm }) => {
    try {
      const data = await networkService.post<ICollaborator>(
        "/collaborators",
        values
      );
      setCollaboratorsData([...collaboratorsData, data]);
    } catch (error) {
      loggerService.error(error);
    }

    resetForm();
  };

  return (
    <AddOverlayLayout
      title="Add Collaborators"
      overlayOpen={collaboratorsCreateOpen}
      setOverlayOpen={setCollaboratorsCreateOpne}
    >
      <Formik
        initialValues={{
          email: "",
          name: "",
        }}
        onSubmit={handleAddCollaborators}
        validationSchema={newCollaboratorsSchema}
      >
        <Form className="flex flex-col flex-1">
          <div className="flex-1">
            <div>
              <label className="text-sm font-medium text-gray-600">Email</label>

              <Field
                type="text"
                name="email"
                placeholder="someone@xyz.com"
                className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
              />
              <ErrorMessage name="email">
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>
            <div>
              <label className="text-sm font-medium text-gray-600">Name</label>

              <Field
                type="text"
                name="name"
                placeholder="John Doe"
                className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
              />
              <ErrorMessage name="name">
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>
          </div>

          <button
            type="submit"
            className="inline-flex justify-center w-full px-6 py-3 mt-8 mb-2 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
          >
            Add Collaborators
          </button>
        </Form>
      </Formik>
    </AddOverlayLayout>
  );
};

export default AddCollaborators;

import { AxiosError } from "axios";
import { helper } from "../../app/shared/utils";
import { IServerError } from "../../interfaces";

const responseInterceptor = (err: AxiosError<IServerError>) => {
  let parsedError = helper.getPropertyValue<IServerError>(err, "response.data");

  if (!parsedError) {
    parsedError = {
      code: helper.getPropertyValue<number>(err, "response.status"),
      message: err.message,
    };
  }

  return Promise.reject<IServerError>(parsedError);
};

export default responseInterceptor;

import LanguageFileCard from "./language-file-card.component";

interface FileCardInterface {
  setFileExportOpen: (value: boolean) => void;
  languageCounts: any;
  totalLabelsCount: number,
  getData: () => void;
}

const FileCardComponent: React.FC<FileCardInterface> = ({
  setFileExportOpen,
  languageCounts,
  totalLabelsCount,
  getData
}) => {

  const handleView = () => {
    window.open("/123/json", "_blank");
  };



  return (
    <div className="bg-white divide-y divide-gray-200 mb-10 rounded-md">
      {/* <div className="flex justify-between items-center p-5 px-10">
        <span>Pre-login.json</span>
        <ul className="flex justify-center items-center">
          <li className="ml-10 items-center cursor-pointer">
            <button
              onClick={handleView}
              className="ml-4 mx-1 text-sm flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.2"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>

              <span className="pl-2">View</span>
            </button>
          </li>
          <li
            onClick={() => {
              setFileExportOpen(true);
            }}
            className="ml-10 flex items-center cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.2"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
              />
            </svg>

            <span className="ml-4 mx-1 text-sm">Export</span>
          </li>
          <li className="ml-10 flex items-center cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.2"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>

            <span className="ml-4 mx-1 text-sm">Remove</span>
          </li>
        </ul>
      </div> */}
      <div className="py-6 px-6">
        <div className="grid gap-6 mt-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
          {languageCounts.length > 0 && languageCounts.map((languageCount) => (
            <LanguageFileCard
              key={languageCount.language.id}
              languageId={languageCount.language.id}
              language={languageCount.language.name}
              percentCompleted={languageCount.customValueCount ? Math.round((100 * languageCount.customValueCount) / totalLabelsCount) : 0}
              totalLabelsCount={totalLabelsCount}
              totalLabelsWithCustomValues={languageCount.customValueCount}
              showFileOptions={true}
              getData={getData}
            />
          ))}

        </div>
      </div>
    </div>
  );
};

export default FileCardComponent;

import { Outlet, useLocation } from "react-router-dom";
import { useHeaderButtonOverlay, useSidebarStore } from "../../store";
import { Header, HeaderButton } from "../shared/components";
import ConfigProjectDetail from "./config-project-detail.component";
import { useState } from "react";
import { toast } from "react-toastify";

const ConfigurationProvider: React.FC = () => {
  const location = useLocation();
  const toggleSidebar = useSidebarStore((state) => state.toggleSidebar);
  const toggleOverLay = useHeaderButtonOverlay((state) => state.toggleOverLay);

  const [showHelp, setShowHelp] = useState(true);

  const flag =
    location.pathname === "/configurations" ||
    location.pathname === "/configurations/";
  return (
    <>
      <Header title="Configurations" setSidebarOpen={toggleSidebar}>
        {/* <HeaderButton name={"Add new project"} toggleOverLay={toggleOverLay} /> */}
      </Header>
      {flag ? (
        <div className="overflow-y-auto">
          <ConfigProjectDetail />
          {showHelp && (
            <div className="absolute right-5 rounded-3xl w-96 bottom-5 p-10 flex flex-col bg-primary/10 gap-3">
              <div className="flex items-start justify-between mb-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="none"
                  viewBox="0 0 50 50"
                >
                  <rect width="50" height="50" fill="#546CCC" rx="6.579"></rect>
                  <path
                    fill="#fff"
                    d="M19.934 20.033c3.772 0 5.658 2.204 5.658 6.612v8.092c0 .614-.186 1.097-.56 1.448-.35.35-.843.526-1.48.526-.635 0-1.14-.175-1.512-.526-.373-.351-.56-.834-.56-1.448v-8.092c0-1.162-.24-2.006-.723-2.533-.46-.548-1.196-.822-2.204-.822-1.184 0-2.139.373-2.862 1.118-.702.746-1.053 1.744-1.053 2.994v7.335c0 .614-.186 1.097-.559 1.448-.373.35-.877.526-1.513.526-.636 0-1.14-.175-1.513-.526-.351-.351-.527-.834-.527-1.448v-19.67c0-.57.187-1.031.56-1.382.394-.351.899-.527 1.513-.527.614 0 1.107.165 1.48.494.373.329.56.778.56 1.349v7.664a5.294 5.294 0 012.17-1.94c.921-.461 1.963-.692 3.125-.692z"
                  ></path>
                  <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M37.228 13.253a1.645 1.645 0 011 2.1L30.99 35.748a1.645 1.645 0 01-3.1-1.1l7.237-20.395a1.645 1.645 0 012.1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <button
                  onClick={() => {
                    setShowHelp(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="none"
                    viewBox="0 0 18 18"
                  >
                    <path
                      stroke="#B7B9C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 4.5l-9 9M4.5 4.5l9 9"
                    ></path>
                  </svg>
                </button>
              </div>
              <span className="text-2xl font-bold">
                Need help with any Configuration changes?
              </span>
              <span>
                mail us at{" "}
                <a href="mailto:support@hlth.dev">support@hlth.dev</a>
              </span>
              <button
                className="flex items-center gap-2 bg-primary/20 p-2 px-4 w-fit rounded mt-5"
                onClick={() => {
                  toast.success("Email address copied to clipboard");
                  navigator.clipboard.writeText("support@hlth.dev ");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <g
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    clipPath="url(#clip0_814_25685)"
                  >
                    <path d="M13.333 6h-6C6.597 6 6 6.597 6 7.333v6c0 .737.597 1.334 1.333 1.334h6c.737 0 1.334-.597 1.334-1.334v-6c0-.736-.597-1.333-1.334-1.333z"></path>
                    <path d="M3.333 10h-.666a1.333 1.333 0 01-1.334-1.333v-6a1.333 1.333 0 011.334-1.334h6A1.333 1.333 0 0110 2.667v.666"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_814_25685">
                      <path fill="#fff" d="M0 0H16V16H0z"></path>
                    </clipPath>
                  </defs>
                </svg>
                <div>Copy Email Address</div>
              </button>
            </div>
          )}
        </div>
      ) : (
        <main className="flex-1 overflow-y-auto ">
          <Outlet />
        </main>
      )}
    </>
  );
};

export default ConfigurationProvider;

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../../store";

const Login: React.FC = () => {
  const authToken = useAuthStore((state) => state.token);
  const setAuthUser = useAuthStore((state) => state.setAuthUser);
  const setAuthToken = useAuthStore((state) => state.setAuthToken);
  const { user, isLoading, getIdTokenClaims, loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    if (isLoading || user) return;

    setAuthUser(null);
    loginWithRedirect();
  }, [isLoading, user, loginWithRedirect, setAuthUser]);

  React.useEffect(() => {
    if (!user) return;
    const getAccessToken = async () => {
      const token = await getIdTokenClaims();
      setAuthToken(token.__raw);
    };

    getAccessToken();
  }, [getIdTokenClaims, setAuthToken, user]);

  return authToken ? <Navigate to="/organization" /> : null;
};

export default Login;

import { useLocation } from 'react-router-dom';
import { useOrganizationStore } from '../../../store';

const useProjectData = () => {
  const location = useLocation();
  const getProjects = useOrganizationStore((state) => state.getProjects);
  const projectDetails = getProjects();
  const projectId = location.pathname.split("/")[3];

  const projectData = projectDetails.find((project) => project.module === projectId);

  return {projectData, projectId, projectDetails};
};

export default useProjectData;

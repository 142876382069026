import { useEffect, useState } from "react";
import { networkService } from "../../services";
import { IEhrSystem } from "../../interfaces";

const EHRIntegration: React.FC = () => {
  const [ehrIntegration, setEHRIntegration] = useState<any>(null);

  const getEHRIntegration = () => {
    networkService
      .get<IEhrSystem[]>("/configurations/ehr-configurations")
      .then((res) => {
        setEHRIntegration(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEHRIntegration();
  }, []);

  return (
    <section className="py-6 w-1/2 ">
      <div className="px-4 lg:px-8 sm:px-6">
        {ehrIntegration &&
          ehrIntegration.map((item: any) => (
            <div className="bg-white rounded-lg mt-4" key={item.id}>
              <div className="px-6 py-6 border-b">
                <div className="sm:flex sm:items-center sm:justify-between">
                  <div className="mb-3 pt-0 w-full">
                    <h2 className="flex py-2 items-center space-x-2 text-sm text-gray-400 ">
                      <span>Service</span>
                    </h2>
                    <input
                      type="text"
                      disabled
                      value={item.ehrSystem ?? "N.A"}
                      placeholder="Placeholder"
                      className="px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white  rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full disabled:bg-slate-50"
                    />
                  </div>
                </div>
                <div className="sm:flex sm:items-center sm:justify-between">
                  <div className="mb-3 pt-0 w-full">
                    <h2 className="flex py-2 items-center space-x-2 text-sm text-gray-400 ">
                      <span>Tenant (Organization) Name</span>
                    </h2>
                    <input
                      type="text"
                      disabled
                      value={item.apiTenantName ?? "N.A"}
                      placeholder="Placeholder"
                      className="px-3 py-3 placeholder-slate-300 text-slate-600 relative  bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full disabled:bg-slate-50"
                    />
                  </div>
                </div>
                <div className="sm:flex sm:items-center sm:justify-between">
                  <div className="mb-3 pt-0 w-full">
                    <h2 className="flex py-2 items-center space-x-2 text-sm text-gray-400 ">
                      <span>Instance (Environment) Name</span>
                    </h2>
                    <input
                      type="text"
                      disabled
                      value={item.apiEnvironmentName ?? "N.A"}
                      placeholder="Placeholder"
                      className="px-3 py-3 placeholder-slate-300 text-slate-600 relative bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full disabled:bg-slate-50"
                    />
                  </div>
                </div>
                <div className="sm:flex sm:items-center sm:justify-between">
                  <div className="mb-3 pt-0 w-full">
                    <h2 className="flex py-2 items-center space-x-3 text-sm text-gray-400 ">
                      <span>Client Name</span>
                    </h2>
                    <input
                      type="text"
                      disabled
                      value={item.organization.email ?? "N.A"}
                      placeholder="Placeholder"
                      className="px-3 py-3 placeholder-slate-300 text-slate-600 relative  bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full disabled:bg-slate-50"
                    />
                  </div>
                </div>
                <div className="sm:flex sm:items-center sm:justify-between">
                  <div className="mb-3 pt-0 w-full">
                    <h2 className="flex py-2 items-center space-x-3 text-sm text-gray-400 ">
                      <span>Client Secret</span>
                    </h2>
                    <input
                      type="password"
                      disabled
                      value={item.apiClientSecret ?? "N.A"}
                      placeholder="Placeholder"
                      className="px-3 py-3 placeholder-slate-300 text-slate-600 relative  bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full disabled:bg-slate-50"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};

export default EHRIntegration;

import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";

interface IPagination {
  onPageChange: (x) => void;
  totalRows: number;
  rowsPerPage: number;
  pageCount: number;
  resetToFirstPage?: boolean;
}

const Pagination: React.FC<IPagination> = ({
  onPageChange,
  totalRows,
  rowsPerPage,
  pageCount,
  resetToFirstPage = true,
}) => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataKey, setDataKey] = useState(0);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    onPageChange(selectedPage.selected + 1);
  };

  useEffect(() => {
    if (resetToFirstPage) {
      setCurrentPage(1);
      setDataKey((prevDataKey) => prevDataKey + 1);
    }
  }, [totalRows, location.pathname]);

  const startIndex = (currentPage - 1) * rowsPerPage + 1;
  const endIndex = Math.min(currentPage * rowsPerPage, totalRows);

  return (
    <div className="flex items-center justify-between px-4 py-4 bg-white">
      <p className="text-sm text-gray-700">
        Showing <span className="font-medium">{startIndex}</span> to{" "}
        <span className="font-medium">{endIndex}</span> of{" "}
        <span className="font-medium">{totalRows}</span> results
      </p>
      <ReactPaginate
        key={dataKey}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        previousLabel="<"
        nextLabel=">"
        breakLabel="..."
        breakClassName="py-2 px-3"
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        containerClassName="flex items-center justify-center space-x-1"
        pageClassName="text-blue-500 hover:text-blue-700 cursor-pointer"
        pageLinkClassName="block py-2 px-3 rounded-md"
        previousClassName={`text-blue-500 hover:text-blue-700 cursor-pointer ${
          currentPage === 1 ? "disabled:opacity-60 cursor-not-allowed" : ""
        }`}
        nextClassName={`text-blue-500 hover:text-blue-700 cursor-pointer ${
          currentPage === pageCount
            ? "disabled:opacity-60 cursor-not-allowed"
            : ""
        }`}
        previousLinkClassName={`block py-2 px-3 rounded-md ${
          currentPage === 1 ? "disabled:opacity-60 cursor-not-allowed" : ""
        }`}
        nextLinkClassName={`block py-2 px-3 rounded-md ${
          currentPage === pageCount
            ? "disabled:opacity-60 cursor-not-allowed"
            : ""
        }`}
        activeClassName="bg-blue-500 text-white"
        disabledClassName="opacity-60"
      />
    </div>
  );
};

export default Pagination;

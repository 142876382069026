import { Link } from "react-router-dom";
import { IProjectDetails } from "../../interfaces";
import { useOrganizationStore } from "../../store";

const Projects: React.FC = () => {
  const getProjects = useOrganizationStore((state) => state.getProjects);
  const projectDetails: IProjectDetails[] = getProjects();

  return (
    <main className="flex-1 px-4 py-8 space-y-4 overflow-y-auto lg:px-8 sm:px-6">
      <h1 className="text-3xl font-medium text-gray-800">Projects</h1>

      <div className="flex flex-col mt-12">
        <div className="-my-2 overflow-x-auto xl:-mx-8">
          {projectDetails.length > 0 && (
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden rounded-xl">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="px-6 pt-4 pr-16 pb-7 whitespace-nowrap"
                      >
                        <button className="flex items-center space-x-3 text-xs font-normal text-left text-gray-500 uppercase hover:text-primary">
                          <span>App Name</span>

                          <svg
                            className="h-3"
                            viewBox="0 0 10 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z"
                              fill="currentColor"
                              stroke="#FEFEFE"
                              strokeWidth="0.1"
                            />
                            <path
                              d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z"
                              fill="currentColor"
                              stroke="#FEFEFE"
                              strokeWidth="0.1"
                            />
                            <path
                              d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z"
                              fill="currentColor"
                              stroke="#FEFEFE"
                              strokeWidth="0.3"
                            />
                          </svg>
                        </button>
                      </th>

                      <th
                        scope="col"
                        className="px-6 pt-4 pr-16 pb-7 whitespace-nowrap"
                      ></th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200 rounded-md">
                    {Object.values(projectDetails).map((project) => (
                      <tr
                        className="transition-colors duration-300 hover:bg-gray-50"
                        key={project.module}
                      >
                        <td className="px-6 py-4 text-lg font-medium text-gray-800 whitespace-nowrap">
                          {project.module}
                        </td>

                        <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
                          <div className="flex items-center lg:justify-end">
                            <Link
                              to={`/usage-analytics/projects/${project.module}`}
                              className="flex items-center justify-center p-2 text-sm font-medium text-center text-gray-500 transition-colors duration-200 transform bg-gray-100 rounded-full focus:outline-none hover:bg-gray-200"
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.91669 7H11.0834"
                                  stroke="#565759"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M7 2.91675L11.0833 7.00008L7 11.0834"
                                  stroke="#565759"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <div className="text-center p-12">
          {projectDetails.length === 0 && "No Data Found!"}
        </div>
      </div>
    </main>
  );
};

export default Projects;

import React, { useEffect, useState } from "react";
import { ICurrentMember, IEditMember, IMember } from "../../interfaces";
import { loggerService, networkService } from "../../services";
import dateService from "../../services/date.service";
import { useHeaderButtonOverlay, useSidebarStore } from "../../store";
import { Header, HeaderButton } from "../shared/components";
import GenericTable from "../shared/components/table.component";
import AddEditMember from "./components/add-edit-member.component";

const EditMember: React.FC<IEditMember> = ({
  id,
  setCurrentMember,
  membersData,
  setIsEditing,
  toggleOverLay,
  toggleSidebar,
  setCurrentMemberId,
}) => {
  const handleEditMember = () => {
    setIsEditing(true);
    toggleOverLay(true);
    toggleSidebar(true);

    const curretMemberData = membersData.find((item) => item.id === id);

    setCurrentMemberId(id);

    setCurrentMember({
      firstName: curretMemberData.firstName,
      lastName: curretMemberData.lastName,
      role: curretMemberData.roles[0],
      email: curretMemberData.email,
    });
  };

  return (
    <button
      onClick={handleEditMember}
      className="text-blue-600 hover:text-blue-900 flex gap-1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="w-5 h-5"
      >
        <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
        <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
      </svg>

      <div>Edit</div>
    </button>
  );
};

const Members: React.FC = () => {
  const toggleSidebar = useSidebarStore((state) => state.toggleSidebar);
  const toggleOverLay = useHeaderButtonOverlay((state) => state.toggleOverLay);

  const [membersData, setMembersData] = useState<IMember[]>([]);
  const [membersSearch, setMembersSearch] = useState("");

  const [isEditing, setIsEditing] = useState(false);
  const [currentMember, setCurrentMember] = useState<ICurrentMember>();

  const [currentMemberId, setCurrentMemberId] = useState<number>();

  const getAllMembers = async () => {
    try {
      const data = await networkService.get<IMember[]>(
        "/organization/serviceProviders"
      );

      setMembersData(data);
    } catch (error) {
      loggerService.error(error);
    }
  };

  useEffect(() => {
    getAllMembers();
  }, []);

  const handleAddMember = () => {
    setIsEditing(false);
    toggleOverLay(true);
  };

  const colDefs = [
    {
      name: "User Name",
      type: "string",
      field: "username"
    },
    {
      name: "Role",
      type: "string",
      field: "role"
    },
    {
      name: "Date Created",
      type: "date",
      field: "datecreated"
    }
  ];

  return (
    <>
      <AddEditMember
        isEditing={isEditing}
        membersData={membersData}
        currentMember={currentMember}
        currentMemberId={currentMemberId}
        setMembersData={setMembersData}
      />

      <Header title="Member" setSidebarOpen={toggleSidebar}>
        <HeaderButton name={"Add new member"} toggleOverLay={handleAddMember} />
      </Header>
      <main className="flex-1 px-4 py-8 space-y-4 overflow-y-auto lg:px-8 sm:px-6">
        <div className="flex flex-col mt-12">
          <div className="-my-2 overflow-x-auto xl:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden rounded-xl">
                {membersData.length > 0 && (
                  <GenericTable
                    column={colDefs}
                    row={membersData.map((item) => ({
                      username: item.firstName + " " + item.lastName,
                      role: item.roles[0],
                      datecreated: dateService.convertToDDMMYYYYFormat(
                        item.createdAt
                      ),
                      // lastlogin: item.username,
                      editMember: (
                        <EditMember
                          id={item.id}
                          membersData={membersData}
                          setMembersData={setMembersData}
                          setIsEditing={setIsEditing}
                          toggleOverLay={toggleOverLay}
                          toggleSidebar={toggleSidebar}
                          setCurrentMember={setCurrentMember}
                          setCurrentMemberId={setCurrentMemberId}
                        />
                      ),
                    }))}
                    searchField="username"
                    searchTerm={membersSearch}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Members;

// Dropdown.tsx
import React, { useEffect, useState } from "react";

interface Option {
  name: string;
  value: string;
  children?: Option[];
}

interface DropdownProps {
  options: Option[];
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  selectedOptions,
  setSelectedOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const divRef = React.useRef<HTMLDivElement>(null);

  const handleCheckboxChange = (option: Option) => {
    const isSelected = selectedOptions.includes(option.value);

    if (isSelected) {
      // Unselect the current option and its children
      const updatedSelection = selectedOptions.filter(
        (selected) =>
          selected !== option.value &&
          !getOptionValues(option.children).includes(selected)
      );
      setSelectedOptions(updatedSelection);
    } else {
      // Select the current option and its children
      const updatedSelection = [
        ...selectedOptions,
        option.value,
        ...getOptionValues(option.children),
      ].filter((v, i, a) => a.indexOf(v) === i);
      setSelectedOptions(updatedSelection);
    }
  };

  const getOptionValues = (optionArray?: Option[]): string[] => {
    if (!optionArray) return [];
    return optionArray.reduce((values, currentOption) => {
      return [
        ...values,
        currentOption.value,
        ...getOptionValues(currentOption.children),
      ];
    }, [] as string[]);
  };

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Add a click event listener when the component mounts
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        divRef.current &&
        !divRef.current.contains(e.target) &&
        window.innerWidth > 1024
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [divRef, setIsOpen]);

  const renderOptions = (option: Option) => {
    return (
      <div key={option?.value} className="ml-4 w-full pr-6">
        <label className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer">
          <input
            type="checkbox"
            value={option?.value}
            checked={selectedOptions.includes(option.value)}
            onChange={() => handleCheckboxChange(option)}
            className="mr-2"
          />
          <span>{option.name}</span>
        </label>
        {option?.children && (
          <div>{option.children.map((child) => renderOptions(child))}</div>
        )}
      </div>
    );
  };

  return (
    <div ref={divRef} className="relative text-left">
      <div className="inline-block">
        <button
          type="button"
          onClick={handleToggleDropdown}
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 focus:outline-none focus:ring focus:border-blue-300"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
        >
          {selectedOptions.length > 0
            ? selectedOptions.join(", ")
            : "Select options"}
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute z-50 left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none min-w-fit">
          <div
            className="py-1 w-fit"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options.map((option) => renderOptions(option))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;

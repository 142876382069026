import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ILanguageData } from "../../interfaces";
import { loggerService, networkService } from "../../services";
import { useProjectData } from "../shared/hooks";
import CreateLabel from "./components/create-label.component";
import LabelInput from "./components/label-input.component";
import LanguageFileCard from "./components/language-file-card.component";

interface TranslationInterface {
  isActiveTab: boolean;
}
const Translations: React.FC<TranslationInterface> = ({ isActiveTab }) => {
  const [translationData, setTranslationData] = useState<ILanguageData>();
  const [searchedValue, setSearchedValue] = useState("");
  const [openCreateLabel, setCreateLabel] = useState(false);
  const [mappedLabelValues, setMappedLabelValues] = useState([]);

  const searchAfterKeys = 3;

  const location = useLocation();

  const { projectData, projectId, projectDetails } = useProjectData();

  const getData = async () => {
    try {
      const data = await networkService.get<ILanguageData>(
        `/translations?module=${projectData.module}`
      );
      setTranslationData(data);
    } catch (error) {
      loggerService.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, [location.pathname]);

  useEffect(() => {
    if (isActiveTab) {
      getData();
    }
  }, [isActiveTab]);

  const refCollapseDiv = useRef<HTMLDivElement>();

  const handleCollapse = () => {
    if (refCollapseDiv.current.className === "md:hidden") {
      refCollapseDiv.current.className = "px-12 py-8 transition-all";
    } else {
      refCollapseDiv.current.className = "md:hidden";
    }
  };

  const handleSearch = (query) => {
    if (query.length >= searchAfterKeys) {
      setSearchedValue(query);
      const filteredLabels = mappedLabelValues.filter((label) =>
        label.code.toLowerCase().includes(query.toLowerCase())
      );
      setMappedLabelValues(filteredLabels);
    } else {
      setSearchedValue(query);
      setMappedLabelValues(mappedLabelValues);
    }
  };

  const languageCardDetails = () => {
    const valueStore = new Map();

    translationData.defaultValues.forEach((value) => {
      if (value.value !== "") {
        const languageId = value.languageId;
        const counts = valueStore.get(languageId) || {
          defaultValueCount: 0,
          customValueCount: 0,
        };
        counts.defaultValueCount++;
        valueStore.set(languageId, counts);
      }
    });

    translationData.customValues.forEach((value) => {
      if (value.value !== "") {
        const languageId = value.languageId;
        const counts = valueStore.get(languageId) || {
          defaultValueCount: 0,
          customValueCount: 0,
        };
        counts.customValueCount++;
        valueStore.set(languageId, counts);
      }
    });

    const languageCounts = translationData.languages.map((language) => {
      const counts = valueStore.get(language.id) || {
        defaultValueCount: 0,
        customValueCount: 0,
      };

      return {
        defaultValueCount: counts.defaultValueCount,
        customValueCount: counts.customValueCount,
        language,
      };
    });
    const totalLabelsCount = translationData.labels.length;

    return (
      <div className="flex overflow-x-auto space-x-4">
        {languageCounts.map((languageCount) => (
          <LanguageFileCard
            key={languageCount.language.id}
            language={languageCount.language.name}
            percentCompleted={
              languageCount.customValueCount
                ? Math.round(
                    (100 * languageCount.customValueCount) / totalLabelsCount
                  )
                : 0
            }
            totalLabelsCount={totalLabelsCount}
            totalLabelsWithCustomValues={languageCount.customValueCount}
          />
        ))}
      </div>
    );
  };

  const updatedTranslationLabels = useMemo(() => {
    if (!translationData) {
      return;
    }
    const data = translationData;

    const defaultValuesMap = new Map(
      data.defaultValues.map((value) => [
        `${value.labelId}_${value.languageId}`,
        { value: value.value || "", defaultValueId: value.id || null },
      ])
    );

    const customValuesMap = new Map(
      data.customValues.map((value) => [
        `${value.labelId}_${value.languageId}`,
        { value: value.value || "", customValueId: value.id || null },
      ])
    );
    let mappedLabels = [];
    if (searchedValue.length < searchAfterKeys) {
      mappedLabels = data.labels.map((label) => {
        const mappedLanguages = data.languages.map((language) => {
          const defaultValue = defaultValuesMap.get(
            `${label.id}_${language.id}`
          );
          const customValue = customValuesMap.get(`${label.id}_${language.id}`);

          return {
            ...language,
            placeholder: defaultValue || "",
            customValue: customValue || "",
          };
        });

        return {
          ...label,
          languages: mappedLanguages,
        };
      });
      setMappedLabelValues(mappedLabels);
    }

    const filteredLabels =
      searchedValue.length >= searchAfterKeys
        ? mappedLabelValues
        : mappedLabels;

    const rows = [];
    return filteredLabels.map(
      ({ id: labelId, code: labelCode, description, languages, maxLength }) => {
        return (
          <div key={labelId} className="mb-5 rounded-md">
            <div className="flex items-center">
              <span className="mr-2">{labelCode}</span>

              <div className="relative group">
                <svg
                  data-tooltip-target="tooltip-default"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-info-circle cursor-pointer"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />{" "}
                </svg>
                <div className="absolute z-10 top-0 left-full ml-2 bg-gray-800 text-white text-sm px-2 py-1 rounded w-[230px] opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                  {description}
                </div>
              </div>
            </div>

            {languages.map(({ id: languageId, name }) => (
              <LabelInput
                key={`${labelId}_${languageId}`}
                value={customValuesMap.get(`${labelId}_${languageId}`)?.value}
                placeholder={
                  defaultValuesMap.get(`${labelId}_${languageId}`)?.value
                }
                translationId={
                  customValuesMap.get(`${labelId}_${languageId}`)?.customValueId
                }
                btnType={rows.length === 0 ? 0 : 1}
                lan={name}
                labelId={labelId}
                languageId={languageId}
                getData={getData}
                fieldMaxLength={maxLength}
              />
            ))}
          </div>
        );
      }
    );
  }, [translationData]);

  return (
    <div>
      <div className="flex items-center justify-between px-4 lg:px-8 sm:px-6">
        <div></div>
        <button
          onClick={() => {
            setCreateLabel(true);
          }}
          className="bg-primary rounded-md text-white py-2 px-4"
        >
          Create Label
        </button>
      </div>

      <CreateLabel open={openCreateLabel} setOpen={setCreateLabel} getData ={getData}/>

      {translationData?.languages.length > 0 ? (
        <section className="py-6">
          <div className="px-4 lg:px-8 sm:px-6">
            <div className="bg-white rounded-lg">
              <div className="px-6 py-4 border-b">
                <div className="sm:flex sm:items-end sm:justify-end mb-4">
                  <div className="flex items-center mt-4 space-x-4 sm:mt-0 ">
                    <div className="relative flex-1">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <svg
                          className="w-5 h-5 text-gray-400"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                          ></path>
                        </svg>
                      </span>

                      <input
                        type="text"
                        className="md:w-72 w-full py-2 pl-10 pr-4 border-gray-400 text-gray-700 bg-white border rounded-md"
                        placeholder="Search"
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </div>

                    <button
                      className="text-gray-600 focus:outline-none"
                      onClick={handleCollapse}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 15L12 9L18 15"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="flex overflow-x-auto">
                  {translationData ? languageCardDetails() : ""}
                </div>
              </div>

              <div ref={refCollapseDiv} className="px-12 py-8 ">
                {translationData ? updatedTranslationLabels : ""}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div className="text-center p-12">No Data Found!</div>
      )}
    </div>
  );
};

export default Translations;

import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

interface LanguageInputInterface {
  handleAddLanguage: (language: { locale: string; name: string, id: number }) => void;
}

const LanguageInput: React.FC<LanguageInputInterface> = ({
  handleAddLanguage,
}) => {
  // TODO: Get the list of languages from the API
  const defaultLanguage = [
    { id: 29, locale: "en", name: "English" },
    {
      id: 30,
      locale: "es",
      name: "Spanish",
    },
    { id: 37, locale: "fr", name: "French" },
    { id: 38, locale: "gr", name: "German" },
    { id: 39, locale: "ro", name: "Romanian" },
  ];

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <Menu as="div" className="relative inline-block text-left ">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md  bg-white py-2 text-sm font-medium text-gray-700 ">
          Add New Language
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-10 mt-2 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
          <div className="py-1 overflow-y-auto">
            <div className="p-5">
              <input
                type="text"
                className="rounded-sm w-full"
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {defaultLanguage
              .filter((language) =>
                language.name.toLowerCase().startsWith(searchTerm.toLowerCase())
                // return language[0]
                //   .toLowerCase()
                //   .startsWith(searchTerm.toLowerCase());
              )
              .map((language, languageIdx) => (
                <Menu.Item key={languageIdx}>
                  {({ active }) => (
                    <button
                      onClick={() =>
                        handleAddLanguage({
                          locale: language.locale,
                          name: language.name,
                          id: language.id
                        })
                      }
                      className={classNames(
                        active ? " text-gray-900 block" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      {language.name}
                    </button>
                  )}
                </Menu.Item>
              ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default LanguageInput;

import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { IPromptTemplate } from "../../../interfaces/ai-models.interface";
import BinIcon from "../../shared/icons/bin.icon";
import { extractTextInBraces } from "../ai.helper";

interface PromptProps {
  prompt: IPromptTemplate | null;
  handleUpdateOrSave: (prompt: string, promptId: string) => void;
  handleDelete: (promptId: string) => void;
}

const Prompt: React.FC<PromptProps> = ({
  prompt,
  handleUpdateOrSave,
  handleDelete,
}) => {
  const [value, setValue] = useState(prompt?.text || "");

  return (
    <div className="col-span-4 grid grid-cols-10 gap-4 border p-2 border-black">
      <div className="col-span-6">
        <textarea
          value={value}
          placeholder="Enter prompt"
          onChange={(e) => setValue(e.target.value)}
          className="w-full"
        />
      </div>
      <div className="col-span-2 text-center flex items-center flex-wrap justify-center gap-2">
        {extractTextInBraces(value).map((label) => {
          return (
            <div className="px-2 py-1 border rounded-full border-black text-center">
              {label}
            </div>
          );
        })}
      </div>
      <div className="col-span-2 flex items-center justify-end gap-2">
        {prompt?.promptId && (
          <button
            type="button"
            onClick={() => {
              handleDelete(prompt.promptId);
            }}
          >
            <BinIcon />
          </button>
        )}
        <button
          type="button"
          disabled={prompt?.text === value}
          onClick={() => {
            handleUpdateOrSave(value, prompt?.promptId || "");
            if (!prompt?.promptId) {
              setValue("");
            }
          }}
          className="px-3 py-2 bg-black text-white rounded-md disabled:opacity-50"
        >
          {prompt?.promptId ? (
            <FormattedMessage id="form.update" />
          ) : (
            <FormattedMessage id="form.save" />
          )}
        </button>
      </div>
    </div>
  );
};

export default Prompt;

import { AddOverlayLayout } from "../../shared/components";

import { useProjectData } from "../../shared/hooks";
import { loggerService, networkService } from "../../../services";
import { IExportFile } from "../../../interfaces";
import { toast } from "react-toastify";
import { useState } from "react";

const downloadFile = ({ data, fileName, fileType }) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};



interface FilesExportInterface {
  FileExportOpen: boolean;
  setFileExportOpen: (value: boolean) => void;
  languageCounts: any;
  totalLabelsCount: number;
}

const FilesExport: React.FC<FilesExportInterface> = ({
  FileExportOpen,
  setFileExportOpen,
  languageCounts,
  totalLabelsCount
}) => {
  const { projectData, projectId, projectDetails } = useProjectData();
  const [selectedLanguagesToExport, setSelectedLanguagesToExport] = useState([]);

  const exportToJson = async (e) => {
    e.preventDefault();
    try {
      let fileUrl = '';
      if (selectedLanguagesToExport.length > 0) {
        fileUrl = `files/localization/download?module=${projectData.module}&languageId=${selectedLanguagesToExport[0]}`
      } else {
        fileUrl = `files/localization/download?module=${projectData.module}`;
      }
      const fileData = await networkService.get<IExportFile[]>(
        fileUrl
      );
      if (fileData.length > 0) {
        downloadFile({
          data: JSON.stringify(fileData),
          fileName: "users.json",
          fileType: "text/json",
        });
      } else {
        toast.error('No Data Found!');
      }
    } catch (error) {
      loggerService.error(error);
    }

  };

  const handleCheckboxChange = (event) => {
    const languageId = Number(event.target.value);
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedLanguagesToExport((prevSelectedLanguages) => [
        ...prevSelectedLanguages,
        languageId,
      ]);
    } else {
      setSelectedLanguagesToExport((prevSelectedLanguages) =>
        prevSelectedLanguages.filter((id) => id !== languageId)
      );
    }
  };

  return (
    <AddOverlayLayout
      title="Select Translations to Export"
      overlayOpen={FileExportOpen}
      setOverlayOpen={setFileExportOpen}
    >
      <form className="flex flex-col flex-1">
        <div className="flex-1">
          {languageCounts.length > 0 && languageCounts.map((languageCount) => (
            <div className="flex-1" key={languageCount.language.id}>
              <div className="flex items-center mb-6">
                <input
                  id="checked-checkbox"
                  type="checkbox"
                  value={languageCount.language.id}
                  checked={selectedLanguagesToExport.includes(Number(languageCount.language.id))}
                  onChange={handleCheckboxChange}
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <p className="ml-2 font-semibold">{languageCount.language.name}</p>
                <label
                  htmlFor="checked-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  ({Math.round((100 * languageCount.customValueCount) / totalLabelsCount)})%
                </label>
              </div>
            </div>
          ))}


        </div>
        <button
          type="button"
          onClick={exportToJson}
          // disabled={selectedLanguagesToExport.length === 0}
          // ${selectedLanguagesToExport.length === 0 && "disabled:opacity-60 cursor-not-allowed"}
          className={`inline-flex justify-center w-full px-6 py-3 mt-8 mb-2 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80 `}
        >
          Export
        </button>
      </form>
    </AddOverlayLayout>
  );
};

export default FilesExport;

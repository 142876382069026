import { useQuery } from "@tanstack/react-query";
import { Outlet, useParams } from "react-router-dom";
import { IAiTemplate } from "../../interfaces/ai-models.interface";
import { networkService } from "../../services";
import appConstants from "../shared/config";

const AiLayout: React.FC = () => {
  const { useCaseId, templateId } = useParams<{
    useCaseId: string;
    templateId: string;
  }>();

  const { data: template } = useQuery({
    queryKey: ["template"],
    queryFn: () => {
      return networkService.get<IAiTemplate[]>(
        `${appConstants.urls.aiUrl}/usecases/${useCaseId}/templates/${templateId}`
      );
    },
  });

  if (!template) return null;

  return (
    <>
      <Outlet
        context={{
          template: template,
          useCaseId: useCaseId,
        }}
      />
    </>
  );
};

export default AiLayout;

import React from "react";
import { IntlProvider } from "react-intl";
import { Route, Routes } from "react-router-dom";
import { DEFAULT_LOCALE, messages } from "../i18n";
import { RoutePermission } from "../models";
import useLangStore from "../store/lang.store";
import AiModelsLayout from "./ai/ai-layout.component";
import PlaygroundOne from "./ai/ai-playground-one";
import PlaygroundTwo from "./ai/ai-playground-two";
import AiModels from "./ai/ai.component";
import AuditLogs from "./audit-logs/components/audit-logs-data.component";
import Login from "./auth/login.component";
import Logout from "./auth/logout.component";
import Organization from "./auth/organization.component";
import Profile from "./auth/profile.component";
import { ConfigurationProvider } from "./configuration";
import Home from "./home";
import Layout from "./layout";
import { LocalizationProvider } from "./localization";
import ShowJson from "./localization/components/show-json.component";
import ProjectDetail from "./localization/loc-project-detail.component";
import Members from "./members/members.component";
import NotificationsProvider from "./notifications/notifications-provider.component";
import Releasenote from "./release-notes/release-note.component";
import { NotFound, PrivateRoute, Unauthorized } from "./shared/components";
import { RoutePermissionType } from "./shared/config";
import { AnalyticsProvider, Rawdata } from "./usage-analytics";

function AppRouter() {
  const loginPermission = React.useMemo(
    () => RoutePermission.factory([], RoutePermissionType.LoginRequired),
    []
  );
  const tokenPermission = React.useMemo(
    () => RoutePermission.factory([], RoutePermissionType.TokenRequired),
    []
  );
  const lang = useLangStore((state) => state.value);
  const locale = lang;

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={DEFAULT_LOCALE}
      messages={messages[locale]}
    >
      <Routes>
        <Route index element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="/home" element={<Home />} />

        <Route element={<PrivateRoute routePermission={tokenPermission} />}>
          <Route path="/organization" element={<Organization />} />
        </Route>

        <Route element={<PrivateRoute routePermission={loginPermission} />}>
          <Route path=":id/json" element={<ShowJson />} />
          <Route element={<Layout />}>
            {/* Localization routes */}
            <Route
              path="localization/projects"
              element={<LocalizationProvider />}
            >
              <Route path=":id" element={<ProjectDetail />} />
            </Route>

            {/* usage-analytics routes */}

            <Route
              path="usage-analytics/projects"
              element={<AnalyticsProvider />}
            >
              <Route path=":id" element={<Rawdata />} />
            </Route>

            <Route
              path="notifications/projects"
              element={<NotificationsProvider />}
            >
              <Route path=":id" element={<Rawdata />} />
            </Route>

            {/* Release Notes routes */}
            <Route path="/release-note" element={<Releasenote />} />
            {/* Members routes */}
            <Route path="members" element={<Members />} />
            {/* Configuration */}
            <Route path="configurations/" element={<ConfigurationProvider />}>
              {/* <Route path=":id" element={<ProjectDetail1 />} /> */}
            </Route>

            {/* Service Provider Profile*/}
            <Route path="profile" element={<Profile />}></Route>

            {/* audit-logs routes */}
            <Route path="audit-logs" element={<AuditLogs />}></Route>
            {/* Ai models */}
            <Route path="ai" element={<AiModels />} />
            <Route element={<AiModelsLayout />}>
              <Route
                path="playground-one/:useCaseId/:templateId"
                element={<PlaygroundOne />}
              />
              <Route
                path="playground-two/:useCaseId/:templateId"
                element={<PlaygroundTwo />}
              />
            </Route>
          </Route>
        </Route>

        {/* generic routes */}
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="error" element={<NotFound />} />
        <Route path="not-found" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </IntlProvider>
  );
}

export default AppRouter;

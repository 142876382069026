import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  IAddMemberProps,
  ICurrentMember,
  IMember,
  IModalConfirm,
} from "../../../interfaces";
import { ModalConfirmModel } from "../../../models";
import { loggerService, networkService } from "../../../services";
import { useAuthStore, useHeaderButtonOverlay } from "../../../store";
import {
  AddOverlayLayout,
  FieldError,
  ModalConfirm,
} from "../../shared/components";

const newMembersSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Please enter Email."),
  firstName: Yup.string().required("Please enter First Name."),
  lastName: Yup.string().required("Please enter Last Name."),
  role: Yup.string().required(),
});

const AddEditMember: React.FC<IAddMemberProps> = ({
  isEditing,
  currentMember,
  currentMemberId,
  setMembersData,
  membersData,
}) => {
  const [formResetKey, setFormResetKey] = useState(0);

  const [addButtonOverLayOpen, toggleOverLay] = useHeaderButtonOverlay(
    (state) => [state.addButtonOverLayOpen, state.toggleOverLay]
  );

  const [user] = useAuthStore((state) => [state.user]);
  const updateAuthUser = useAuthStore((state) => state.updateAuthUser);

  useEffect(() => {
    setFormResetKey((prevKey) => prevKey + 1);
  }, [addButtonOverLayOpen]);

  const [modalConfirm, setModalConfirm] = React.useState<IModalConfirm>(
    () => new ModalConfirmModel()
  );

  const handleAddMember = async (values: ICurrentMember) => {
    const valuesToSend = { ...values, role: [values.role], active: true };

    try {
      const data = await networkService.post<IMember>(
        "/serviceProviders",
        valuesToSend
      );

      setMembersData([data, ...membersData]);
      toggleOverLay(false);
      toast.success("New member added successfully");
      return true;
    } catch (error) {
      if (error.message.includes("email")) {
        toast.error("Email already exists, please use another email");
        return false;
      }
    }
  };

  const handleEditMember = async (values: ICurrentMember) => {
    let valuesToSend = { ...values, role: [values.role] };

    try {
      const data = await networkService.put<IMember>(
        `/serviceProviders/${currentMemberId}`,
        valuesToSend
      );
      const updatedMembersData: IMember[] = membersData.map((member) => {
        if (member.id === currentMemberId) {
          return data;
        }
        return member;
      });
      setMembersData(updatedMembersData);
      
      if (user.id === currentMemberId) {
        updateAuthUser({
          firstName: valuesToSend.firstName,
          lastName: valuesToSend.lastName,
        });
      }
      toggleOverLay(false);
      toast.success("Member updated successfully");
    } catch (error) {
      loggerService.error(error);
    }
  };

  const handleFormSubmit = (values: ICurrentMember, { resetForm }) => {
    if (isEditing) {
      handleEditMember(values);
    } else {
      handleAddMember(values).then((result) => {
        if (result) {
          resetForm();
        }
      });
    }
  };

  const handleMemberDelete = React.useCallback(
    (member, id) => {
      setModalConfirm({
        open: true,
        title: "Deactivate Member?",
        message: `Are you sure you want to deactivate ${member.email} from the organization`,
        onConfirm: async () => {
          try {
            // close modal
            setModalConfirm((prevState) => ({ ...prevState, open: false }));

            await networkService.delete(`/serviceProviders/${id}`);

            setMembersData(membersData.filter((member) => member.id !== id));

            toast.success("Member deactivated successfully");
          } catch (error) {
            loggerService.error(error.mesaage);
            toast.error(error.mesaage);
          }
        },
      });
    },
    [membersData]
  );

  return (
    <AddOverlayLayout
      title={isEditing ? "Edit Member" : "Add Member"}
      overlayOpen={addButtonOverLayOpen}
      setOverlayOpen={toggleOverLay}
    >
      <Formik
        initialValues={{
          email: isEditing ? currentMember.email : "",
          firstName: isEditing ? currentMember.firstName : "",
          lastName: isEditing ? currentMember.lastName : "",
          role: isEditing ? currentMember.role : "OWNER",
        }}
        key={formResetKey}
        onSubmit={handleFormSubmit}
        validationSchema={newMembersSchema}
        enableReinitialize
      >
        {({ dirty }) => (
          <Form className="flex flex-col flex-1">
            <div className="flex-1">
              <div className="flex gap-3 w-full">
                <div className="flex-1">
                  <label className="text-sm font-medium text-gray-600">
                    First Name{" "}
                  </label>
                  <Field
                    name="firstName"
                    type="text"
                    id="firstName"
                    placeholder="John"
                    className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                  />
                  <ErrorMessage name="firstName">
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </div>
                <div className="flex-1">
                  <label className="text-sm font-medium text-gray-600">
                    Last Name{" "}
                  </label>
                  <Field
                    name="lastName"
                    id="lastName"
                    type="text"
                    placeholder="Doe"
                    className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                  />
                  <ErrorMessage name="lastName">
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </div>
              </div>
              <div className="mt-5">
                <label
                  htmlFor="role"
                  className="text-sm font-medium text-gray-600"
                >
                  Role
                </label>
                <Field
                  as="select"
                  name="role"
                  id="role"
                  disabled={isEditing ? true : false}
                  className={
                    isEditing
                      ? "block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm bg-gray-100 cursor-not-allowed"
                      : "block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                  }
                >
                  <option value="OWNER">Owner</option>
                  <option value="MEMBER">Member</option>
                </Field>
              </div>

              <div className="mt-5">
                <label className="text-sm font-medium text-gray-600">
                  Email{" "}
                </label>

                <Field
                  name="email"
                  type="email"
                  disabled={isEditing}
                  placeholder="johndoe@gmail.com"
                  className={
                    isEditing
                      ? "block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm bg-gray-100 cursor-not-allowed"
                      : "block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                  }
                />
                <ErrorMessage name="email">
                  {(msg) => <FieldError message={msg} />}
                </ErrorMessage>
              </div>
              {isEditing && (
                <div className="flex items-center lg:justify-start mt-5">
                  <button
                    disabled
                    onClick={() => {
                      toggleOverLay(false);
                      // handleMemberDelete(currentMember, currentMemberId);
                    }}
                    type="button"
                    className="flex items-center justify-center text-sm font-medium text-red-500 transition-colors duration-200 transform rounded-full focus:outline-none hover:text-red-900"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 4H3.33333H14"
                        stroke="currentColor"
                        strokeLinecap="round"
                      />
                      <path
                        d="M5.33333 4.00016V2.66683C5.33333 2.31321 5.4738 1.97407 5.72385 1.72402C5.9739 1.47397 6.31304 1.3335 6.66666 1.3335H9.33333C9.68695 1.3335 10.0261 1.47397 10.2761 1.72402C10.5262 1.97407 10.6667 2.31321 10.6667 2.66683V4.00016M12.6667 4.00016V13.3335C12.6667 13.6871 12.5262 14.0263 12.2761 14.2763C12.0261 14.5264 11.687 14.6668 11.3333 14.6668H4.66666C4.31304 14.6668 3.9739 14.5264 3.72385 14.2763C3.4738 14.0263 3.33333 13.6871 3.33333 13.3335V4.00016H12.6667Z"
                        stroke="currentColor"
                        strokeLinecap="round"
                      />
                      <path
                        d="M6.66667 7.3335V11.3335"
                        stroke="currentColor"
                        strokeLinecap="round"
                      />
                      <path
                        d="M9.33333 7.3335V11.3335"
                        stroke="currentColor"
                        strokeLinecap="round"
                      />
                    </svg>
                    <span className="pl-2 text-gray-500">
                      Deactivate Member
                    </span>
                  </button>
                </div>
              )}
            </div>
            <button
              type="submit"
              disabled={!dirty}
              className="inline-flex justify-center w-full px-6 py-3 mt-8 mb-2 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80 disabled:bg-opacity-60"
            >
              {isEditing ? "Update Member" : "Add Member"}
            </button>
          </Form>
        )}
      </Formik>
      <ModalConfirm {...modalConfirm} setProps={setModalConfirm} />
    </AddOverlayLayout>
  );
};

export default AddEditMember;

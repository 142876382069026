import produce from "immer";
import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../app/shared/config";
import { IProjectDetails } from "../interfaces";

interface IOrganizationInfo {
  projects: IProjectDetails[];
  orgId: number;
  organization_code: string;
  getProjects: () => IProjectDetails[];
  setProjects: (x: IProjectDetails[]) => void;
  setOrgId: (x: number) => void;
  setOrganizationCode: (x: string) => void;
}
const useOrganizationStore = create<IOrganizationInfo>()(
  persist(
    devtools(
      (set, get) => ({
        projects: [],
        orgId: 0,
        organization_code: "",
        setOrgId: (newOrgId) =>
          set(
            produce((state: any) => {
              state.orgId = newOrgId;
            })
          ),
        setOrganizationCode: (newOrganizationCode) =>
          set(
            produce((state: any) => {
              state.organization_code = newOrganizationCode;
            })
          ),
        getProjects: () => {
          const projects = get().projects;
          return projects;
        },
        setProjects: (newProjects) =>
          set(
            produce((state: any) => {
              state.projects = newProjects;
            })
          ),
      }),
      { name: "userOrganizations", serialize: { options: true } }
    ),
    {
      name: "projectDetails", // unique name
      getStorage: () => appConstants.keys.storage,
    }
  )
);

export default useOrganizationStore;

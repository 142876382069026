import { useEffect, useState } from "react";
import AddFile from "./components/add-file.component";
import FileCardComponent from "./components/file-card.component";

import { useLocation } from "react-router-dom";
import { ILanguageData } from "../../interfaces";
import { loggerService, networkService } from "../../services";
import { useProjectData } from "../shared/hooks";
import FilesExport from "./components/files-export.component";

interface FilesInterface {
  isActiveTab: boolean;
}
const Files: React.FC<FilesInterface> = ({ isActiveTab }) => {
  const [FileExportOpen, setFileExportOpen] = useState(false);
  const [filesCreateOpen, setFilesCreateOpen] = useState(false);

  const [filesData, setFilesData] = useState<ILanguageData>();
  const location = useLocation();
  const { projectData, projectId, projectDetails } = useProjectData();

  const [languageCounts, setLanguageCounts] = useState([]);
  const [totalLabelsCount, setTotalLabelsCount] = useState(0);
  const [unfilteredLanguageCounts, setUnfilteredLanguageCounts] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const searchAfterKeys = 3;

  const getData = async (fromChild = false) => {
    try {
      if (fromChild) {
        languageCardDetails();
        return;
      }
      const data = await networkService.get<ILanguageData>(
        `/translations?module=${projectData.module}`
      );
      setFilesData(data);
      return data;
    } catch (error) {
      loggerService.error(error);
    }
  };

  const handleSearch = (query) => {
    if (query.length >= searchAfterKeys) {
      setSearchedValue(query);
      const filteredLabels = languageCounts.filter((language) =>
        language.language.name.toLowerCase().includes(query.toLowerCase())
      );
      setLanguageCounts(filteredLabels);
    } else {
      setSearchedValue(query);
      setLanguageCounts(unfilteredLanguageCounts);
    }
  };

  useEffect(() => {
    languageCardDetails();
  }, [location.pathname, isActiveTab]);

  const languageCardDetails = () => {
    getData().then((data) => {
      const filesData = data;
      if (!filesData) {
        return;
      }

      const languageCounts = filesData.languages.map((language) => {
        const defaultValueCount = filesData.defaultValues.reduce(
          (count, value) => {
            if (value.languageId === language.id && value.value !== "") {
              return count + 1;
            }
            return count;
          },
          0
        );

        const customValueCount = filesData.customValues.reduce(
          (count, value) => {
            if (value.languageId === language.id && value.value !== "") {
              return count + 1;
            }
            return count;
          },
          0
        );

        return {
          defaultValueCount,
          customValueCount,
          language,
        };
      });
      const totalLabelsCount = filesData.labels.length;
      setTotalLabelsCount(totalLabelsCount);
      setLanguageCounts(languageCounts);
      setUnfilteredLanguageCounts(languageCounts);
    });
  };

  return (
    <>
      <FilesExport
        FileExportOpen={FileExportOpen}
        setFileExportOpen={setFileExportOpen}
        languageCounts={languageCounts}
        totalLabelsCount={totalLabelsCount}
      />

      <AddFile
        addFileOpen={filesCreateOpen}
        setAddFileOpen={setFilesCreateOpen}
        projectData={projectData}
        getData={getData}
      />
      {languageCounts?.length !== 0 ? (
        <section className="px-4 py-6 lg:px-8 sm:px-6 ">
          <div className="sm:flex sm:items-center sm:justify-between ">
            <h2 className="text-xl font-medium text-gray-800 ">1 File(s)</h2>

            <div className="mt-4 sm:flex sm:items-center sm:space-x-4 sm:mt-0 ">
              <div className="relative flex-1">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg
                    className="w-5 h-5 text-gray-400"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    ></path>
                  </svg>
                </span>

                <input
                  type="text"
                  className="md:w-72 w-full py-2 pl-10 pr-4 border-gray-400 text-gray-700 bg-transparent border rounded-md"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>

              {/* <button
              onClick={() => setFilesCreateOpen(true)}
              className="px-5 w-full sm:w-auto mt-4 sm:mt-0 font-medium py-2.5 text-white rounded-lg bg-primary focus:outline-none"
            >
              Add New File
            </button> */}
            </div>
          </div>

          <div className="flex flex-col mt-8">
            <div className="-my-2 overflow-x-auto xl:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="overflow-hidden flex flex-col">
                  <FileCardComponent
                    setFileExportOpen={setFileExportOpen}
                    languageCounts={languageCounts}
                    totalLabelsCount={totalLabelsCount}
                    getData={getData}
                  />
                  {/* <FileCardComponent setFileExportOpen={setFileExportOpen} /> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div className="text-center p-12">No Data Found!</div>
      )}
    </>
  );
};

export default Files;

import { useState, useEffect, useCallback } from 'react';

const useTableSorting = (initialData) => {
  const [data, setData] = useState(initialData);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const sortData = useCallback(
    (key, direction) => {
      setData((prevData) => {
        const sortedData = [...prevData];
        sortedData.sort((a, b) => {
          const valueA = getObjectProperty(a, key);
          const valueB = getObjectProperty(b, key);

          if (valueA < valueB) {
            return direction === 'ascending' ? -1 : 1;
          }
          if (valueA > valueB) {
            return direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
        return sortedData;
      });
    },
    []
  );

  const handleSort = useCallback(
    (key) => {
        let direction: "ascending" | "descending" = "ascending";
      if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
      sortData(key, direction);
    },
    [sortConfig, sortData]
  );

  const getObjectProperty = (object, key) => {
    const keys = key.split('.');
    let value = object;
    for (let i = 0; i < keys.length; i++) {
      value = value[keys[i]];
    }
    return value;
  };

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  return { data, handleSort };
};

export default useTableSorting;

import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import dateService from "../../../services/date.service";
import { rawDataInterface } from "../analytics-raw-data.component";
import { getAnalyticsData } from "../analytics-utilities";
import { loggerService } from "../../../services";
import Datepicker from "react-tailwindcss-datepicker";
import { useDateFilterStore } from "../../../store";

interface EventRowInterface {
  setRawData: (rawData: rawDataInterface[]) => void;
  appName: string;
  setTotalRows: (value: number) => void;
}

export interface DateRangeInterface {
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
}

const AnalyticsTime: React.FC<EventRowInterface> = ({
  setRawData,
  appName,
  setTotalRows
}) => {
  const [dateRange, setDateRange] = useState<DateRangeInterface>(
    {} as DateRangeInterface
  );

  const [activeBtn, setActiveBtn] = useState(null);

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const [selectedDateValue, setSelectedDateValue] = useState({
    startDate: null,
    endDate: null,
  });

  const setSelectedDateFilter = useDateFilterStore((state) => state.setSelectedDateFilter);
  useEffect(() => {
    if (value.startDate && value.endDate) {
      setSelectedDateFilter({ startDate: value.startDate, endDate: value.endDate });
    }
  }, [setSelectedDateFilter, selectedDateValue]);

  const handleBtns = async (id) => {
    // id 1 = pastweek, 2 = past2weeks, 3 = pastmonth, 4 = past6months
    let startDate;
    if (id === 1) {
      setActiveBtn(1);
      startDate = moment().subtract(7, "days");
    } else if (id === 2) {
      setActiveBtn(2);
      startDate = moment().subtract(2, "weeks");
    } else if (id === 3) {
      setActiveBtn(3);
      startDate = moment().subtract(1, "months");
    } else {
      setActiveBtn(4);
      startDate = moment().subtract(6, "months");
    }
    let endDate = moment().toDate();
    try {
      setSelectedDateValue({ startDate: startDate, endDate: endDate });
      setSelectedDateFilter({ startDate: startDate, endDate: endDate });
      await getAnalyticsData(startDate, endDate, appName, setRawData, 1, setTotalRows);
    } catch (error) {
      loggerService.error(error);
    }
  };

  const handleDateRangeSubmit = async (values) => {
    setActiveBtn(null);
    setDateRange({
      dateRange: {
        startDate: values.startDate,
        endDate: values.endDate,
      },
    });
    setSelectedDateValue({ startDate: values.startDate, endDate: values.endDate });
    setSelectedDateFilter({ startDate: values.startDate, endDate: values.endDate });
    try {
      await getAnalyticsData(
        values.startDate,
        values.endDate,
        appName,
        setRawData,
        1, setTotalRows
      );
    } catch (error) {
      loggerService.error(error);
    }
  };

  useEffect(() => {
    setActiveBtn(null);
  }, [appName]);

  return (
    <div className="flex items-center justify-between py-8">
      <div className="flex items-center justify-center cursor-pointer w-72">
        <Datepicker
          value={value}
          startFrom={moment().subtract(1, "months").toDate()}
          maxDate={moment().toDate()}
          showFooter={true}
          placeholder={
            "dateRange" in dateRange &&
              "startDate" in dateRange.dateRange &&
              "endDate" in dateRange.dateRange
              ? dateService.convertToDDMMYYYYFormat(
                dateRange.dateRange.startDate
              ) +
              " to " +
              dateService.convertToDDMMYYYYFormat(dateRange.dateRange.endDate)
              : dateService.convertToDDMMYYYYFormat(new Date())
          }
          onChange={handleDateRangeSubmit}
        />
      </div>
      <div className="flex items-center justify-center">
        <button
          type="button"
          onClick={() => handleBtns(1)}
          className={`${activeBtn === 1
            ? "bg-primary text-white"
            : "bg-transparent text-gray-900"
            } py-2.5 px-5 mr-2 mb-2 text-sm font-medium focus:outline-none rounded-lg border border-gray-300 `}
        >
          Past Week
        </button>
        <button
          type="button"
          onClick={() => handleBtns(2)}
          className={`${activeBtn === 2
            ? "bg-primary text-white"
            : "bg-transparent text-gray-900"
            } py-2.5 px-5 mr-2 mb-2 text-sm font-medium focus:outline-none rounded-lg border border-gray-300 `}
        >
          Past 2 Weeks
        </button>
        <button
          onClick={() => handleBtns(3)}
          type="button"
          className={`${activeBtn === 3
            ? "bg-primary text-white"
            : "bg-transparent text-gray-900"
            } py-2.5 px-5 mr-2 mb-2 text-sm font-medium focus:outline-none rounded-lg border border-gray-300 `}
        >
          Past Month
        </button>
        <button
          type="button"
          onClick={() => handleBtns(4)}
          className={`${activeBtn === 4
            ? "bg-primary text-white"
            : "bg-transparent text-gray-900"
            } py-2.5 px-5 mr-2 mb-2 text-sm font-medium focus:outline-none rounded-lg border border-gray-300 `}
        >
          Past 6 Months
        </button>
      </div>
    </div>
  );
};

export default AnalyticsTime;

import { loggerService, networkService } from "../../services";
import dateService from "../../services/date.service";
import { PaginationProps } from "../shared/config";
import { IAuditData, IAuditlogs } from "./components/audit-logs-data.component";

const getAuditData = async (
  startDate: Date,
  endDate: Date,
  setRawData: (rawData: IAuditData[]) => void,
  pageNumber: number = 1,
  setTotalRows: (totalRows: number) => void
) => {
  let url = "";

  if (
    startDate !== null &&
    startDate !== undefined &&
    endDate !== null &&
    endDate !== undefined
  ) {
    const startDate_ = dateService.convertToYYYYMMDDFormat(startDate);
    const endDate_ = dateService.convertToYYYYMMDDFormat(endDate);
    url = `audit-logs?startDate=${startDate_}&endDate=${endDate_}&skip=${pageNumber}&limit=${PaginationProps.rowsPerPage}`;
  } else {
    url = `audit-logs?skip=${pageNumber}&limit=${PaginationProps.rowsPerPage}`;
  }

  try {
    networkService.get<IAuditlogs>(url).then((data) => {
      setRawData(data.events);
      if (setTotalRows) {
        setTotalRows(data.total);
      }
    });
  } catch (error) {
    loggerService.error(error);
  }
};

const handleExportData = async (selectedDates) => {
  try {
    let downloadAuditLogsUrl: string = "";
    let startDate,
      endDate: string = "";
    if (selectedDates.startDate !== null && selectedDates.endDate !== null) {
      startDate = dateService.convertToYYYYMMDDFormat(selectedDates.startDate);
      endDate = dateService.convertToYYYYMMDDFormat(selectedDates.endDate);
      downloadAuditLogsUrl = `/audit-logs/event-logs/export-csv?startDate=${startDate}&endDate=${endDate}`;
    } else {
      downloadAuditLogsUrl = `/audit-logs/event-logs/export-csv`;
    }
    const data = await networkService.get<BinaryData>(
      downloadAuditLogsUrl,
      {},
      { responseType: "blob" }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "audit-logs.xlsx");
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    loggerService.error(err);
  }
};

export { getAuditData, handleExportData };

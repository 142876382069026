import PropTypes from "prop-types";
import React, { ErrorInfo } from "react";
import { IErrorBoundary } from "../../../interfaces";
import loggerService from "../../../services/logger/logger.service";

interface IErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  IErrorBoundary,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundary) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public static propTypes = {
    message: PropTypes.string,
    fallbackUI: PropTypes.bool,
  };

  public static defaultProps = {
    fallbackUI: true,
    message:
      "Something went wrong. Please try again later or contact system administrator.",
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    loggerService.error("ErrorBoundary", error, errorInfo);
  }

  render() {
    if (this.state.hasError && this.props.fallbackUI) {
      return <h1>{this.props.message}</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

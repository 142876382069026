import dateService from "../../services/date.service";
import { uploadTos3 } from "../shared/utils/upload-to-s3";

export const getObservations = (data: any): any => {
  if (!data) return [];

  const options = data.map((item: any) => {
    return {
      name: item.observationGroupName || "NA",
      value: item.observationGroupName,
      children: item.observations.map((child: any) => {
        return {
          name: child.name,
          value: `${item.observationGroupName}-${child.name}`,
        };
      }),
    };
  });

  const uniqueOptions = options.reduce(
    (acc: any, current: any) => {
      const x = acc.find((item: any) => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    },

    []
  );

  // make their children unique
  const uniqueChildren = uniqueOptions.map((item: any) => {
    const children = item.children.reduce(
      (acc: any, current: any) => {
        const x = acc.find((item: any) => item.name === current.name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      },

      []
    );

    return {
      ...item,
      children,
    };
  });

  return uniqueChildren;
};

export const getQuestionnaires = (data: any): any => {
  return data.map((item) => {
    return {
      name: item.name,
      value: item.name,
    };
  });
};

export function extractTextInBraces(inputString: string): string[] {
  const regex = /\{([^}]+)\}/g;
  const matches = inputString.match(regex);
  if (matches) {
    const extractedText = matches.map((match) =>
      match.substring(1, match.length - 1)
    );
    return extractedText;
  } else {
    return [];
  }
}

export async function createJsonFile(fileName: string, data: any) {
  const file = new File([JSON.stringify(data)], fileName, {
    type: "application/json",
  });
  return await uploadTos3(file, "Error uploading file", "Uploading file...");
}

export const extractQuestionnairesData = async (
  data: any,
  dateRange: any,
  selectedNestedOptions
) => {
  const filteredData = data.filter((item) => {
    const date = dateService.convertDateObjToMoment(item.date);
    const startDate = dateService.convertDateObjToMoment(dateRange.startDate);
    const endDate = dateService.convertDateObjToMoment(dateRange.endDate);

    if (
      date.isBetween(startDate, endDate, "day", "[]") &&
      selectedNestedOptions.includes(item.name)
    ) {
      return true;
    }

    return false;
  });

  return await createJsonFile("questionnaires.json", filteredData);
};

export const extractObservationsData = async (
  data: any,
  dateRange: any,
  selectedNestedOptions
) => {
  const filteredData = data
    .filter((item) => {
      const date = dateService.convertDateObjToMoment(item.date);
      const startDate = dateService.convertDateObjToMoment(dateRange.startDate);
      const endDate = dateService.convertDateObjToMoment(dateRange.endDate);

      if (
        date.isBetween(startDate, endDate, "day", "[]") &&
        selectedNestedOptions.includes(item.observationGroupName)
      ) {
        return true;
      }

      return false;
    })
    .map((item) => {
      return {
        ...item,
        observations: item.observations.filter((observation) => {
          return selectedNestedOptions.includes(
            `${item.observationGroupName}-${observation.name}`
          );
        }),
      };
    });

  return await createJsonFile("observations.json", filteredData);
};

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { loggerService, networkService } from "../../../services";
import { IExportFile, IModalConfirm } from "../../../interfaces";
import { useProjectData } from "../../shared/hooks";
import { toast } from "react-toastify";
import { ModalConfirmModel } from "../../../models";
import React from "react";
import { ModalConfirm } from "../../shared/components";
import AddFile from "./add-file.component";

interface LanguageFileCardInterface {
  language: string;
  percentCompleted: number;
  totalLabelsCount?: number;
  totalLabelsWithCustomValues?: number;
  languageId?: number;
  showFileOptions?: boolean;
  getData?: (fromChild: boolean) => void;
}

const downloadFile = ({ data, fileName, fileType }) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

const LanguageFileCard: React.FC<LanguageFileCardInterface> = ({
  language,
  percentCompleted,
  totalLabelsCount,
  totalLabelsWithCustomValues,
  languageId,
  showFileOptions = false,
  getData,
}) => {
  const [displayLanguageFileCardDetails, setDisplayLanguageFileCardDetails] =
    useState(true);
  const location = useLocation();

  const [filesCreateOpen, setFilesCreateOpen] = useState(false);

  const { projectData, projectId, projectDetails } = useProjectData();
  const [modalConfirm, setModalConfirm] = React.useState<IModalConfirm>(
    () => new ModalConfirmModel()
  );

  useEffect(() => {
    setDisplayLanguageFileCardDetails(true);
  }, [location.pathname]);

  const exportToJson = async (e, languageid) => {
    e.preventDefault();
    try {
      const fileData = await networkService.get<IExportFile[]>(
        `files/localization/download?module=${projectData.module}&languageId=${languageid}`
      );
      if (fileData.length > 0) {
        downloadFile({
          data: JSON.stringify(fileData),
          fileName: `${language}.json`,
          fileType: "text/json",
        });
      } else {
        toast.error("No Data Found!");
      }
    } catch (error) {
      loggerService.error(error);
    }
  };

  const removeJson = async (e, languageid) => {
    e.preventDefault();
    setModalConfirm({
      open: true,
      title: "Remove Translations?",
      message: `Are you sure you want to remove this translation file?`,
      onConfirm: async () => {
        try {
          const fileData = await networkService.delete(
            `files/localization/delete?module=${projectData.module}&languageId=${languageid}`
          );
          setModalConfirm((prevState) => ({ ...prevState, open: false }));
          getData(true);
        } catch (error) {
          loggerService.error(error);
          setModalConfirm((prevState) => ({ ...prevState, open: false }));
        }
      },
    });
  };

  return (
    <div
      className={`p-4 border rounded-lg w-[300px] ${
        displayLanguageFileCardDetails ? "h-auto" : "h-20"
      }`}
    >
      {showFileOptions ? (
        <ul className="flex justify-end items-end">
          <li
            className="ml-2 items-end cursor-pointer"
            onClick={() => setFilesCreateOpen(true)}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              ></path>
            </svg>
          </li>
          <li
            onClick={(e) => exportToJson(e, languageId)}
            className="ml-2 flex items-center cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.2"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
              />
            </svg>

            {/* <span className="ml-4 mx-1 text-sm">Export</span> */}
          </li>
          <li
            onClick={(e) => removeJson(e, languageId)}
            className="ml-2 flex items-center cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.2"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>

            {/* <span className="ml-4 mx-1 text-sm">Remove</span> */}
          </li>
        </ul>
      ) : (
        ""
      )}
      <div className="flex items-center justify-between w-[260px]">
        <h3 className="text-gray-600">{language}</h3>
        <button
          onClick={() =>
            setDisplayLanguageFileCardDetails(!displayLanguageFileCardDetails)
          }
          className="focus:outline-none"
        >
          <svg
            width="18"
            height="18"
            className={`text-gray-700 ${
              !displayLanguageFileCardDetails ? "" : "transform rotate-180"
            }`}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 6.75L9 11.25L13.5 6.75"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        </button>
      </div>
      {displayLanguageFileCardDetails && (
        <div className="mt-4">
          <div className="w-full h-1 overflow-hidden bg-gray-200 rounded-lg">
            <div
              className="w-2/3 h-1 bg-emerald-500"
              style={{ width: percentCompleted + "%" }}
            ></div>
          </div>
          <h2 className="mt-4 text-lg font-medium text-gray-800">
            {percentCompleted}% Completed
          </h2>
          <p className="mt-1 font-light text-gray-500">
            {totalLabelsWithCustomValues} / {totalLabelsCount} Words
          </p>
        </div>
      )}
      <ModalConfirm {...modalConfirm} setProps={setModalConfirm} />
      <AddFile
        addFileOpen={filesCreateOpen}
        setAddFileOpen={setFilesCreateOpen}
        projectData={projectData}
        languageId={languageId}
        getData={getData}
      />
    </div>
  );
};

export default LanguageFileCard;

import create from "zustand";

const projectDetails = {
  "patient-app-web": {
    name: "Patient App Web",
    code: "PATIENT_APP_WEB",
    id: "patient-app-web",
  },
  "patient-app-mobile": {
    name: "Patient App Mobile",
    code: "PATIENT_APP_MOBILE",
    id: "patient-app-mobile",
  },
};



// const projectDetails = [
//   {
//     name: "Patient App Web",
//     id: "1",
//     code: "PATIENT_APP_WEB",
//   },
//   {
//     name: "Patient App Mobile",
//     id: "2",
//     code: "PATIENT_APP_MOBILE",
//   },
// ];

type IProjectDetails = {
  [id: string]: {
    name: string;
    code: string;
    id: string;
  };
};

interface ProjectStore {
  projects: IProjectDetails;
}

const projectStore = create<ProjectStore>((set) => ({
  projects: projectDetails,
}));

export default projectStore;

import { useEffect, useState } from "react";
import { networkService } from "../../services";
import { IAuthConfig } from "../../interfaces";

interface IAuthConfigState {
  clientId?: string;
  link?: string;
  secret?: string;
}

const Authentication: React.FC = () => {
  const [authConfig, setAuthConfig] = useState<IAuthConfigState>({});

  useEffect(() => {
    const getAuthConfig = async () => {
      try {
        const res = await networkService.get<IAuthConfig[]>(
          "/configurations/authorization"
        );
        const config = {
          clientId:
            res.find((x) => x.setting === "AUTH0_CLIENT_ID")?.value ?? "",
          link: res.find((x) => x.setting === "AUTH0_DOMAIN")?.value ?? "",
          secret:
            res.find((x) => x.setting === "AUTH0_CLIENT_SECRET")?.value ?? "",
        };
        setAuthConfig(config);
      } catch (err) {
        console.log(err);
      }
    };
    getAuthConfig();
  }, []);

  return (
    <section className="py-6 w-1/2">
      <div className="px-4 lg:px-8 sm:px-6">
        <div className="bg-white rounded-lg">
          <div className="px-6 py-6 border-b">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div className="mb-3 pt-0 w-full">
                <h2 className="flex py-2 items-center space-x-2 text-sm text-gray-400 ">
                  <span>Service</span>
                </h2>
                <input
                  type="text"
                  placeholder="Placeholder"
                  // value={authConfig?.clientId}
                  value="Auth0"
                  disabled
                  className="px-3 py-3 placeholder-slate-300 text-slate-600 relative  bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full disabled:bg-slate-50"
                />
              </div>
            </div>
            <div className="sm:flex sm:items-center sm:justify-between">
              <div className="mb-3 pt-0 w-full">
                <h2 className="flex py-2 items-center space-x-2 text-sm text-gray-400 ">
                  <span>Auth0 Link</span>
                </h2>
                <input
                  type="text"
                  placeholder="Placeholder"
                  disabled
                  value={authConfig?.link ?? ""}
                  className="px-3 py-3 placeholder-slate-300 text-slate-600 relative  bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full disabled:bg-slate-50"
                />
              </div>
            </div>
            <div className="sm:flex sm:items-center sm:justify-between">
              <div className="mb-3 pt-0 w-full">
                <h2 className="flex py-2 items-center space-x-2 text-sm text-gray-400 ">
                  <span>Access Token</span>
                </h2>
                <input
                  type="password"
                  placeholder="Placeholder"
                  disabled
                  value={authConfig?.clientId ?? ""}
                  className="px-3 py-3 placeholder-slate-300 text-slate-600 relative  bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full disabled:bg-slate-50"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Authentication;

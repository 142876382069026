import React from "react";

interface headerButtonInterface {
  name: string;
  toggleOverLay: (x: boolean) => void;
}

const headerButton: React.FC<headerButtonInterface> = ({
  name,
  toggleOverLay,
}) => {
  return (
    <button
      onClick={() => toggleOverLay(true)}
      className="items-center hidden space-x-2 font-medium lg:flex focus:outline-none text-primary hover:text-gray-500"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-5 h-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </svg>
      <span>{name}</span>
    </button>
  );
};

export default headerButton;

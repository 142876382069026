import { IProjectDetails } from "../../interfaces";
import { useOrganizationStore } from "../../store";
import { useTableSorting } from "../shared/hooks";
import ProjectRow from "./components/project-row.component";

const Projects: React.FC = () => {

  const getProjects = useOrganizationStore((state) => state.getProjects);
  const projectDetails: IProjectDetails[] = getProjects();

  const { data, handleSort } = useTableSorting(projectDetails);
  const sortedData: IProjectDetails[] = data;

  return (
    <main className="flex-1 px-4 py-8 space-y-4 overflow-y-auto lg:px-8 sm:px-6">
      <h1 className="text-3xl font-medium text-gray-800">Projects</h1>

      <div className="flex flex-col mt-12">
        {sortedData.length > 0 && (
          <div className="-my-2 overflow-x-auto xl:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden rounded-xl">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="px-6 pt-4 pr-16 pb-7 whitespace-nowrap"
                        onClick={() => handleSort("module")}
                      >
                        <button className="flex items-center space-x-3 text-xs font-normal text-left text-gray-500 uppercase hover:text-primary">
                          <span>App Name</span>

                          <svg
                            className="h-3"
                            viewBox="0 0 10 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z"
                              fill="currentColor"
                              stroke="#FEFEFE"
                              strokeWidth="0.1"
                            />
                            <path
                              d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z"
                              fill="currentColor"
                              stroke="#FEFEFE"
                              strokeWidth="0.1"
                            />
                            <path
                              d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z"
                              fill="currentColor"
                              stroke="#FEFEFE"
                              strokeWidth="0.3"
                            />
                          </svg>
                        </button>
                      </th>

                      <th
                        scope="col"
                        className="px-6 pt-4 pr-16 pb-7 whitespace-nowrap"
                      >
                        <button className="flex items-center space-x-3 text-xs font-normal text-left text-gray-500 uppercase hover:text-primary">
                          <span>Completion Rate</span>

                          <svg
                            className="h-3"
                            viewBox="0 0 10 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.78566 7.15666V7.30666H2.93566H3.99072C4.05488 7.30666 4.07675 7.32276 4.08149 7.32694C4.08156 7.32811 4.08154 7.32995 4.08123 7.33265C4.08009 7.34267 4.07466 7.36306 4.05533 7.39317C4.0553 7.39323 4.05526 7.39328 4.05522 7.39334L2.19967 10.2529L2.19932 10.2535C2.16235 10.311 2.12871 10.318 2.11576 10.318C2.1028 10.318 2.06916 10.311 2.03219 10.2535L2.03184 10.2529L0.176289 7.39334C0.176244 7.39327 0.176199 7.3932 0.176154 7.39313C0.156847 7.36304 0.151425 7.34267 0.150276 7.33265C0.149968 7.32995 0.149953 7.32811 0.150022 7.32694C0.154766 7.32276 0.176626 7.30666 0.240787 7.30666H1.29585H1.44585V7.15666V0.53389C1.44585 0.324364 1.62022 0.15 1.82974 0.15H2.40177C2.61129 0.15 2.78566 0.324364 2.78566 0.53389V7.15666Z"
                              fill="currentColor"
                              stroke="#FEFEFE"
                              strokeWidth="0.3"
                            />
                            <path
                              d="M7.78566 3.31135V3.16135H7.93566H8.99072C9.05488 3.16135 9.07675 3.14525 9.08149 3.14108C9.08156 3.13991 9.08154 3.13807 9.08123 3.13537C9.08009 3.12535 9.07466 3.10496 9.05533 3.07485C9.0553 3.07479 9.05526 3.07473 9.05522 3.07468L7.19967 0.215092L7.19932 0.214555C7.16235 0.157044 7.12871 0.149968 7.11576 0.149968C7.1028 0.149968 7.06916 0.157044 7.03219 0.214556L7.03184 0.215091L5.17629 3.07468C5.17624 3.07475 5.1762 3.07482 5.17615 3.07489C5.15685 3.10498 5.15142 3.12535 5.15028 3.13537C5.14997 3.13807 5.14995 3.13991 5.15002 3.14108C5.15477 3.14525 5.17663 3.16135 5.24079 3.16135H6.29585H6.44585V3.31135V9.93413C6.44585 10.1437 6.62022 10.318 6.82974 10.318H7.40177C7.61129 10.318 7.78566 10.1437 7.78566 9.93413V3.31135Z"
                              fill="currentColor"
                              stroke="#FEFEFE"
                              strokeWidth="0.3"
                            />
                          </svg>
                        </button>
                      </th>

                      <th
                        scope="col"
                        className="px-6 pt-4 pr-16 pb-7 whitespace-nowrap"
                      >
                        <button className="flex items-center space-x-3 text-xs font-normal text-left text-gray-500 uppercase hover:text-primary">
                          <span>Selected Languages</span>

                          <svg
                            className="h-3"
                            viewBox="0 0 10 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.78566 7.15666V7.30666H2.93566H3.99072C4.05488 7.30666 4.07675 7.32276 4.08149 7.32694C4.08156 7.32811 4.08154 7.32995 4.08123 7.33265C4.08009 7.34267 4.07466 7.36306 4.05533 7.39317C4.0553 7.39323 4.05526 7.39328 4.05522 7.39334L2.19967 10.2529L2.19932 10.2535C2.16235 10.311 2.12871 10.318 2.11576 10.318C2.1028 10.318 2.06916 10.311 2.03219 10.2535L2.03184 10.2529L0.176289 7.39334C0.176244 7.39327 0.176199 7.3932 0.176154 7.39313C0.156847 7.36304 0.151425 7.34267 0.150276 7.33265C0.149968 7.32995 0.149953 7.32811 0.150022 7.32694C0.154766 7.32276 0.176626 7.30666 0.240787 7.30666H1.29585H1.44585V7.15666V0.53389C1.44585 0.324364 1.62022 0.15 1.82974 0.15H2.40177C2.61129 0.15 2.78566 0.324364 2.78566 0.53389V7.15666Z"
                              fill="currentColor"
                              stroke="#FEFEFE"
                              strokeWidth="0.3"
                            />
                            <path
                              d="M7.78566 3.31135V3.16135H7.93566H8.99072C9.05488 3.16135 9.07675 3.14525 9.08149 3.14108C9.08156 3.13991 9.08154 3.13807 9.08123 3.13537C9.08009 3.12535 9.07466 3.10496 9.05533 3.07485C9.0553 3.07479 9.05526 3.07473 9.05522 3.07468L7.19967 0.215092L7.19932 0.214555C7.16235 0.157044 7.12871 0.149968 7.11576 0.149968C7.1028 0.149968 7.06916 0.157044 7.03219 0.214556L7.03184 0.215091L5.17629 3.07468C5.17624 3.07475 5.1762 3.07482 5.17615 3.07489C5.15685 3.10498 5.15142 3.12535 5.15028 3.13537C5.14997 3.13807 5.14995 3.13991 5.15002 3.14108C5.15477 3.14525 5.17663 3.16135 5.24079 3.16135H6.29585H6.44585V3.31135V9.93413C6.44585 10.1437 6.62022 10.318 6.82974 10.318H7.40177C7.61129 10.318 7.78566 10.1437 7.78566 9.93413V3.31135Z"
                              fill="currentColor"
                              stroke="#FEFEFE"
                              strokeWidth="0.3"
                            />
                          </svg>
                        </button>
                      </th>

                      <th
                        scope="col"
                        className="px-6 pt-4 pr-16 pb-7 whitespace-nowrap"
                      ></th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200 rounded-md">
                    <ProjectRow projectDetails={sortedData} />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <div className="text-center p-12">
          {sortedData.length === 0 && "No Data Found!"}
        </div>
      </div>
    </main>
  );
};

export default Projects;

import { Link } from "react-router-dom";
import ToolTip from "../../shared/components/ToolTip";
import { IProjectDetails } from "../../../interfaces";

interface IProjectRow {
  projectDetails: IProjectDetails[];
}

const ProjectRow: React.FC<IProjectRow> = ({ projectDetails }) => {

  return (
    <>
      {Object.values(projectDetails).map((project) => (
        <tr className="transition-colors duration-300 hover:bg-gray-50"
          key={project.module}
        >
          <td className="px-6 py-4 text-lg font-medium text-gray-800 whitespace-nowrap">
            {project.module}
          </td>

          <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
            <div className="flex items-center space-x-3 ">
              <ToolTip />

              <span>76%</span>
            </div>
          </td>

          <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
            English., French, German
          </td>

          <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
            <div className="flex items-center lg:justify-end">
              <Link
                to={`/localization/projects/${project.module}`}
                className="flex items-center justify-center p-2 text-sm font-medium text-center text-gray-500 transition-colors duration-200 transform bg-gray-100 rounded-full focus:outline-none hover:bg-gray-200"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.91669 7H11.0834"
                    stroke="#565759"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M7 2.91675L11.0833 7.00008L7 11.0834"
                    stroke="#565759"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </Link>
            </div>
          </td>
        </tr>
      ))}
    </>
  );
};

export default ProjectRow;

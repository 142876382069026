import produce from "immer";
import create from "zustand";
import { devtools } from "zustand/middleware";
import { IAuthInfo, IAuthProfile } from "../interfaces";

interface AuthState {
  token: string;
  user: IAuthProfile;
  setAuthToken: (x: string) => void;
  setAuthState: (x?: IAuthInfo) => void;
  setAuthUser: (x: IAuthProfile) => void;
  updateAuthUser: (x: Partial<IAuthProfile>) => void;
}

const useAuthStore = create<AuthState>()(
  devtools(
    (set) => ({
      token: null,
      user: null,
      setAuthToken: (token) =>
        set(
          produce((state) => {
            state.token = token;
          }),
          false,
          "auth/setAuthToken"
        ),
      setAuthUser: (user) =>
        set(
          produce((state) => {
            state.user = user;
          }),
          false,
          "auth/setAuthUser"
        ),
      updateAuthUser: (changes) =>
        set(
          produce((state) => {
            state.user = { ...state.user, ...changes };
          }),
          false,
          "auth/updateAuthUser"
        ),
      setAuthState: (data) =>
        set(
          produce((state) => {
            state.token = data?.token || null;
            state.user = data?.user || null;
          }),
          false,
          "auth/setAuthState"
        ),
    }),
    {
      name: "auth",
      serialize: { options: true },
    }
  )
);
export default useAuthStore;

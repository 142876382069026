import { IAuthProfile } from "./../../../interfaces";

export function addMonthsToDate(date: Date, toadd: number = 3): Date {
  date.setMonth(date.getMonth() + toadd);
  return date;
}

export function getPropertyValue<T>(
  object: { [key: string]: any },
  propertyPath: string,
  defaultValue: any = null
): T {
  return doesObjectContainProperty(object, propertyPath)
    ? propertyPath.split(".").reduce((previous, current) => {
        return previous[current];
      }, object)
    : defaultValue;
}

export function doesObjectContainProperty(
  object: { [key: string]: any },
  propertyPath: string
): boolean {
  // If there's nothing to check
  if (typeof object !== "object" || !object || !Object.keys(object).length) {
    return false;
  }

  // If there's nothing to check
  if (!propertyPath || !propertyPath.length) {
    return false;
  }

  try {
    // Iterate through propertyPath to dig into the object
    const finalValue = propertyPath.split(".").reduce((previous, current) => {
      // No hasOwnProperty check
      return typeof previous !== "undefined" && previous !== null
        ? previous[current]
        : undefined;
    }, object);
    // We specifically want to check for undefined & null to check if value exist here
    return typeof finalValue !== "undefined" && finalValue !== null;
  } catch (error) {
    // If the path has a wrong turn, the reduce function will throw an error
    return false;
  }
}
export function getProfilePayload(values: IAuthProfile): Partial<IAuthProfile> {
  const { profilePic, organizationId, organizationName, ...payload } =
    values;
  return payload;
}

export function getOnboardingStep(status: string) {
  if (!status) return "0";
  return status;
}

import produce from "immer";
import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";
import { IGlossary } from "../../interfaces";

interface GlossaryState {
  glossaryItems: IGlossary[];
  setGlossaryItems: (x: IGlossary[]) => void;
  addGlossary: (glossary: IGlossary) => void;
}

const useGlossaryStore = create<GlossaryState>()(
  persist(
    devtools(
      (set) => ({
        glossaryItems: [],
        setGlossaryItems: (glossarysItems) =>
          set(
            produce((state) => {
              state.glossaryItems = glossarysItems;
            }),
            false,
            "glossary/setGlossaryItems"
          ),
        addGlossary: (glossary) =>
          set(
            produce((state) => {
              state.glossaryItems.push(glossary);
            }),
            false,
            "glossary/addGlossary"
          ),
      }),
      { name: "glossary", serialize: { options: true } }
    ),
    {
      name: "glossary", // unique name
      getStorage: () => appConstants.keys.storage, // (optional) by default, 'localStorage' is used
    }
  )
);

export default useGlossaryStore;

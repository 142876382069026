import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { IGlossary } from "../../../interfaces";
import { loggerService, networkService } from "../../../services";
import { useAuthStore } from "../../../store";
import useGlossaryStore from "../../../store/localization/glossary.store";
import { AddOverlayLayout, FieldError } from "../../shared/components";
import { useProjectData } from "../../shared/hooks";
interface GlossaryCreateProps {
  glossaryCreateOpen: boolean;
  setGlossaryCreateOpen: (open: boolean) => void;
  isEditing: boolean;
  currentRow: IGlossary;
  setIsEditing: (open: boolean) => void;
  glossaryItems: IGlossary[];
}

const newGlossarySchema = Yup.object().shape({
  term: Yup.string().required("Please enter Term."),
  description: Yup.string().required("Please enter description."),
  caseSensitive: Yup.boolean().oneOf([true, false], ""),
  nonTranslatable: Yup.boolean().oneOf([true, false], ""),
});

const GlossaryCreate: React.FC<GlossaryCreateProps> = ({
  glossaryCreateOpen,
  setGlossaryCreateOpen,
  isEditing,
  currentRow,
  setIsEditing,
  glossaryItems,
}) => {
  const orgId = useAuthStore((state) => state.user?.organizationId);
  const { projectData, projectId, projectDetails } = useProjectData();

  const [formResetKey, setFormResetKey] = useState(0);

  useEffect(() => {
    setFormResetKey((prevKey) => prevKey + 1);
    if (!glossaryCreateOpen) {
      setIsEditing(false);
    }
  }, [glossaryCreateOpen]);

  const addGlossary = useGlossaryStore((state) => state.addGlossary);

  const setGlossaryItems = useGlossaryStore((state) => state.setGlossaryItems);

  const handleGlossaryAdd = async (values) => {
    try {
      const glossaryItem = {
        organizationId: orgId,
        term: values.term,
        description: values.description,
        caseSensitive: values.caseSensitive,
        nonTranslatable: values.nonTranslatable,
        module: projectId,
      };
      const data = await networkService.post<IGlossary>(
        "/glossary",
        glossaryItem
      );

      addGlossary({
        ...glossaryItem,
        id: data.id,
      });
      setGlossaryCreateOpen(false);
      return true;
    } catch (error) {
      loggerService.error(error);
      return false;
    }
  };

  const handleGlossaryEdit = async (values) => {
    try {
      const glossaryItem = {
        organizationId: orgId,
        term: values.term,
        description: values.description,
        caseSensitive: values.caseSensitive,
        nonTranslatable: values.nonTranslatable,
        module: projectId,
      };
      const data = await networkService.put<IGlossary>(
        `/glossary/${currentRow.id}`,
        glossaryItem
      );

      const updatedGlossaryData: IGlossary[] = glossaryItems.map(
        (glossaryItem) => {
          if (glossaryItem.id === currentRow.id) {
            return data;
          }
          return glossaryItem;
        }
      );

      setGlossaryItems(updatedGlossaryData);
      setGlossaryCreateOpen(false);
    } catch (error) {
      loggerService.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    if (isEditing) {
      handleGlossaryEdit(values);
    } else {
      handleGlossaryAdd(values).then((result) => {
        if (result) {
          resetForm();
        }
      });
    }
  };

  return (
    <AddOverlayLayout
      overlayOpen={glossaryCreateOpen}
      setOverlayOpen={setGlossaryCreateOpen}
      title={isEditing ? "Edit Word" : "Add New Word"}
    >
      <Formik
        initialValues={{
          term: isEditing ? currentRow.term : "",
          description: isEditing ? currentRow.description : "",
          caseSensitive: isEditing ? currentRow.caseSensitive : false,
          nonTranslatable: isEditing ? currentRow.nonTranslatable : false,
        }}
        key={formResetKey}
        validationSchema={newGlossarySchema}
        onSubmit={handleSubmit}
      >
        <Form className="flex flex-col flex-1">
          <div className="flex-1">
            <div>
              <label className="text-sm font-medium text-gray-600">Term </label>

              <Field
                type="text"
                id="term"
                name="term"
                placeholder="e.g. BRB"
                className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
              />
              <ErrorMessage name="term">
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>

            <div className="mt-8">
              <label className="text-sm font-medium text-gray-600">
                Description
              </label>

              <Field
                type="text"
                name="description"
                id="description"
                placeholder="e.g. Shortform of Be Right Back"
                className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
              ></Field>
              <ErrorMessage name="description">
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>

            <div className="mt-8">
              <label className="text-sm font-medium text-gray-600">
                Attributes
              </label>

              <label className="flex items-center mt-4 space-x-3">
                <Field
                  name="caseSensitive"
                  className="p-2 border-gray-200 rounded-md text-primary"
                  type="checkbox"
                />
                <span className="font-medium text-gray-700 ">
                  Case Sensitive
                </span>
                <ErrorMessage name="caseSensitive">
                  {(msg) => <FieldError message={msg} />}
                </ErrorMessage>
              </label>

              <label className="flex items-center mt-4 space-x-3">
                <Field
                  name="nonTranslatable"
                  className="p-2 border-gray-200 rounded-md text-primary"
                  type="checkbox"
                />
                <span className="font-medium text-gray-700 ">
                  Non-Translatable
                </span>
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="inline-flex justify-center w-full px-6 py-3 mt-8 mb-2 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
          >
            {isEditing ? "Update Word" : "Add Word"}
          </button>
        </Form>
      </Formik>
    </AddOverlayLayout>
  );
};

export default GlossaryCreate;

import React from "react";
import { useLocation } from "react-router-dom";
import { AnalyticsProjects, Rawdata } from ".";

import { useDateFilterStore, useHeaderButtonOverlay, useOrganizationStore, useSidebarStore } from "../../store";
import { Header, HeaderButton, NotFound } from "../shared/components";
import { loggerService, networkService } from "../../services";
import ProjectDetail from "./analytics-project-details.component";
import { IProjectDetails } from "../../interfaces";
import dateService from "../../services/date.service";


const AnalyticsProvider: React.FC = () => {
  const location = useLocation();
  const toggleSidebar = useSidebarStore((state) => state.toggleSidebar);
  const toggleOverLay = useHeaderButtonOverlay((state) => state.toggleOverLay);

  const flag =
    location.pathname === "/usage-analytics/projects" ||
    location.pathname === "/usage-analytics/projects/";

  const projectId = location.pathname.split("/")[3];

  const getProjects = useOrganizationStore((state) => state.getProjects);
  const projectDetails: IProjectDetails[] = getProjects();

  const selectedDatesInFilter = useDateFilterStore((state) => state.selectedDates);

  const handleExportData = async () => {
    try {
      const projectData: IProjectDetails = projectDetails.find((project) => project.module === projectId);
      let downloadEventLogsUrl: string = '';
      let startDate, endDate: string = '';
      if (selectedDatesInFilter.startDate !== null && selectedDatesInFilter.endDate !== null) {
        startDate = dateService.convertToYYYYMMDDFormat(selectedDatesInFilter.startDate);
        endDate = dateService.convertToYYYYMMDDFormat(selectedDatesInFilter.endDate);
        downloadEventLogsUrl = `/usage-analytics/event-logs/export-csv?application=${projectData?.module}&startDate=${startDate}&endDate=${endDate}`;
      } else {
        downloadEventLogsUrl = `/usage-analytics/event-logs/export-csv?application=${projectData?.module}`;
      }
      const data = await networkService.get<BinaryData>(
        downloadEventLogsUrl,
        {},
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "event-logs.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      loggerService.error(err);
    }
  };


  return (
    <>
      {flag ? (
        <>
          <Header title="Usage Analytics" setSidebarOpen={toggleSidebar}>
            {/* <HeaderButton name="Add Project" toggleOverLay={toggleOverLay} /> */}
          </Header>
          <AnalyticsProjects />
        </>
      ) : (
        <>
          <Header title="Usage Analytics" setSidebarOpen={toggleSidebar}>
            <button
              className="items-center hidden space-x-2 font-medium lg:flex focus:outline-none text-primary hover:text-gray-500"
              onClick={handleExportData}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.25 8.75V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V8.75"
                  stroke="#546CCC"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.08325 5.83325L6.99992 8.74992L9.91659 5.83325"
                  stroke="#546CCC"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 8.75V1.75"
                  stroke="#546CCC"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Export Data</span>
            </button>
          </Header>
          <main className="flex-1 overflow-y-auto ">
            <ProjectDetail />
          </main>
        </>
      )}
    </>
  );
};

export default AnalyticsProvider;

import { useEffect, useState } from "react";
import { networkService } from "../../services";
import { Formik, Field, Form } from "formik";

interface cmsData {
  id: number;
  service: string;
  notionLink: string;
}

const ContentCMS: React.FC = () => {
  const [cmsData, setCmsData] = useState<cmsData>();

  useEffect(() => {
    networkService
      .get<cmsData>("configurations/cms-configurations?application=SCHEDULING")
      .then((res) => {
        setCmsData(res[0]);
      });
  }, []);

  return (
    <section className="py-6 w-1/2 ">
      <div className="px-4 lg:px-8 sm:px-6">
        <div className="bg-white rounded-lg">
          <div className="px-6 py-6 border-b">
            <Formik
              initialValues={{
                service: cmsData ? cmsData.service : "",
                notionLink: cmsData ? cmsData.notionLink : "",
              }}
              enableReinitialize
              onSubmit={() => {}}
            >
              <Form>
                <label className="flex py-2 items-center space-x-2 text-sm text-gray-400 ">
                  Service
                </label>
                <Field
                  name="service"
                  type="text"
                  disabled
                  className="px-3 py-3 placeholder-slate-300 text-slate-600 relative  bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full disabled:bg-slate-50"
                />
                <label className="flex py-2 items-center space-x-2 text-sm text-gray-400 ">
                  Notion Link
                </label>
                <Field
                  name="notionLink"
                  type="text"
                  disabled
                  className="px-3 py-3 placeholder-slate-300 text-slate-600 relative  bg-white rounded text-sm border border-slate-300 outline-none focus:outline-none focus:ring w-full disabled:bg-slate-50"
                />
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentCMS;

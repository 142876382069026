import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { networkService } from "../../../services";
import { AddOverlayLayout } from "../../shared/components";
import ArrayInput from "../../shared/components/formik-key-value-array-input.component";
import appConstants from "../../shared/config";
import { useProjectData } from "../../shared/hooks";

interface CreateLabelProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getData: () => void;
}

const CreateLabel: React.FC<CreateLabelProps> = ({
  open,
  setOpen,
  getData,
}) => {
  const labelsValidationSchema = Yup.object().shape({
    labels: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required("Label is required"),
      })
    ),
  });

  const { projectData } = useProjectData();

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    try {
      await networkService.post<any>(
        `${appConstants.urls.baseUrl}/api/labels`,
        values.labels.map((label) => {
          return {
            code: label.label.trim(),
            module: projectData.module,
          };
        })
      );
      getData();
      setOpen(false);
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again later.");
    } finally {
      setSubmitting(false);
      resetForm();
    }
  };

  return (
    <AddOverlayLayout
      title="Create Label"
      overlayOpen={open}
      setOverlayOpen={setOpen}
    >
      <Formik
        initialValues={{
          labels: [],
        }}
        validationSchema={labelsValidationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) =>
          handleSubmit(values, setSubmitting, resetForm)
        }
      >
        {() => (
          <Form className="flex flex-col flex-1">
            <div className="flex-1">
              <div className="flex items-center justify-center w-full">
                <ArrayInput
                  name="labels"
                  buttonText="+ Add Another Label"
                  label="Label"
                  arrayFields={[{ label: "label", type: "text" }]}
                />
              </div>
            </div>

            <button
              type="submit"
              className="inline-flex justify-center w-full px-6 py-3 mt-8 mb-2 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
            >
              Save
            </button>
          </Form>
        )}
      </Formik>
    </AddOverlayLayout>
  );
};

export default CreateLabel;

import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

import React from "react";
import CrossIcon from "../icons/cross-icon";

export interface ModalLayoutProps {
  title?: string;
  open: boolean;
  setProps: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
  actionBtn?: React.ReactNode[];
}

const ModalLayout: React.FC<ModalLayoutProps> = ({
  title,
  open,
  setProps,
  children,
  actionBtn,
}) => {
  const cancelButtonRef = React.useRef(null);

  function closeModal() {
    setProps(false);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-400 bg-opacity-60 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:w-full max-sm:w-full max-w-fit">
                <header
                  className={`flex items-center gap-2 p-4 border-b-2 ${
                    title ? "justify-between" : "justify-end"
                  }`}
                >
                  {title && <p className="text-lg font-semibold">{title}</p>}{" "}
                  <div className="flex items-center gap-4">
                    {actionBtn?.map((btn, index) => btn)}
                    <button
                      type="button"
                      onClick={closeModal}
                      ref={cancelButtonRef}
                    >
                      <CrossIcon />
                    </button>
                  </div>
                </header>
                <section className="max-w-6xl">{children}</section>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalLayout;

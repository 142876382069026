import React from "react";
import { Navigate, Outlet, RouteProps, useLocation } from "react-router-dom";
import { RoutePermission } from "../../../models";
import { useAuthStore } from "../../../store";
import { AuthorizationResult } from "../config";
import useAuthorization from "../hooks/use-authorization.hook";

type IPrivateRouteProps = RouteProps & { routePermission: RoutePermission };

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ routePermission }) => {
  const location = useLocation(),
    [token, user] = useAuthStore((state) => [state.token, state.user]),
    authorization = useAuthorization(routePermission, {
      token,
      organizationId: user?.organizationId ?? null,
      roles: user?.role ?? [],
    });

  if (authorization === AuthorizationResult.TokenRequired) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (authorization === AuthorizationResult.LoginRequired) {
    // Redirect them to the /organization page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/organization" state={{ from: location }} replace />;
  }

  if (authorization === AuthorizationResult.Unauthorized) {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;

import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../app/shared/config";
import produce from "immer";

interface ISelectedDate {
    startDate: Date;
    endDate: Date;
}
interface ISelectedDates {
    selectedDates: ISelectedDate;
    setSelectedDateFilter: (x: ISelectedDate) => void;
}
const useDateFilterStore = create<ISelectedDates>()(
    persist(
        devtools(
            (set, get) => ({
                selectedDates: null,
                setSelectedDateFilter: (dates) =>
                    set(
                        produce((state: any) => {
                            state.selectedDates = dates;
                        })
                    )
            }),
            { name: "selectedDatesInFilter", serialize: { options: true } }
        ),
        {
            name: "selectedDatesInFilter", // unique name
            getStorage: () => appConstants.keys.storage
        }
    )
);

export default useDateFilterStore;

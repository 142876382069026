import { networkService } from "../../services";
import { useEffect, useState } from "react";

const Modules: React.FC = () => {
  const [modules, setModules] = useState<any>([]);

  useEffect(() => {
    const getModules = async () => {
      try {
        const data = await networkService.get(
          "/configurations/organization-modules"
        );
        setModules(data);
      } catch (err) {
        console.log(err);
      }
    };

    getModules();
  }, []);

  return (
    <>
      <section className="py-6 lg:px-8 sm:px-6 ">
        <div className="">
          <span>Integrated Modules</span>

          <div className="grid gap-6 mt-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
            {modules.map((item: any) => (
              <div className="bg-white p-4 border rounded-lg" key={item.module}>
                <div className="flex items-center justify-between ">
                  <h3 className="py-1 px-2 text-lg">{item.module}</h3>
                </div>
                {/* <p className="py-1 px-2 mt-1 font-light text-gray-500">
                  Patients accessing their appointments notes from past
                </p> */}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Modules;

import { useEffect, useState } from "react";
import { IReleaseNote } from "../../../interfaces";
import dateService from "../../../services/date.service";
import { loggerService, networkService } from "../../../services";

interface ShowReleaseNoteInterface {
  showNotesOpen: boolean;
  setShowNotesOpen: (x: boolean) => void;
  releaseNote: IReleaseNote;
}

const ShowReleaseNote: React.FC<ShowReleaseNoteInterface> = ({
  showNotesOpen,
  setShowNotesOpen,
  releaseNote
}) => {
  const [releaseNoteDetails, setReleaseNoteDetails] = useState<IReleaseNote | null>(null);

  useEffect(() => {
    if (!releaseNote) {
      return;
    };
    const fetchReleaseNote = async () => {
      try {
        const data = await networkService.get<IReleaseNote>(`${process.env.REACT_APP_C0_APP_URL}/api/blog/${releaseNote.id}`, {}, {
          headers: {
            "X-C0-TOKEN": process.env.REACT_APP_C0_APP_TOKEN
          }
        });
        setReleaseNoteDetails(data);
      } catch (error) {
        loggerService.error(error);
      }
    };
    fetchReleaseNote();
  }, [releaseNote]);


  return (
    <div className="text-gray-800 bg-gray-100 font-dm-sans">
      <div
        onClick={() => setShowNotesOpen(false)}
        className={
          "fixed inset-0 z-40 transition-opacity bg-black opacity-50 " +
          (showNotesOpen ? "block" : "hidden")
        }
      ></div>

      <div
        className={
          "fixed inset-y-0 right-0 z-50 flex flex-col h-screen overflow-y-auto transition duration-200 transform bg-white border-r border-gray-200 w-full sm:w-[28rem] " +
          (showNotesOpen
            ? "translate-x-0 ease-in"
            : "translate-x-full ease-out")
        }
      >
        <div className="flex items-center justify-between px-6 py-8 space-x-4 sm:space-x-0">
          <div>
            <h3 className="text-2xl font-medium text-gray-800">
              {releaseNoteDetails?.properties.Name}
            </h3>
          </div>

          <button
            onClick={() => setShowNotesOpen(false)}
            className="text-gray-600 focus:text-primary focus:outline-none"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 4.5L4.5 13.5"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.5 4.5L13.5 13.5"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>

        <main className={"flex flex-col flex-1 px-6 pb-6 " + (releaseNoteDetails ? 'block' : 'hidden')}>
          <div className="flex items-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
              />
            </svg>

            <p className="my-5 ml-2">
              {dateService.convertToDDMMYYYYFormat(releaseNoteDetails?.properties.Date)}
            </p>
          </div>
          <div className="prose lg:prose-xl"
            dangerouslySetInnerHTML={{ __html: releaseNoteDetails?.description }}
          ></div>
          {/* <h1 className="font-medium leading-tight text-xl mt-0 mb-2 ">
            What’s new
          </h1>
          <ul className="list-disc pl-5">
            <li>
              When opening SSH, FTP, SFTP or SMB links, you’ll now see the
              option to allow all future URLs from just that same domain instead
              of the whole wide world. A little more secure for us, a little
              more peace of mind for you.
            </li>
          </ul>

          <h1 className="font-medium leading-tight text-xl mt-10 mb-2 ">
            Bug fixes
          </h1>
          <ul className="list-disc pl-5">
            <li>
              Certain versions of the app were not correctly changing
              availability to “Away” after 10 minutes of inactivity. While this
              has been fixed, we’d like to remind you that you can use your
              Custom Status to let your teammates know your whereabouts beyond
              simply Active or Away. Examples include: walking the dog, catching
              up after PTO, taking a mental break, or tweeting nice things to
              the Slack social media team.
            </li>
          </ul> */}
        </main>
      </div>
    </div>
  );
};

export default ShowReleaseNote;

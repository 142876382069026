import { useState } from "react";

function ToolTip(props) {
  const [isShown, setIsShown] = useState(false);

  return (
    <button className="relative focus:outline-none">
      <span
        className="cursor-pointer "
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_168_5106)">
            <path
              d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6818 14.6667 7.99992C14.6667 4.31802 11.6819 1.33325 8.00004 1.33325C4.31814 1.33325 1.33337 4.31802 1.33337 7.99992C1.33337 11.6818 4.31814 14.6666 8.00004 14.6666Z"
              stroke="black"
              strokeLinecap="round"
            />
            <path d="M8 10.6667V8" stroke="black" strokeLinecap="round" />
            <path d="M8 5.33325H8.00667" stroke="black" strokeLinecap="round" />
          </g>
          <defs>
            <clipPath id="clip0_168_5106">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </span>

      {isShown && (
        <div className="absolute z-10 px-3 py-3 text-white bg-black rounded-lg cursor-default w-36 top-8 -left-12">
          <ul>
            <li>English - 62 %</li>
            <li>German - 78 %</li>
          </ul>
        </div>
      )}
    </button>
  );
}

export default ToolTip;

import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useProjectStore } from "../../../store";

interface ProjectNavInterface {
  openTab: number;
  setOpenTab: (x: number) => void;
}

const NotificationsNav: React.FC<ProjectNavInterface> = ({
  openTab,
  setOpenTab,
}) => {
  const projectDetails = useProjectStore((state) => state.projects);
  const navigate = useNavigate();
  const location = useLocation();
  const projectId = location.pathname.split("/")[3];
  const [currentTab, setCurrentTab] = useState(projectId);

  useEffect(() => {
    setCurrentTab(projectId);
  }, [location.pathname]);

  return (
    <>
      <div className="px-4 pt-6 bg-white border-b lg:px-8 sm:px-6 ">
        <select
          name=""
          id=""
          className=" border border-gray-300 text-gray-900 rounded-md focus:ring-blue-500 focus:border-blue-500 "
          onChange={(e) =>
            navigate(`/notifications/projects/${e.target.value}`)
          }
          defaultValue={currentTab}
        >
          {Object.keys(projectDetails).map((item) => (
            <option value={item} className="p-2" key={item}>
              {projectDetails[item].name}
            </option>
          ))}
        </select>

        <div>
          <ul
            role="tablist"
            className=" md:grid md:gap-4 md:grid-cols-5 md:place-items-start mt-4"
          >
            <li>
              <Link
                to="#"
                className={`flex items-center px-2 py-5 -mb-px text-center text-gray-600 border-b-2  lg:px-4 whitespace-nowrap hover:border-gray-300
              ${
                openTab === 1 &&
                "text-primary border-primary hover:border-primary"
              }`}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                role="tablist"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="16"
                  fill="none"
                  stroke={openTab === 1 ? "#546CCC" : "#BDBDBD"}
                  viewBox="0 0 15 16"
                >
                  <path
                    stroke={openTab === 1 ? "#546CCC" : "#BDBDBD"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M7.5 14.25a6.25 6.25 0 100-12.5 6.25 6.25 0 000 12.5z"
                  ></path>
                  <path
                    stroke={openTab === 1 ? "#546CCC" : "#BDBDBD"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.5 4.25V8L10 9.25"
                  ></path>
                </svg>

                <span
                  className={`${openTab === 1 && "text-primary"} mx-1 text-sm`}
                >
                  Scheduled Notification
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="#"
                className={`flex items-center px-2 py-5 -mb-px text-center text-gray-600 border-b-2  lg:px-4 whitespace-nowrap hover:border-gray-300
              ${openTab === 2 && "text-primary border-b-2 border-primary"}`}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                role="tablist"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="16"
                  fill="none"
                  viewBox="0 0 15 16"
                >
                  <path
                    stroke={openTab === 2 ? "#546CCC" : "#BDBDBD"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.25 5.5a3.75 3.75 0 00-7.5 0c0 4.375-1.875 5.625-1.875 5.625h11.25S11.25 9.875 11.25 5.5z"
                  ></path>
                  <path
                    stroke={openTab === 2 ? "#546CCC" : "#BDBDBD"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.581 13.625a1.25 1.25 0 01-2.162 0"
                  ></path>
                </svg>
                <span
                  className={`${openTab === 2 && "text-primary"} mx-1 text-sm`}
                >
                  Send Notification Now
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={`#languages`}
                className={`flex items-center px-2 py-5 -mb-px text-center text-gray-600 border-b-2  lg:px-4 whitespace-nowrap hover:border-gray-300
              ${openTab === 3 && "text-primary border-b-2 border-primary"}`}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                role="tablist"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="16"
                  fill="none"
                  viewBox="0 0 15 16"
                  stroke={openTab === 3 ? "#546CCC" : "#BDBDBD"}
                >
                  <g
                    stroke={openTab === 3 ? "#546CCC" : "#BDBDBD"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    clipPath="url(#clip0_261_6550)"
                  >
                    <path d="M7.5 9.875a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75z"></path>
                    <path d="M12.125 9.875a1.032 1.032 0 00.206 1.137l.038.038a1.25 1.25 0 11-1.769 1.769l-.037-.038a1.032 1.032 0 00-1.138-.206 1.031 1.031 0 00-.625.944v.106a1.25 1.25 0 01-2.5 0v-.056a1.031 1.031 0 00-.675-.944 1.032 1.032 0 00-1.138.206l-.037.038A1.25 1.25 0 112.681 11.1l.038-.037a1.031 1.031 0 00.206-1.138 1.031 1.031 0 00-.944-.625h-.106a1.25 1.25 0 010-2.5h.056a1.031 1.031 0 00.944-.675 1.031 1.031 0 00-.206-1.138L2.63 4.95A1.25 1.25 0 114.4 3.181l.037.038a1.031 1.031 0 001.138.206h.05a1.031 1.031 0 00.625-.944v-.106a1.25 1.25 0 012.5 0v.056a1.031 1.031 0 00.625.944 1.031 1.031 0 001.137-.206l.038-.038A1.25 1.25 0 1112.319 4.9l-.038.037a1.032 1.032 0 00-.206 1.138v.05a1.031 1.031 0 00.944.625h.106a1.25 1.25 0 010 2.5h-.056a1.03 1.03 0 00-.944.625v0z"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_261_6550">
                      <path
                        fill="#fff"
                        d="M0 0H15V15H0z"
                        transform="translate(0 .5)"
                      ></path>
                    </clipPath>
                  </defs>
                </svg>

                <span
                  className={`${openTab === 3 && "text-primary"} mx-1 text-sm`}
                >
                  Settings
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={`#files`}
                className={`flex items-center px-2 py-5 -mb-px text-center text-gray-600 border-b-2  lg:px-4 whitespace-nowrap hover:border-gray-300
              ${openTab === 4 && "text-primary border-b-2 border-primary"}`}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(4);
                }}
                data-toggle="tab"
                role="tablist"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="16"
                  fill="none"
                  viewBox="0 0 15 16"
                >
                  <path
                    stroke={openTab === 4 ? "#546CCC" : "#BDBDBD"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.625 13.625v-1.25a2.5 2.5 0 00-2.5-2.5h-5a2.5 2.5 0 00-2.5 2.5v1.25M5.625 7.375a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM14.375 13.625v-1.25A2.5 2.5 0 0012.5 9.956M10 2.456A2.5 2.5 0 0110 7.3"
                  ></path>
                </svg>

                <span
                  className={`${openTab === 4 && "text-primary"} mx-1 text-sm`}
                >
                  Users
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default NotificationsNav;

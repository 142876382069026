import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { IFile, IProjectDetails } from "../../../interfaces";
import { loggerService, networkService } from "../../../services";
import { AddOverlayLayout, FieldError } from "../../shared/components";
import { useEffect, useState } from "react";

const addFileSchema = Yup.object().shape({
  file: Yup.mixed().required("Please Add a File"),
});

interface AddFileInterface {
  addFileOpen: boolean;
  setAddFileOpen: (value: boolean) => void;
  projectData?: IProjectDetails
  languageId?: number;
  getData: (fromChild: boolean) => void;
}

const AddFile: React.FC<AddFileInterface> = ({
  addFileOpen,
  setAddFileOpen,
  projectData,
  languageId,
  getData
}) => {
  const [formResetKey, setFormResetKey] = useState(0);
  
  useEffect(() => {
    setFormResetKey((prevKey) => prevKey + 1);
  }, [addFileOpen]);
  
  const handleSubmit = async (values, { resetForm }) => {

    try {
      const formData = new FormData();
      formData.append("media", values.file);

      const data = await networkService.post<IFile>(
        `files/localization/upload?module=${projectData.module}&languageId=${languageId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      getData(true);

    } catch (error) {
      loggerService.error("error", error);
    }
    resetForm();
  };
  return (
    <AddOverlayLayout
      title="Add File"
      overlayOpen={addFileOpen}
      setOverlayOpen={setAddFileOpen}
    >
      <Formik
        initialValues={{
          file: "",
        }}
        key={formResetKey}
        validationSchema={addFileSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="flex flex-col flex-1">
            <div className="flex-1">
              <div className="flex items-center justify-center w-full">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      aria-hidden="true"
                      className="w-10 h-10 mb-3 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      JSON (MAX. 1 Mb)
                    </p>
                  </div>
                  <input
                    type="file"
                    id="dropzone-file"
                    style={{width: '60%'}}
                    onChange={(event) => {
                      setFieldValue('file',event.target.files[0]);
                    }}
                  />
                  <ErrorMessage name="file">
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </label>
              </div>
            </div>

            <button
              type="submit"
              className="inline-flex justify-center w-full px-6 py-3 mt-8 mb-2 font-medium text-center text-white transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80"
            >
              Add File
            </button>
          </Form>
        )}
      </Formik>
    </AddOverlayLayout>
  );
};

export default AddFile;

import { useQuery } from "@tanstack/react-query";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useOutletContext } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  FileExtraction,
  IAiModel,
  IAiTemplate,
  IPromptTemplate,
} from "../../interfaces/ai-models.interface";
import { networkService } from "../../services";
import { FieldError } from "../shared/components";
import NestedMultiSelect from "../shared/components/nested-multi-select.component";
import appConstants from "../shared/config";
import LeftArrow from "../shared/icons/left-arrow.icon";
import MessageIcon from "../shared/icons/message.icon";
import PasteIcon from "../shared/icons/paste.icon";
import { uploadTos3 } from "../shared/utils/upload-to-s3";
import {
  extractObservationsData,
  extractQuestionnairesData,
  extractTextInBraces,
  getObservations,
  getQuestionnaires,
} from "./ai.helper";
import FileInput from "./components/ai-file-input";

const PlaygroundTwo = () => {
  const [nestedOptions, setNestedOptions] = useState([]);
  const [results, setResults] = useState<string[]>([]);
  const [selectedNestedOptions, setSelectedNestedOptions] = useState<string[]>(
    []
  );
  const [jsonText, setJsonText] = useState(null);
  const [cloudFiles, setCloudFiles] = useState<any>([]);
  const [files, setFiles] = useState<File[]>([]);
  const intl = useIntl();

  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const { data: models } = useQuery({
    queryKey: ["models"],
    queryFn: () => {
      return networkService.get<IAiModel[]>(
        `${appConstants.urls.aiUrl}/models`
      );
    },
  });

  const { template, useCaseId } = useOutletContext<{
    template: IAiTemplate;
    useCaseId: string;
  }>();

  const validationSchema = Yup.object().shape({
    model: Yup.string().required(intl.formatMessage({ id: "form.required" })),
    prompt: Yup.string().required(intl.formatMessage({ id: "form.required" })),
  });

  useEffect(() => {
    if (!jsonText || !template) return;

    if (template.inputFormat.fileExtraction === FileExtraction.OBSERVATIONS) {
      const observations = getObservations(jsonText);
      setNestedOptions(observations);
    } else if (
      template.inputFormat.fileExtraction === FileExtraction.QUESTIONNAIRES
    ) {
      const questionnaires = getQuestionnaires(jsonText);
      setNestedOptions(questionnaires);
    }
  }, [jsonText, setNestedOptions, template]);

  useEffect(() => {
    if (!template || !files.length) return;

    const sendToS3 = async (file) => {
      try {
        const data = await uploadTos3(
          file,
          intl.formatMessage({
            id: "shared.file_upload_util.file_upload_error",
          }),
          intl.formatMessage({
            id: "shared.file_upload_util.file_upload_pending",
          })
        );

        setCloudFiles((prev) => [
          ...prev,
          { cloudId: data.cloudId, mimeType: file.type },
        ]);
      } catch (error) {
        console.log(error);
      }
    };

    try {
      if (template.inputFormat.fileExtraction === FileExtraction.CLOUD_ID) {
        sendToS3(files[files.length - 1]);
      } else {
        const fileReader = new FileReader();
        fileReader.readAsText(files[0], "UTF-8");
        fileReader.onload = (e) => {
          setJsonText(JSON.parse(e.target.result as string));
        };
      }
    } catch (error) {
      console.log(error);
    }
  }, [files, intl, template]);

  const handleChangeAiModel = async (modelId) => {
    try {
      await networkService.put<IAiTemplate>(
        `${appConstants.urls.aiUrl}/usecases/${useCaseId}/templates/${template.templateId}`,
        {
          ...template,
          modelId: modelId,
        }
      );
      toast.success("Template updated successfully");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const updatePrompt = async (prompt) => {
    try {
      if (template.promptTemplates.length === 0) {
        await networkService.post<IPromptTemplate>(
          `${appConstants.urls.aiUrl}/usecases/${useCaseId}/templates/${template.templateId}/prompts`,
          {
            promptText: prompt,
            expectedOutputFormat: "",
          }
        );
        toast.success("Template updated successfully");
      } else {
        await networkService.put<IPromptTemplate>(
          `${appConstants.urls.aiUrl}/usecases/${useCaseId}/templates/${template.templateId}/prompts/${template.promptTemplates[0].promptId}`,
          {
            promptText: prompt,
            expectedOutputFormat: "",
          }
        );
        toast.success("Template updated successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  // TODO: Complete it when the API is ready
  const handleRun = async (values, setSubmitting, resetForm) => {
    let res;
    try {
      if (
        template.inputFormat.fileExtraction === FileExtraction.QUESTIONNAIRES
      ) {
        const data = await extractQuestionnairesData(
          jsonText,
          dateRange,
          selectedNestedOptions
        );

        res = [{ cloudId: data.cloudId, mimeType: "application/json" }];
      } else if (
        template.inputFormat.fileExtraction === FileExtraction.OBSERVATIONS
      ) {
        const data = await extractObservationsData(
          jsonText,
          dateRange,
          selectedNestedOptions
        );
        res = [{ cloudId: data.cloudId, mimeType: "application/json" }];
      } else {
        res = cloudFiles;
        // send cloud files to api
      }

      res.forEach(async (file) => {
        const data = await networkService.post<{
          answer: string;
          question: string;
        }>(`${appConstants.urls.inferenceUrl}/pdf/claude`, {
          prompt: values.prompt,
          mimeType: file.mimeType,
          fileId: file.cloudId,
        });

        setResults((prev) => [...prev, data.answer]);
      });

      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      toast.error(
        intl.formatMessage({
          id: "toast.something_went_wrong",
        })
      );
    }
  };

  return (
    <div className="p-4 bg-white rounded-md shadow-2xl m-4 overflow-auto">
      {/* Header */}
      <div className="border-b pb-2 flex items-center gap-2">
        <Link to="/ai">
          <LeftArrow />
        </Link>
        <div className="flex items-center gap-3">
          <PasteIcon />
          {template?.templateName}
        </div>
      </div>
      {/* data Manipulation */}
      <div>
        <div className="grid grid-cols-4 gap-6 gap-y-8 mt-5">
          <div>
            <FormattedMessage id="ai.prompt.sample_data" />
          </div>
          <div className="col-span-3">
            <FileInput
              name="file"
              files={files}
              multiple={template.inputFormat.multipleFileUpload}
              fileExtension={template.inputFormat.fileType}
              setFiles={setFiles}
            />
          </div>
        </div>
        {template.inputFormat.fileExtraction !== FileExtraction.CLOUD_ID && (
          <div className="grid grid-cols-4 gap-6 gap-y-8 mt-5">
            <div>
              <FormattedMessage id="ai.prompt.select_observation_group" />
            </div>
            <div className="col-span-3">
              <NestedMultiSelect
                options={nestedOptions}
                selectedOptions={selectedNestedOptions}
                setSelectedOptions={setSelectedNestedOptions}
              />
            </div>
            <div>
              <FormattedMessage id="ai.prompt.select_data_and_range" />
            </div>
            <div className="col-span-3">
              <Datepicker
                value={dateRange}
                onChange={setDateRange}
                containerClassName={"w-72 relative"}
              />
            </div>
            {template.inputFormat.fileExtraction ===
              FileExtraction.OBSERVATIONS && (
              <div className="col-span-4 grid grid-cols-4 gap-6 gap-y-8 mt-5">
                <div>
                  <FormattedMessage id="ai.prompt.select_count" />
                </div>
                <div className="col-span-3">
                  <input
                    type="number"
                    placeholder={intl.formatMessage({
                      id: "ai.prompt.enter_count",
                    })}
                    className="rounded-md focus:ring-0 outline-none"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Prompt */}
      <div className="mt-10">
        <div className="flex items-center gap-2">
          <MessageIcon />
          <p className="font-semibold text-2xl">
            <FormattedMessage id="ai.prompt" />
          </p>
        </div>

        <Formik
          initialValues={{
            model: template.modelId,
            prompt: template?.promptTemplates[0]?.text || "",
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleRun(values, setSubmitting, resetForm);
          }}
        >
          {({ values, setFieldValue, getFieldMeta }) => (
            <Form className="grid grid-cols-4 gap-6 gap-y-8 mt-5">
              <div>
                <FormattedMessage id="ai.prompt.ai_model" />
              </div>
              <div className="col-span-3">
                <Field
                  as="select"
                  name="model"
                  id="model"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setFieldValue("model", e.target.value);
                    handleChangeAiModel(e.target.value);
                  }}
                  className={
                    "block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                  }
                >
                  <option value="">
                    {intl.formatMessage({ id: "form.select" })}
                  </option>
                  {models?.map((model, index) => {
                    return (
                      <option key={index} value={model.modelId}>
                        {model.configuration.modelName}
                      </option>
                    );
                  })}
                </Field>

                <ErrorMessage name="model">
                  {(msg) => <FieldError message={msg} />}
                </ErrorMessage>
              </div>

              <div className="col-span-4  p-3 px-4 text-lg font-medium flex justify-between">
                <p>
                  <FormattedMessage id="ai.prompt.prompt_query" />
                </p>
                <p className="mr-24">
                  <FormattedMessage id="ai.prompt.action" />
                </p>
              </div>
              <div className="col-span-4 border border-black p-3 px-4 flex items-center gap-4">
                <div className="w-full">
                  <Field
                    as="textarea"
                    name="prompt"
                    id="prompt"
                    placeholder={intl.formatMessage({
                      id: "ai.prompt.enter_prompt",
                    })}
                    className={
                      "block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                    }
                  />
                  <ErrorMessage name="prompt">
                    {(msg) => <FieldError message={msg} />}
                  </ErrorMessage>
                </div>
                {template.inputFormat.fileExtraction ===
                  FileExtraction.QUESTIONNAIRES && (
                  <div className="px-3 flex items-center gap-2 w-1/3 flex-wrap">
                    {extractTextInBraces(values.prompt).map((label) => {
                      return (
                        <div className="px-2 py-1 border rounded-full border-black text-center">
                          {label}
                        </div>
                      );
                    })}
                  </div>
                )}
                <button
                  type="button"
                  onClick={() => {
                    updatePrompt(values.prompt);
                  }}
                  disabled={
                    values.prompt.length === 0 &&
                    getFieldMeta("prompt").initialValue === values.prompt
                  }
                  className="bg-black mx-16 px-6 h-fit py-2 rounded-md text-white disabled:opacity-50"
                >
                  <FormattedMessage id="form.save" />
                </button>
              </div>

              <div className="col-span-4 text-right">
                <button
                  type="submit"
                  className="bg-black px-10 h-fit py-3 rounded-md text-white"
                >
                  <FormattedMessage id="ai.prompt.run" />
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <hr className="border-black my-10" />
      {/* Result */}

      <div className="grid grid-cols-4 items-start gap-4">
        <p className="col-span-1">
          <FormattedMessage id="ai.prompt.raw_result" />
        </p>

        <div className="col-span-3 grid grid-cols-4 gap-6 gap-y-8">
          <div className="col-span-4">
            {results.map((result, index) => {
              return (
                <div key={index} className="border border-black p-3">
                  {result}
                </div>
              );
            })}
          </div>
        </div>

        <p className="col-span-1">
          <FormattedMessage id="ai.prompt.structured_result" />
        </p>

        <div className="col-span-3 grid grid-cols-4 gap-6 gap-y-8">
          <div className="col-span-4">
            {results.map((result, index) => {
              return (
                <div key={index} className="border border-black p-3">
                  {result}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaygroundTwo;

import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DEFAULT_LOCALE, LOCALES, messages } from "../i18n";
import { networkService } from "../services";
import { useAuthStore, useOrganizationStore } from "../store";
import AppRouter from "./app-router.component";
import { ErrorBoundary } from "./shared/components";
import appConstants from "./shared/config";

const queryClient = new QueryClient();
function getUserLocale(): string {
  let userLocale =
    (navigator.languages && navigator.languages[0]) || navigator.language;

  if (!userLocale) {
    return DEFAULT_LOCALE;
  }

  userLocale = userLocale.split("-")[0];
  const isValid = Object.keys(LOCALES).some(
    (key) => LOCALES[key] === userLocale
  );
  return isValid ? userLocale : DEFAULT_LOCALE;
}

const locale = getUserLocale();

function App() {
  const [clientId, setClientId] = React.useState("");
  const [clientDomain, setClientDomain] = React.useState("");
  const consumer = useAuthStore((state) => state.user);
  const [setOrgId, setOrganizationCode] = useOrganizationStore((state) => [
    state.setOrgId,
    state.setOrganizationCode,
  ]);

  useEffect(() => {
    if (!consumer?.id) {
      const subdomain = window.location.host.split(".")[0];

      const getOrgSetting = async () => {
        if (
          window.location.pathname === "/unauthorized" &&
          subdomain !== process.env.REACT_APP_CLIENT_ORIGIN
        ) {
          return;
        }
        if (subdomain === process.env.REACT_APP_CLIENT_ORIGIN) {
          window.location.href = window.location.origin + "/unauthorized";
        }
        try {
          const orgAuthSetting = await networkService.get<any[]>(
            `${appConstants.urls.baseUrl}/api/organization-settings/auth0-auth?orgurlcode=${subdomain}`
          );
          orgAuthSetting.forEach((element) => {
            if (element.setting === "AUTH0_DOMAIN")
              setClientDomain(element.value);
            if (element.setting === "AUTH0_CLIENT_ID")
              setClientId(element.value);
          });

          setOrganizationCode(orgAuthSetting[0].organization.urlCode);
          setOrgId(orgAuthSetting[0].organization.id);
        } catch (error) {
          console.log(error);
        }
      };

      getOrgSetting();
    }
  }, [
    consumer?.id,
    setClientId,
    setClientDomain,
    setOrganizationCode,
    setOrgId,
  ]);

  return (
    <React.StrictMode>
      <ErrorBoundary fallbackUI={false}>
        <IntlProvider
          locale={locale}
          defaultLocale={DEFAULT_LOCALE}
          messages={messages[locale]}
        >
          {clientId !== "" && (
            <Auth0Provider
              domain={clientDomain}
              clientId={clientId}
              redirectUri={window.location.origin}
            >
              <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                  <AppRouter />
                </QueryClientProvider>
                <ToastContainer
                  theme="colored"
                  bodyClassName="m-0 py-0"
                  toastClassName="text-sm font-medium text-gray-800"
                />
              </BrowserRouter>
            </Auth0Provider>
          )}
        </IntlProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
}

export default App;

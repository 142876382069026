import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IGlossary, IModalConfirm } from "../../interfaces";
import { loggerService, networkService } from "../../services";
import useGlossaryStore from "../../store/localization/glossary.store";
import GenericTable from "../shared/components/table.component";
import GlossaryCreate from "./components/glossary-create.component";
import { useProjectData } from "../shared/hooks";
import { ModalConfirm } from "../shared/components";
import { ModalConfirmModel } from "../../models";

interface RemoveGlossaryInterface {
  glossaryItems: IGlossary[];
  setGlossaryItems: (glossaryItems: IGlossary[]) => void;
  item: IGlossary;
}

const RemoveGlossary: React.FC<RemoveGlossaryInterface> = ({
  glossaryItems,
  setGlossaryItems,
  item,
}) => {
  const [modalConfirm, setModalConfirm] = React.useState<IModalConfirm>(
    () => new ModalConfirmModel()
  );
  const handleDelete = async (item: any) => {
    setModalConfirm({
      open: true,
      title: "Remove Word?",
      message: `Are you sure you want to remove this word?`,
      onConfirm: async () => {
        try {
          const glossaryData = await networkService.delete<IGlossary>(
            `/glossary/${item.id}`
          );
          setGlossaryItems(
            glossaryItems.filter((glossaryItem) => glossaryItem.id !== item.id)
          );
          setModalConfirm((prevState) => ({ ...prevState, open: false }));
        } catch (error) {
          loggerService.error(error);
          setModalConfirm((prevState) => ({ ...prevState, open: false }));
        }
      },
    });

    
  };
  return (
    <div className="flex items-center lg:justify-end">
      <button
        onClick={() => handleDelete(item)}
        className="flex items-center justify-center p-2 text-sm font-medium text-center text-gray-500 transition-colors duration-200 transform rounded-full focus:outline-none hover:text-red-500"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 4H3.33333H14"
            stroke="currentColor"
            strokeLinecap="round"
          />
          <path
            d="M5.33333 4.00016V2.66683C5.33333 2.31321 5.4738 1.97407 5.72385 1.72402C5.9739 1.47397 6.31304 1.3335 6.66666 1.3335H9.33333C9.68695 1.3335 10.0261 1.47397 10.2761 1.72402C10.5262 1.97407 10.6667 2.31321 10.6667 2.66683V4.00016M12.6667 4.00016V13.3335C12.6667 13.6871 12.5262 14.0263 12.2761 14.2763C12.0261 14.5264 11.687 14.6668 11.3333 14.6668H4.66666C4.31304 14.6668 3.9739 14.5264 3.72385 14.2763C3.4738 14.0263 3.33333 13.6871 3.33333 13.3335V4.00016H12.6667Z"
            stroke="currentColor"
            strokeLinecap="round"
          />
          <path
            d="M6.66667 7.3335V11.3335"
            stroke="currentColor"
            strokeLinecap="round"
          />
          <path
            d="M9.33333 7.3335V11.3335"
            stroke="currentColor"
            strokeLinecap="round"
          />
        </svg>
      </button>
      <ModalConfirm {...modalConfirm} setProps={setModalConfirm} />
    </div>
  );
};

const EditGlossary: React.FC<any> = ({
  item,
  setIsEditing,
  setGlossaryCreateOpen,
  setCurrentRow
}) => {
  const handleEditGlossary = async () => {
    setIsEditing(true);
    setGlossaryCreateOpen(true);
    setCurrentRow(item);
  }
  return (
    <button
      onClick={handleEditGlossary}
      className="text-blue-600 hover:text-blue-900 flex gap-1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="w-5 h-5"
      >
        <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
        <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
      </svg>

      <div>Edit</div>
    </button>
  )
};

const Glossary: React.FC = () => {
  const [glossaryCreateOpen, setGlossaryCreateOpen] = useState(false);
  const [glossarySearch, setGlossarySearch] = useState("");
  const [glossaryItems, setGlossaryItems] = useGlossaryStore((state) => [
    state.glossaryItems,
    state.setGlossaryItems,
  ]);
  const [isEditing, setIsEditing] = useState(false);

  const [currentRow, setCurrentRow] = useState();

  const location = useLocation();
  const { projectData, projectId } = useProjectData();

  const getData = async (projectId) => {
    try {
      const glossaryData = await networkService.get<IGlossary[]>(`/glossary?module=${projectId}`);
      setGlossaryItems(glossaryData);
    } catch (error) {
      loggerService.error(error);
    }
  };

  useEffect(() => {
    getData(projectData.module);
  }, [setGlossaryItems]);

  useEffect(() => {
    getData(projectId);
  }, [location.pathname]);

  const colDefs = [
    {
      name: "Term",
      type: "string",
      field: "term"
    },
    {
      name: "Description",
      type: "string",
      field: "description"
    },
    {
      name: "Attributes",
      type: "string",
      field: "eventSubType"
    }];

  return (
    <>
      <GlossaryCreate
        glossaryCreateOpen={glossaryCreateOpen}
        setGlossaryCreateOpen={setGlossaryCreateOpen}
        isEditing={isEditing}
        currentRow={currentRow}
        setIsEditing={setIsEditing}
        glossaryItems={glossaryItems}
      />

      <section className="px-4 py-6 lg:px-8 sm:px-6 ">
        <div className="sm:flex sm:items-center sm:justify-between ">
          <h2 className="text-xl font-medium text-gray-800 ">{glossaryItems.length} Word(s)</h2>

          <div className="mt-4 sm:flex sm:items-center sm:space-x-4 sm:mt-0 ">
            <div className="relative flex-1">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg
                  className="w-5 h-5 text-gray-400"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  ></path>
                </svg>
              </span>

              <input
                type="text"
                className="md:w-72 w-full py-2 pl-10 pr-4 border-gray-400 text-gray-700 bg-transparent border rounded-md"
                placeholder="Search"
                onChange={(e) => setGlossarySearch(e.target.value)}
              />
            </div>

            <button
              onClick={() => setGlossaryCreateOpen(true)}
              className="px-5 w-full sm:w-auto mt-4 sm:mt-0 font-medium py-2.5 text-white rounded-lg bg-primary focus:outline-none"
            >
              Add New term
            </button>
          </div>
        </div>

        <div className="flex flex-col mt-8">
          <div className="-my-2 overflow-x-auto xl:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden rounded-xl">
                {glossaryItems.length > 0 ? (
                  <GenericTable
                    column={colDefs}
                    row={glossaryItems.map((item: IGlossary) => ({
                      term: item.term,
                      description: item.description,
                      eventSubType: `${item.caseSensitive ? "Case Sensitive" : ""
                        }
            ${item.nonTranslatable
                          ? "Non Translatable"
                          : item.caseSensitive
                            ? " & Translatable"
                            : "Translatable"
                        }`,
                      edit: (
                        <EditGlossary
                          item={item}
                          setIsEditing={setIsEditing}
                          setGlossaryCreateOpen={setGlossaryCreateOpen}
                          setCurrentRow={setCurrentRow}
                        />
                      ),
                      remove: (
                        <RemoveGlossary
                          item={item}
                          glossaryItems={glossaryItems}
                          setGlossaryItems={setGlossaryItems}
                        />
                      ),
                    }))}
                    searchField="term"
                    searchTerm={glossarySearch}
                  />
                ) : (
                  <div className="text-center p-12">No Data Found!</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Glossary;

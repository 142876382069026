import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { IAiTemplate, IAiUseCase } from "../../interfaces/ai-models.interface";
import { networkService } from "../../services";
import appConstants from "../shared/config";
import CreateTemplate from "./components/create-ai-template.component";

const AiComponent: React.FC = () => {
  const [openCreateTemplate, setOpenCreateTemplate] = useState(false);
  const [editTemplate, setEditTemplate] = useState<IAiTemplate | null>(null);
  const [CreateTemplateUseCase, setCreateTemplateUseCase] =
    useState<string>("");

  const intl = useIntl();

  const { data: useCases } = useQuery({
    queryKey: ["useCase"],
    queryFn: () => {
      return networkService.get<IAiUseCase[]>(
        `${appConstants.urls.aiUrl}/usecases`
      );
    },
  });

  const handleDelete = async (useCaseId: string, templateId: string) => {
    try {
      await networkService.delete(
        `${appConstants.urls.aiUrl}/usecases/${useCaseId}/templates/${templateId}`
      );
      toast.success(intl.formatMessage({ id: "ai.template_deleted_success" }));
    } catch (error) {
      console.log(error);
      toast.error(intl.formatMessage({ id: "toast.something_went_wrong" }));
    }
  };

  return (
    <div>
      <div className="p-4 w-full">
        <CreateTemplate
          open={openCreateTemplate}
          useCaseId={CreateTemplateUseCase}
          setOpen={setOpenCreateTemplate}
          template={editTemplate}
        />
        <div className="grid grid-cols-5">
          <div className="col-span-1 border border-black p-2 text-center font-semibold">
            <FormattedMessage id="ai.table.use_case_name" />
          </div>
          <div className="col-span-1 border border-black p-2 text-center font-semibold">
            <FormattedMessage id="ai.table.template_name" />
          </div>

          <div className="col-span-1 border border-black p-2 text-center font-semibold">
            <FormattedMessage id="ai.table.active" />
          </div>
          <div className="col-span-2 border border-black p-2 text-center font-semibold">
            <FormattedMessage id="ai.table.actions" />
          </div>
        </div>

        {useCases &&
          useCases?.flatMap((useCase) => {
            return useCase.data.templates.map((template, index) => {
              return (
                <div key={index} className="grid grid-cols-5">
                  <div className="col-span-1 border border-black p-2 text-center font-semibold">
                    {useCase.data.useCaseName}
                  </div>
                  <div className="col-span-1 border border-black p-2 text-center font-semibold">
                    {template.templateName}
                  </div>
                  <div className="col-span-1 border border-black p-2 text-center font-semibold">
                    <p
                      className={`px-2 py-1 text-white ${
                        template.status ? "bg-green-500" : "bg-red-400"
                      }`}
                    >
                      {template.status ? (
                        <FormattedMessage id="ai.template.status.activate" />
                      ) : (
                        <FormattedMessage id="ai.template.status.deactivate" />
                      )}
                    </p>
                  </div>
                  <div className="col-span-2 border border-black p-2 flex items-center justify-center gap-4 font-semibold">
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => {
                          setCreateTemplateUseCase(useCase.useCaseId);
                          setEditTemplate(template);
                          setOpenCreateTemplate(true);
                        }}
                        className="bg-black text-white p-1 px-2"
                      >
                        <FormattedMessage id="form.edit" />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setEditTemplate(null);
                          setCreateTemplateUseCase(useCase.useCaseId);
                          setOpenCreateTemplate(true);
                        }}
                        className="bg-black text-white p-1 px-2"
                      >
                        <FormattedMessage id="ai.template.create_template" />
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          handleDelete(useCase.useCaseId, template.templateId)
                        }
                        className="bg-black text-white p-1 px-2"
                      >
                        <FormattedMessage id="form.delete" />
                      </button>
                      <Link
                        to={`../${template?.inputFormat?.playground
                          ?.toLowerCase()
                          ?.replace("_", "-")}/${
                          useCase.useCaseId
                        }/${template.templateId
                          .toLowerCase()
                          .replace(/\s+/g, "-")}`}
                        className="cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                          />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            });
          })}
      </div>
    </div>
  );
};

export default AiComponent;

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  FileExtraction,
  IAiModel,
  IAiTemplate,
  IPromptTemplate,
} from "../../interfaces/ai-models.interface";
import { networkService } from "../../services";
import { FieldError } from "../shared/components";
import appConstants from "../shared/config";
import LeftArrow from "../shared/icons/left-arrow.icon";
import MessageIcon from "../shared/icons/message.icon";
import PasteIcon from "../shared/icons/paste.icon";
import { uploadTos3 } from "../shared/utils/upload-to-s3";
import FileInput from "./components/ai-file-input";
import Prompt from "./components/ai-prompt.component";

const AiPlaygroundOne: React.FC = () => {
  const intl = useIntl();
  const [cloudFiles, setCloudFiles] = useState<any>([]);
  const [results, setResults] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const queryClient = useQueryClient();

  const validationSchema = Yup.object().shape({
    model: Yup.string().required(intl.formatMessage({ id: "form.required" })),
    prompt: Yup.string().required(intl.formatMessage({ id: "form.required" })),
  });

  const { data: models } = useQuery({
    queryKey: ["models"],
    queryFn: () => {
      return networkService.get<IAiModel[]>(
        `${appConstants.urls.aiUrl}/models`
      );
    },
  });

  const { template, useCaseId } = useOutletContext<{
    template: IAiTemplate;
    useCaseId: string;
  }>();

  useEffect(() => {
    if (!template || !files.length) return;

    const sendToS3 = async (file) => {
      try {
        const data = await uploadTos3(
          file,
          intl.formatMessage({
            id: "shared.file_upload_util.file_upload_error",
          }),
          intl.formatMessage({
            id: "shared.file_upload_util.file_upload_pending",
          })
        );

        if (template.inputFormat.multipleFileUpload) {
          setCloudFiles((prev) => [
            ...prev,
            { cloudId: data.cloudId, mimeType: file.type },
          ]);
        } else {
          setCloudFiles([data]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    try {
      if (template.inputFormat.fileExtraction === FileExtraction.CLOUD_ID) {
        // last of files
        sendToS3(files[files.length - 1]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [files, intl, template, template.inputFormat.fileExtraction]);

  const handleChangeModel = async (modelId) => {
    try {
      await networkService.put<IAiTemplate>(
        `${appConstants.urls.aiUrl}/usecases/${useCaseId}/templates/${template.templateId}`,
        {
          ...template,
          modelId: modelId,
        }
      );
      toast.success(intl.formatMessage({ id: "ai.template_updated_success" }));
    } catch (error) {
      console.log(error);
      toast.error(intl.formatMessage({ id: "toast.something_went_wrong" }));
    }
  };

  const updatePrompt = async (prompt, promptId = "") => {
    try {
      if (promptId === "") {
        await networkService.post<IPromptTemplate>(
          `${appConstants.urls.aiUrl}/usecases/${useCaseId}/templates/${template.templateId}/prompts`,
          {
            promptText: prompt,
            expectedOutputFormat: "",
          }
        );
        queryClient.invalidateQueries({ queryKey: ["template"] });
        toast.success(
          intl.formatMessage({ id: "ai.prompt.prompt_created_success" })
        );
      } else {
        await networkService.put<IPromptTemplate>(
          `${appConstants.urls.aiUrl}/usecases/${useCaseId}/templates/${template.templateId}/prompts/${promptId}`,
          {
            promptText: prompt,
            expectedOutputFormat: "",
          }
        );
        toast.success(
          intl.formatMessage({
            id: "ai.prompt.prompt_updated_success",
          })
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(
        intl.formatMessage({
          id: "toast.something_went_wrong",
        })
      );
    }
  };

  const handleDeletePrompt = async (promptId: string) => {
    try {
      await networkService.delete(
        `${appConstants.urls.aiUrl}/usecases/${useCaseId}/templates/${template.templateId}/prompts/${promptId}`
      );
      queryClient.invalidateQueries({ queryKey: ["template"] });
      toast.success(
        intl.formatMessage({
          id: "ai.prompt.prompt_deleted_success",
        })
      );
    } catch (error) {
      console.log(error);
      toast.error(
        intl.formatMessage({
          id: "toast.something_went_wrong",
        })
      );
    }
  };

  // TODO: Complete it when the API is ready
  const handleRun = async (values, setSubmitting, resetForm) => {
    try {
      console.log(cloudFiles);

      cloudFiles.forEach(async (file) => {
        const data = await networkService.post<{
          answer: string;
          question: string;
        }>(`${appConstants.urls.inferenceUrl}/pdf/claude`, {
          prompt: values.prompt,
          mimeType: file.mimeType,
          fileId: file.cloudId,
        });
        setResults((prev) => [...prev, data.answer]);
      });
    } catch (error) {
      toast.error(
        intl.formatMessage({
          id: "toast.something_went_wrong",
        })
      );
    }
  };

  return (
    <div className="p-4 bg-white rounded-md shadow-2xl m-4 overflow-auto">
      <div className="border-b pb-2 flex items-center gap-2">
        <Link to="/ai">
          <LeftArrow />
        </Link>
        <div className="flex items-center gap-3">
          <PasteIcon />
          {template.templateName}
        </div>
      </div>

      {/* data Manipulation */}
      <div className="grid grid-cols-4 gap-6 gap-y-8 mt-5">
        <div>
          <FormattedMessage id="ai.prompt.sample_data" />
        </div>
        <div className="col-span-3">
          <FileInput
            name="file"
            multiple={template.inputFormat.multipleFileUpload}
            fileExtension={template.inputFormat.fileType}
            files={files}
            setFiles={setFiles}
          />
        </div>
      </div>

      {/* Prompt */}

      <div className="mt-10">
        <div className="flex items-center gap-2">
          <MessageIcon />
          <p className="font-semibold text-2xl">
            <FormattedMessage id="ai.prompt" />
          </p>
        </div>

        <Formik
          initialValues={{
            model: template.modelId,
            prompt: "",
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) =>
            handleRun(values, setSubmitting, resetForm)
          }
        >
          {({ values, setFieldValue, getFieldMeta }) => (
            <Form className="grid grid-cols-4 gap-6 gap-y-8 mt-5">
              <div>
                <FormattedMessage id="ai.prompt.ai_model" />
              </div>
              <div className="col-span-3">
                <Field
                  as="select"
                  name="model"
                  id="model"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setFieldValue("model", e.target.value);
                    handleChangeModel(e.target.value);
                  }}
                  className={
                    "block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                  }
                >
                  <option value="">
                    {intl.formatMessage({ id: "form.select" })}
                  </option>
                  {models?.map((model, index) => {
                    return (
                      <option key={index} value={model.modelId}>
                        {model.configuration.modelName}
                      </option>
                    );
                  })}
                </Field>

                <ErrorMessage name="model">
                  {(msg) => <FieldError message={msg} />}
                </ErrorMessage>
              </div>

              <div className="col-span-4 grid grid-cols-10 border p-2 border-black">
                <div className="col-span-6">
                  <FormattedMessage id="ai.prompt.prompt_query" />
                </div>
                <div className="col-span-2 text-center">
                  <FormattedMessage id="ai.playground_one.health_rec_fields" />
                </div>
                <div className="col-span-2 text-right">
                  <FormattedMessage id="ai.prompt.action" />
                </div>
              </div>

              {template.promptTemplates.map((prompt) => {
                return (
                  <Prompt
                    prompt={prompt}
                    handleUpdateOrSave={updatePrompt}
                    handleDelete={handleDeletePrompt}
                  />
                );
              })}

              {/* For new Prompt */}
              <Prompt
                prompt={null}
                handleUpdateOrSave={updatePrompt}
                handleDelete={handleDeletePrompt}
              />

              <p>
                <FormattedMessage id="ai.prompt.prompt_query" />
              </p>

              <div className="col-span-3">
                <Field
                  as="textarea"
                  name="prompt"
                  id="prompt"
                  placeholder={intl.formatMessage({
                    id: "ai.prompt.enter_prompt",
                  })}
                  className={
                    "block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                  }
                />
                <ErrorMessage name="prompt">
                  {(msg) => <FieldError message={msg} />}
                </ErrorMessage>
              </div>

              <div className="col-span-4 text-right">
                <button
                  type="submit"
                  className="bg-black px-10 h-fit py-3 rounded-md text-white"
                >
                  <FormattedMessage id="ai.prompt.run" />
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <hr className="border-black my-10" />

      {/* Result */}

      <div className="grid grid-cols-4 items-start gap-4">
        <p className="col-span-1">
          <FormattedMessage id="ai.prompt.raw_result" />
        </p>

        <div className="col-span-3 grid grid-cols-4 gap-6 gap-y-8">
          <div className="col-span-4">
            {results.map((result, index) => {
              return (
                <div key={index} className="border border-black p-3">
                  {result}
                </div>
              );
            })}
          </div>
        </div>

        <p className="col-span-1">
          <FormattedMessage id="ai.prompt.structured_result" />
        </p>

        <div className="col-span-3 grid grid-cols-4 gap-6 gap-y-8">
          <div className="col-span-4 flex flex-col gap-2">
            {results.map((result, index) => {
              return (
                <div key={index} className="border border-black p-3">
                  {result}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiPlaygroundOne;

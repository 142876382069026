import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { networkService } from "../../../services";
import appConstants from "../config";

export async function uploadTos3(
  file: File,
  errorMessage: string,
  pendingMessage: string
): Promise<{ cloudId: string } | null> {
  try {
    // get presigned url from server
    const data = await networkService.get<{
      signedUrl: string;
      cloudId: string;
    }>(`${appConstants.urls.platformUrl}/api/files/upload-url`);
    const { signedUrl, cloudId } = data;

    // get content type from file
    const contentType = file.type;

    const config: AxiosRequestConfig = {
      method: "put",
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
      url: signedUrl,
      headers: {
        "Content-Type": contentType,
      },
      data: file,
    };

    // Wrap the Axios request in a Promise
    return toast.promise(
      new Promise<{ cloudId: string }>((resolve, reject) => {
        axios
          .request(config)
          .then((response) => {
            if (response.status === 200) {
              resolve({ cloudId });
            } else {
              reject(response);
              toast.error(errorMessage);
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      }),
      {
        pending: pendingMessage,
        error: errorMessage,
      }
    );
  } catch (error) {
    toast.error(errorMessage);
    return null;
  }
}

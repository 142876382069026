import { loggerService, networkService } from "../../services";
import dateService from "../../services/date.service";
import { PaginationProps } from "../shared/config";
import { IApplogs, rawDataInterface } from "./analytics-raw-data.component";

interface getAnalyticsNetworkDataInterface {
  organizationId: number;
  application: string;
  events: rawDataInterface[];
}

const getAnalyticsData = async (
  startDate: Date,
  endDate: Date,
  appName: string,
  setRawData: (rawData: rawDataInterface[]) => void,
  pageNumber: number = 1,
  setTotalRows: (totalRows: number) => void
) => {
  let url = '';

  if ((startDate !== null && startDate !== undefined) && (endDate !== null && endDate !== undefined )) {
    const startDate_ = dateService.convertToYYYYMMDDFormat(startDate);
    const endDate_ = dateService.convertToYYYYMMDDFormat(endDate);
    url = `usage-analytics/app-logs?module=${appName}&startDate=${startDate_}&endDate=${endDate_}&skip=${pageNumber}&limit=${PaginationProps.rowsPerPage}`;
  } else {
    url = `usage-analytics/app-logs?module=${appName}&skip=${pageNumber}&limit=${PaginationProps.rowsPerPage}`;
  }

  try {
    networkService.get<IApplogs>(url).then((data) => {
      setRawData(data.events);
      if (setTotalRows) {
        setTotalRows(data.total);
      } 
    });
  } catch (error) {
    loggerService.error(error);
  }
};

export { getAnalyticsData };

import React, { useEffect } from "react";
import { ColumnTypes } from "../config";

interface Item<T> {
  attributes: Record<string, T>;
}

interface SortConfig {
  key: keyof Item<any>["attributes"];
  direction: "ascending" | "descending";
  type: string;
}

interface SearchConfig {
  key: keyof Item<any>["attributes"];
  searchTerm: string;
}

interface ManupulateData {
  row: Item<any>["attributes"][];
  filteredItems: Item<any>["attributes"][];
  sort: (key: keyof Item<any>["attributes"], type: string) => void;
  search: (key: keyof Item<any>["attributes"], searchTerm: string) => void;
  sortConfig: SortConfig | null;
  searchConfig: SearchConfig | null;
}

const useManipulateData = (row: Item<any>["attributes"][]): ManupulateData => {
  const [sortConfig, setSortConfig] = React.useState<SortConfig | null>(null);
  const [searchConfig, setSearchConfig] = React.useState<SearchConfig | null>(
    null
  );

  const filteredItems = React.useMemo(() => {
    let sortableItems = [...row];
    if (sortConfig) {
      sortableItems.sort((a, b) => {
        let firstValue, secondValue: Date | string | number;

        if (sortConfig.type === ColumnTypes.Date) {
          firstValue = new Date(a[sortConfig.key]);
          secondValue = new Date(b[sortConfig.key]);
        } else if (sortConfig.type === ColumnTypes.String) {
          firstValue = a[sortConfig.key]?.toLowerCase() || "";
          secondValue = b[sortConfig.key]?.toLowerCase() || "";
        } else {
          firstValue = a[sortConfig.key];
          secondValue = b[sortConfig.key];
        }

        if (firstValue === null || secondValue === null) {
          if (firstValue === null && secondValue === null) {
            return 0;
          } else if (firstValue === null) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          } else {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
        }

        if (firstValue < secondValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        } else if (firstValue > secondValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }

        return 0;
      });
    }

    if (searchConfig) {
      sortableItems = sortableItems.filter((item) => {
        return item[searchConfig.key]
          .toLowerCase()
          .startsWith(searchConfig.searchTerm.toLocaleLowerCase());
      });
    }

    return sortableItems;
  }, [row, sortConfig, searchConfig]);

  const sort = (key: keyof Item<any>["attributes"], type: string) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction, type });
  };

  const search = (key: keyof Item<any>["attributes"], searchTerm: string) => {
    setSearchConfig({ key, searchTerm });
  };

  return { row, filteredItems, sort, search, sortConfig, searchConfig };
};

type TableColumn = {
  name: string;
  type: string;
  field: string;
};

type GenericTableProps = {
  column: TableColumn[];
  row: Record<string, any>[];
  searchField: string;
  searchTerm: string;
};

const GenericTable: React.FC<GenericTableProps> = ({
  column,
  row,
  searchField,
  searchTerm,
}) => {
  const { filteredItems, search, sort } = useManipulateData(row);

  useEffect(() => {
    search(searchField, searchTerm);
  }, [searchTerm, searchField]);

  return (
    <div className="overflow-x-auto">
      <table className=" rounded-md  w-full">
        <thead>
          <tr>
            {column.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 pt-4 pr-16 pb-7 whitespace-nowrap"
              >
                <button
                  type="button"
                  onClick={() => sort(item.field, item.type)}
                  className="flex gap-0.5 items-center space-x-3 text-xs font-normal text-left text-gray-500 uppercase hover:text-primary"
                >
                  <div>{item.name}</div>
                  <svg
                    className="h-3"
                    viewBox="0 0 10 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.78566 7.15666V7.30666H2.93566H3.99072C4.05488 7.30666 4.07675 7.32276 4.08149 7.32694C4.08156 7.32811 4.08154 7.32995 4.08123 7.33265C4.08009 7.34267 4.07466 7.36306 4.05533 7.39317C4.0553 7.39323 4.05526 7.39328 4.05522 7.39334L2.19967 10.2529L2.19932 10.2535C2.16235 10.311 2.12871 10.318 2.11576 10.318C2.1028 10.318 2.06916 10.311 2.03219 10.2535L2.03184 10.2529L0.176289 7.39334C0.176244 7.39327 0.176199 7.3932 0.176154 7.39313C0.156847 7.36304 0.151425 7.34267 0.150276 7.33265C0.149968 7.32995 0.149953 7.32811 0.150022 7.32694C0.154766 7.32276 0.176626 7.30666 0.240787 7.30666H1.29585H1.44585V7.15666V0.53389C1.44585 0.324364 1.62022 0.15 1.82974 0.15H2.40177C2.61129 0.15 2.78566 0.324364 2.78566 0.53389V7.15666Z"
                      fill="currentColor"
                      stroke="#FEFEFE"
                      strokeWidth="0.3"
                    />
                    <path
                      d="M7.78566 3.31135V3.16135H7.93566H8.99072C9.05488 3.16135 9.07675 3.14525 9.08149 3.14108C9.08156 3.13991 9.08154 3.13807 9.08123 3.13537C9.08009 3.12535 9.07466 3.10496 9.05533 3.07485C9.0553 3.07479 9.05526 3.07473 9.05522 3.07468L7.19967 0.215092L7.19932 0.214555C7.16235 0.157044 7.12871 0.149968 7.11576 0.149968C7.1028 0.149968 7.06916 0.157044 7.03219 0.214556L7.03184 0.215091L5.17629 3.07468C5.17624 3.07475 5.1762 3.07482 5.17615 3.07489C5.15685 3.10498 5.15142 3.12535 5.15028 3.13537C5.14997 3.13807 5.14995 3.13991 5.15002 3.14108C5.15477 3.14525 5.17663 3.16135 5.24079 3.16135H6.29585H6.44585V3.31135V9.93413C6.44585 10.1437 6.62022 10.318 6.82974 10.318H7.40177C7.61129 10.318 7.78566 10.1437 7.78566 9.93413V3.31135Z"
                      fill="currentColor"
                      stroke="#FEFEFE"
                      strokeWidth="0.3"
                    />
                  </svg>
                </button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 rounded-md">
          {filteredItems.map((item, idx) => (
            <tr
              key={idx}
              className="transition-colors duration-300 hover:bg-gray-50"
            >
              {Object.keys(item).map((key, index) => (
                <td
                  key={index}
                  className="px-6 py-4 text-gray-800 whitespace-nowrap"
                >
                  {item[key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GenericTable;

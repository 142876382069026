import ReactDOM from "react-dom/client";
import App from "./app/app.component";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import loggerService from "./services/logger/logger.service";

// Create a client

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

// register global(window) error handler
window.onerror = function (
  event: Event | string,
  source?: string,
  lineno?: number,
  colno?: number,
  error?: Error
) {
  loggerService.error("window.onerror", event, source, lineno, colno, error);
  return false;
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useAuthStore } from "../../store";

const Logout: React.FC = () => {
  const { logout: Auth0Signout } = useAuth0();
  const setAuthState = useAuthStore((state) => state.setAuthState);

  React.useEffect(() => {
    setAuthState();
    setTimeout(() => {
      Auth0Signout({
        returnTo: window.location.origin,
      });
    }, 0);
  }, [Auth0Signout, setAuthState]);

  return null;
};

export default Logout;

import { useState } from "react";
import { FormattedMessage } from "react-intl";
import BinIcon from "../../shared/icons/bin.icon";
import DocIcon from "../../shared/icons/doc.icon";

interface FileInputProps {
  name: string;
  multiple?: boolean;
  fileExtension: string;
  files?: File[];
  setFiles?: React.Dispatch<React.SetStateAction<File[]>>;
}

const FileInput: React.FC<FileInputProps> = ({
  name,
  multiple,
  fileExtension,
  files,
  setFiles,
}) => {
  const [loading, setLoading] = useState(false);

  const handleChange = async (e) => {
    try {
      setLoading(true);
      if (multiple) setFiles([...files, e.target.files[0]]);
      else setFiles([e.target.files[0]]);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  return (
    <div className="w-full">
      <label
        htmlFor={name}
        className={`flex flex-col items-center justify-center w-full h-32 border border-dashed rounded-lg cursor-pointer   hover:bg-gray-50 ${
          loading ? "border-gray-400" : "border-black/60"
        }`}
      >
        <div className="w-full px-10 flex items-center justify-between gap-2 pt-5 pb-6">
          <div className="flex flex-col gap-3">
            <div className="flex items-center justify-center gap-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 12V3.85L4.4 6.45L3 5L8 0L13 5L11.6 6.45L9 3.85V12H7ZM2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196666 15.0217 0.000666667 14.5507 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.0217 15.805 14.5507 16.0007 14 16H2Z"
                  fill="black"
                />
              </svg>
              <span>
                <FormattedMessage id="shared.file_input.click_or_drag" />
              </span>
            </div>
            <p>
              <FormattedMessage id="shared.file_input.supported_file_types" />
            </p>
          </div>

          {multiple && (
            <button
              type="button"
              className="border border-black px-6 h-fit py-2 rounded-md"
            >
              +
              <FormattedMessage id="shared.file_input.add_more" />
            </button>
          )}
        </div>
        <input
          id={name}
          name={name}
          data-clarity-mask="True"
          disabled={loading}
          onChange={handleChange}
          type="file"
          className="hidden"
          accept={fileExtension}
        />
      </label>

      {files?.length > 0 && (
        <div className="my-4">
          <p>
            <FormattedMessage id="shared.file_input.files" />
          </p>
          <div>
            {files.map((file) => (
              <div
                key={file.name}
                className="flex items-center justify-between gap-2 border border-black/60 rounded-md px-4 mt-2"
              >
                <div className="flex items-center ">
                  <div className="border-r py-3 pr-4 border-black mr-2">
                    <DocIcon />
                  </div>
                  <span>{file.name}</span>
                </div>
                <button
                  type="button"
                  className="text-red-500"
                  onClick={() =>
                    setFiles(files.filter((f) => f.name !== file.name))
                  }
                >
                  <BinIcon />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FileInput;

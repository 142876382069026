import React from "react";
import { RoutePermission } from "../../../models";
import { AuthorizationResult, RoutePermissionType, UserRole } from "../config";

function useAuthorization(
  routePermission: RoutePermission,
  authState: {
    token: string;
    organizationId: number;
    roles?: Array<string>;
  }
) {
  const getAuthorizationResult = (): AuthorizationResult => {
    const { token, organizationId, roles } = authState;
    const userRoles = roles || [];

    if (routePermission.permissionType === RoutePermissionType.TokenRequired) {
      return token
        ? AuthorizationResult.Success
        : AuthorizationResult.TokenRequired;
    }

    if (routePermission.permissionType === RoutePermissionType.LoginRequired) {
      return token && organizationId
        ? AuthorizationResult.Success
        : AuthorizationResult.TokenRequired;
    }

    if (
      routePermission.permissionType === RoutePermissionType.OneRoleRequired
    ) {
      const isAuthorized = routePermission.permissions.some(
        (permission: UserRole) => userRoles.includes(permission)
      );

      return token && organizationId && isAuthorized
        ? AuthorizationResult.Success
        : AuthorizationResult.Unauthorized;
    }

    if (
      routePermission.permissionType === RoutePermissionType.AllRolesRequired
    ) {
      const isAuthorized = !routePermission.permissions.some(
        (permission: UserRole) => !userRoles.includes(permission)
      );

      return token && organizationId && isAuthorized
        ? AuthorizationResult.Success
        : AuthorizationResult.Unauthorized;
    }

    return AuthorizationResult.Unauthorized;
  };

  return React.useMemo(getAuthorizationResult, [routePermission, authState]);
}

export default useAuthorization;

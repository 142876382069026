import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Navigate } from "react-router-dom";
import { IAuthProfile } from "../../interfaces";
import { loggerService, networkService } from "../../services";
import { useAuthStore } from "../../store";
import { useAuthUser } from "../shared/hooks";

const Organization = () => {
  const authUser = useAuthUser();
  const doesUserExist = authUser?.id;

  const { user } = useAuth0();
  const setAuthUser = useAuthStore((state) => state.setAuthUser);
  const [profiles, setProfiles] = React.useState<Array<IAuthProfile>>();

  React.useEffect(() => {
    if (!user) return;
    const getAuthUser = async () => {
      try {
        const data = await networkService.get<Array<IAuthProfile>>(
          `users?email=${encodeURIComponent(user.email)}`
        );

        setProfiles(data);
        data.length === 1 && setAuthUser(data[0]);
      } catch (error) {
        loggerService.error(error);
      }
    };

    getAuthUser();
  }, [setAuthUser, user]);

  const selectProfile = (profile: IAuthProfile) => {
    setAuthUser(profile);
  };

  const renderProfiles = () => {
    if (!Array.isArray(profiles) || profiles.length === 1) return;

    if (!profiles.length) {
      return <Navigate to="/unauthorized" />;
    }

    return (
      <div className="container flex flex-col items-center px-6 py-8 mx-auto mt-8 space-y-6">
        <h2 className="text-xl font-bold text-center text-gray-800 capitalize sm:text-2xl">
          Select Organization To Proceed...
        </h2>
        {profiles.map((item, index) => (
          <div
            key={index}
            className="w-full max-w-xl p-6 bg-gray-100 rounded-2xl"
          >
            <button
              onClick={() => selectProfile(item)}
              className="flex justify-between items-center space-x-3 text-xl font-medium text-gray-800 capitalize hover:underline hover:text-gray-600"
            >
              <span>{item.organizationName}</span>
              <svg
                width="26"
                height="14"
                viewBox="0 0 26 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.6969 1.066C20.3959 0.722005 19.8369 0.679006 19.4929 0.980006C19.1489 1.28101 19.1059 1.84001 19.4069 2.18401L22.9329 6.26901H1.00294C0.529944 6.26901 0.142944 6.65601 0.142944 7.12901C0.142944 7.60201 0.529944 7.98901 1.00294 7.98901H22.7179L19.3639 11.859C19.0629 12.203 19.1059 12.762 19.4499 13.063C19.6219 13.192 19.7939 13.278 20.0089 13.278C20.2669 13.278 20.4819 13.192 20.6539 12.977L25.8139 7.04301L20.6969 1.066Z"
                  fill="black"
                ></path>
              </svg>
            </button>
          </div>
        ))}
      </div>
    );
  };

  // if not logged-in yet, back to login
  if (doesUserExist) {
    return <Navigate to="/release-note" />;
  }

  // if multiple profiles exist, select org profile
  return <React.Fragment>{renderProfiles()}</React.Fragment>;
};

export default Organization;

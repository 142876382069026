import { ErrorMessage, Field, FieldAttributes } from "formik";
import FieldError from "./field-error.component";

interface FormikInputProps extends FieldAttributes<any> {
  label: string;
  starticon?: React.ReactNode;
}

const FormikInput = (props: FormikInputProps) => {
  return (
    <div className="flex-1 max-sm:w-full relative">
      <label
        htmlFor={props.name}
        className={`text-sm font-medium  ${
          !props.value ? "text-black" : "text-black"
        }`}
      >
        {props.label}
        {props.isRequired && <span className="text-red-500 text-base">*</span>}
      </label>
      <Field
        name={props.name}
        data-clarity-mask="True"
        className={`w-full py-[0.6rem] px-4 border border-gray-300 bg-light-blue/15 rounded-md mt-[0.3rem] disabled:bg-gray-200 placeholder:text-gray-400 focus:outline-none focus:ring-0 ${
          props?.starticon ? "pl-8" : ""
        }`}
        {...props}
      >
        {props.children}
      </Field>
      {props?.starticon && (
        <div className="absolute top-[2.7rem] left-2">{props.starticon}</div>
      )}

      <ErrorMessage name={props.name}>
        {(msg) => <FieldError message={msg} />}
      </ErrorMessage>
    </div>
  );
};

export default FormikInput;

import { useQueryClient } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { IAiTemplate } from "../../../interfaces/ai-models.interface";
import { networkService } from "../../../services";
import FormikInput from "../../shared/components/formik-input";
import ModalLayout from "../../shared/components/modal-layout.component";
import appConstants from "../../shared/config";

interface CreateTemplateProps {
  open: boolean;
  useCaseId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  template: IAiTemplate | null;
}

const CreateTemplate: React.FC<CreateTemplateProps> = ({
  open,
  useCaseId,
  template,
  setOpen,
}) => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const templateValidationSchema = Yup.object().shape({
    templateName: Yup.string().required(
      intl.formatMessage({ id: "form.required" })
    ),
    status: Yup.string().required(intl.formatMessage({ id: "form.required" })),
    modelId: Yup.string().required(intl.formatMessage({ id: "form.required" })),
    playground: Yup.string().required(
      intl.formatMessage({ id: "form.required" })
    ),
    multipleFileUpload: Yup.string().required(
      intl.formatMessage({ id: "form.required" })
    ),
    fileType: Yup.string().required(
      intl.formatMessage({ id: "form.required" })
    ),
    fileExtraction: Yup.string().required(
      intl.formatMessage({ id: "form.required" })
    ),
  });

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    try {
      const payload = {
        templateName: values.templateName,
        status: JSON.parse(values.status),
        modelId: values.modelId,
        inputFormat: {
          playground: values.playground,
          multipleFileUpload: JSON.parse(values.multipleFileUpload),
          fileType: values.fileType,
          fileExtraction: values.fileExtraction,
        },
        outputFormat: {},
      };

      if (template) {
        await networkService.put(
          `${appConstants.urls.aiUrl}/usecases/${useCaseId}/templates/${template.templateId}`,
          payload
        );
        queryClient.invalidateQueries({ queryKey: ["useCase"] });
      } else {
        await networkService.post(
          `${appConstants.urls.aiUrl}/usecases/${useCaseId}/templates`,
          payload
        );
      }

      queryClient.invalidateQueries({ queryKey: ["useCase"] });
      resetForm();
      setSubmitting(false);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  };

  return (
    <ModalLayout
      title={intl.formatMessage({
        id: "create_template.heading",
      })}
      open={open}
      setProps={setOpen}
    >
      <Formik
        initialValues={{
          templateName: template?.templateName || "",
          status: template?.status ? "true" : "false" || "",
          modelId: template?.modelId || "",
          playground: template?.inputFormat.playground || "",
          multipleFileUpload: template?.inputFormat.multipleFileUpload
            ? "true"
            : "false" || "",
          fileType: template?.inputFormat.fileType || "",
          fileExtraction: template?.inputFormat.fileExtraction || "",
          outputFormat: {},
        }}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmit(values, setSubmitting, resetForm);
        }}
        validationSchema={templateValidationSchema}
      >
        {({ values, isSubmitting, dirty }) => (
          <Form>
            <section className="p-4">
              <div className="flex gap-6 items-start max-sm:flex-col max-sm:w-full max-sm:gap-3">
                <FormikInput
                  name="templateName"
                  placeholder={intl.formatMessage({
                    id: "create_template.template_name.placeholder",
                  })}
                  label={intl.formatMessage({
                    id: "create_template.template_name.placeholder",
                  })}
                  type="text"
                  value={values.templateName}
                />
              </div>
              <div className="flex gap-6 items-start max-sm:flex-col max-sm:w-full max-sm:gap-3">
                <FormikInput
                  name="modelId"
                  placeholder={intl.formatMessage({
                    id: "create_template.model_id.placeholder",
                  })}
                  label={intl.formatMessage({
                    id: "create_template.model_id.label",
                  })}
                  type="text"
                  value={values.modelId}
                />
              </div>
              <div>
                <FormikInput
                  name="status"
                  placeholder={intl.formatMessage({
                    id: "create_template.status.placeholder",
                  })}
                  type="text"
                  label={intl.formatMessage({
                    id: "create_template.status.label",
                  })}
                  value={values.status}
                  component="select"
                >
                  <option className="text-gray-400" value="" disabled>
                    <FormattedMessage id="create_template.status.placeholder" />
                  </option>
                  <option value="true">
                    <FormattedMessage id="ai.template.status.activate" />
                  </option>
                  <option value="false">
                    <FormattedMessage id="ai.template.status.deactivate" />
                  </option>
                </FormikInput>
              </div>
              <div>
                <FormikInput
                  name="playground"
                  placeholder={intl.formatMessage({
                    id: "create_template.playground.placeholder",
                  })}
                  label={intl.formatMessage({
                    id: "create_template.playground.label",
                  })}
                  type="text"
                  value={values.playground}
                />
              </div>
              <div>
                <FormikInput
                  name="multipleFileUpload"
                  placeholder={intl.formatMessage({
                    id: "create_template.multiple_file_upload.placeholder",
                  })}
                  type="text"
                  label={intl.formatMessage({
                    id: "create_template.multiple_file_upload.label",
                  })}
                  value={values.multipleFileUpload}
                  component="select"
                >
                  <option className="text-gray-400" value="" disabled>
                    <FormattedMessage id="create_template.multiple_file_upload.placeholder" />
                  </option>
                  <option value="true">
                    <FormattedMessage id="option.yes" />
                  </option>
                  <option value="false">
                    <FormattedMessage id="option.no" />
                  </option>
                </FormikInput>
              </div>

              <div>
                <FormikInput
                  name="fileType"
                  placeholder={intl.formatMessage({
                    id: "create_template.file_type.placeholder",
                  })}
                  label={intl.formatMessage({
                    id: "create_template.file_type.label",
                  })}
                  type="text"
                  value={values.fileType}
                />
              </div>
              <div>
                <FormikInput
                  name="fileExtraction"
                  placeholder={intl.formatMessage({
                    id: "create_template.file_extraction.placeholder",
                  })}
                  label={intl.formatMessage({
                    id: "create_template.file_extraction.label",
                  })}
                  type="text"
                  value={values.fileExtraction}
                />
              </div>
            </section>
            <div className="mt-4 p-4 text-right bg-gray-200">
              <button
                type="submit"
                disabled={isSubmitting || !dirty}
                className="bg-primary text-white font-medium rounded-md py-3 px-6 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSubmitting ? (
                  <div className="flex items-center justify-center">
                    <div>
                      <FormattedMessage id="form.submitting" />
                    </div>
                  </div>
                ) : (
                  <p>
                    <FormattedMessage id="form.submit" />
                  </p>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalLayout>
  );
};

export default CreateTemplate;

import React, { useEffect } from "react";
import {
  LocalizationFiles,
  LocalizationGlossary,
  LocalizationLanguages,
  LocalizationTranslations,
} from ".";
import ProjectNav from "./components/project-nav.component";

const ProjectDetail: React.FC = () => {
  const [openTab, setOpenTab] = React.useState(
    parseInt(localStorage.getItem("loc-pro-nav")) || 1
  );

  useEffect(() => {
    const locProNav = localStorage.getItem("loc-pro-nav");

    if (locProNav) {
      setOpenTab(parseInt(locProNav));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("loc-pro-nav", openTab.toString());
  }, [openTab]);

  return (
    <div className="flex flex-wrap">
      <div className="w-full">
        <ProjectNav openTab={openTab} setOpenTab={setOpenTab} />

        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded">
          <div className="px-4 py-5 flex-auto">
            <div className="tab-content tab-space">
              <div
                className={openTab === 1 ? "block" : "hidden"}
                id="translation"
              >
                <LocalizationTranslations isActiveTab={openTab === 1} />
              </div>
              <div className={openTab === 2 ? "block" : "hidden"} id="glossary">
                <LocalizationGlossary />
              </div>
              <div
                className={openTab === 3 ? "block" : "hidden"}
                id="languages"
              >
                <LocalizationLanguages />
              </div>
              <div className={openTab === 4 ? "block" : "hidden"} id="files">
                <LocalizationFiles isActiveTab={openTab === 4} />
              </div>
              {/* <div
                className={openTab === 5 ? "block" : "hidden"}
                id="Collaborators"
              >
                <LocalizationCollaborators />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetail;

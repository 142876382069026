import { useEffect, useState } from "react";
import { loggerService } from "../../../services";
import dateService from "../../../services/date.service";

import { useSidebarStore } from "../../../store";
import { Header, Pagination } from "../../shared/components";
import GenericTable from "../../shared/components/table.component";
import { PaginationProps } from "../../shared/config";
import { getAuditData, handleExportData } from "../audit-raw-utilities";
import AuditTime from "./audit-timeline.component";

export interface IAuditData {
  createdAt: string;
  createdBy: string;
  createdById: number;
  errorDescription: string;
  eventAction: string;
  eventData: { params: {}; queryParams: {} };
  eventDescription: string;
  eventSubType: string;
  eventType: string;
  id: string;
  module: string;
  organizationId: number;
  organizationName: string;
  source: string;
}

export interface IAuditlogs {
  events: IAuditData[];
  total: number;
}

const AuditLogs: React.FC = () => {
  const [rawData, setRawData] = useState<IAuditData[]>(null);

  const toggleSidebar = useSidebarStore((state) => state.toggleSidebar);
  const colDefs = [
    {
      name: "Module",
      type: "string",
      field: "module",
    },
    {
      name: "Event Type",
      type: "string",
      field: "eventType",
    },
    {
      name: "Event SubType",
      type: "string",
      field: "eventSubType",
    },
    {
      name: "Event Description",
      type: "string",
      field: "eventDescription",
    },
    {
      name: "Organization ID",
      type: "number",
      field: "organizationId",
    },
    {
      name: "Created At",
      type: "date",
      field: "createdAt",
    },
  ];

  const [totalRows, setTotalRows] = useState<number>(0);
  const [selectedDates, setSelectedDateValue] = useState({
    startDate: null,
    endDate: null,
  });

  const rowsPerPage = PaginationProps.rowsPerPage;

  const [skipNumber, setSkipNumber] = useState<number>(0);

  const [resetToFirstPage, setResetToFirstPage] = useState<boolean>(false);

  useEffect(() => {
    try {
      getAuditData(
        selectedDates?.startDate,
        selectedDates?.endDate,
        setRawData,
        skipNumber,
        setTotalRows
      );
    } catch (error) {
      loggerService.error(error);
    }
  }, [skipNumber]);

  const pageChangeHandler = (currentPage) => {
    const skip = (currentPage - 1) * rowsPerPage;
    setSkipNumber(skip);
    setResetToFirstPage(false);
  };

  useEffect(() => {
    if (selectedDates.startDate !== null && selectedDates.endDate !== null) {
      setResetToFirstPage(true);
    }
  }, [selectedDates]);

  return (
    <>
      <Header title="Audit Logs" setSidebarOpen={toggleSidebar}>
        <button
          className="items-center hidden space-x-2 font-medium lg:flex focus:outline-none text-primary hover:text-gray-500"
          onClick={() => handleExportData(selectedDates)}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.25 8.75V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V8.75"
              stroke="#546CCC"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.08325 5.83325L6.99992 8.74992L9.91659 5.83325"
              stroke="#546CCC"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 8.75V1.75"
              stroke="#546CCC"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Export Data</span>
        </button>
      </Header>
      <main className="px-8 py-4 h-full overflow-y-auto">
        <AuditTime
          setRawData={setRawData}
          setTotalRows={setTotalRows}
          setSelectedDateValue={setSelectedDateValue}
        />

        {rawData?.length > 0 && (
          <GenericTable
            column={colDefs}
            row={rawData.map((item) => ({
              module: item.module,
              eventType: item.eventType,
              eventSubType: item.eventSubType,
              eventDescription: item.eventDescription,
              // eventData: item.eventData !== "null" ? item.eventData : "",
              organizationId: item.organizationId,
              createdAt: dateService.convertToDDMMYYYYFormat(item.createdAt),
            }))}
            searchField="module"
            searchTerm=""
          />
        )}
        {rawData?.length === 0 && (
          <div className="flex justify-center items-center h-96">
            No Data Found
          </div>
        )}
        {rawData?.length > 0 && (
          <Pagination
            totalRows={totalRows}
            pageCount={Math.ceil(totalRows / rowsPerPage)}
            onPageChange={pageChangeHandler}
            rowsPerPage={rowsPerPage}
            resetToFirstPage={resetToFirstPage}
          />
        )}
      </main>
    </>
  );
};

export default AuditLogs;

const ShowJson: React.FC = () => {
  const jsonData = {
    language: "ENGLISH_UK",
    Signup: "Please Signup",
    getStarted: "Get started",
    Submit: "Submit",
    Continue: "Continue",
    welcomeTo: "Welcome",
    Login: "Please Login!",
  };

  const PrettyPrintJson = (
    <div>
      <pre>{JSON.stringify(jsonData, null, 2)}</pre>
    </div>
  );

  return <div>{PrettyPrintJson}</div>;
};

export default ShowJson;

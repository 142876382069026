export interface IPromptTemplate {
  promptId: string;
  text: string;
  expectedOutputFormat: string;
}
export interface IAiModel {
  modelId: string;
  configuration: {
    modelName: string;
    parameters: {
      temperature: string;
    };
  };
}

export enum FileExtraction {
  OBSERVATIONS = "OBSERVATIONS",
  QUESTIONNAIRES = "QUESTIONNAIRES",
  CLOUD_ID = "CLOUD_ID",
}

export enum Playground {
  PLAYGROUND_ONE = "playground-one",
  PLAYGROUND_TWO = "playground-two",
}

export interface IAiTemplate {
  templateName: string;
  modelId: string;
  templateId: string;
  inputFormat: {
    playground: Playground;
    multipleFileUpload: boolean;
    fileType: string;
    fileExtraction: FileExtraction;
  };
  outputFormat: any;
  promptTemplates: IPromptTemplate[];
  status: string;
}

export interface IAiUseCase {
  useCaseId: string;
  data: {
    useCaseName: string;
    organizationCode: string;
    templates: IAiTemplate[];
  };
}

import React from "react";

import Rawdata from "./analytics-raw-data.component";
import { NotFound } from "../shared/components";
import AnalyticsNav from "./components/analytics-nav.component";

const ProjectDetail: React.FC = () => {
  const [openTab, setOpenTab] = React.useState(1);

  return (
    <div className="flex flex-wrap">
      <div className="w-full">
        <AnalyticsNav openTab={openTab} setOpenTab={setOpenTab} />

        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded">
          <div className="px-4 py-5 flex-auto">
            <div className="tab-content tab-space">
              <div
                className={openTab === 1 ? "block" : "hidden"}
                id="translation"
              >
                <Rawdata />
              </div>
              <div className={openTab === 2 ? "block" : "hidden"} id="glossary">
                <NotFound />
              </div>
              <div
                className={openTab === 3 ? "block" : "hidden"}
                id="languages"
              >
                <NotFound />
              </div>
              <div className={openTab === 4 ? "block" : "hidden"} id="files">
                <NotFound />
              </div>
              <div
                className={openTab === 5 ? "block" : "hidden"}
                id="Collaborators"
              >
                <NotFound />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetail;

import { Outlet, useLocation } from "react-router-dom";
import { useSidebarStore } from "../../store";
import { Header } from "../shared/components";
import LocalizationProjects from "./loc-projects.component";

const LocalizationProvider: React.FC = () => {
  const location = useLocation();
  const toggleSidebar = useSidebarStore((state) => state.toggleSidebar);

  const flag =
    location.pathname === "/localization/projects" ||
    location.pathname === "/localization/projects/";
  return (
    <>
      <Header title="Localization" setSidebarOpen={toggleSidebar}>
        {/* <HeaderButton name={"Add new project"} toggleOverLay={toggleOverLay} /> */}
      </Header>
      {flag ? (
        <LocalizationProjects />
      ) : (
        <main className="flex-1 overflow-y-auto">
          <Outlet />
        </main>
      )}
    </>
  );
};

export default LocalizationProvider;

import messages_de from "./lang/de.json";
import messages_en from "./lang/en.json";
import messages_fr from "./lang/fr.json";
import { LOCALES } from "./locales";

const messages = {
  [LOCALES.ENGLISH]: messages_en,
  [LOCALES.FRENCH]: messages_fr,
  [LOCALES.GERMAN]: messages_de,
};

export default messages;

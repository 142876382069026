const storageTypes = {
  local: localStorage,
  session: sessionStorage,
};

export enum UserRole {
  Admin = "OWNER",
  Member = "MEMBER",
}

export enum AuthorizationResult {
  Success = 1,
  Unauthorized = 2,
  LoginRequired = 3,
  TokenRequired = 4,
}

export enum RoutePermissionType {
  OneRoleRequired = 1,
  AllRolesRequired = 2,
  LoginRequired = 3,
  TokenRequired = 4,
}

export enum EventType {
  OneOnOneEvent = "ONE_ON_ONE_EVENT",
}

export const ColumnTypes = {
  Date: "date",
  String: "string",
  Number: "number",
};

export const PaginationProps = {
  rowsPerPage: 25,
};

const keys = {
  auth: "authInfo",
  storage: storageTypes.session,
};

class AppUrls {
  baseUrl = `${process.env.REACT_APP_API_URL}`;
  inferenceUrl = `${process.env.REACT_APP_INFERENCE_URL}`;
  aiUrl = `${process.env.REACT_APP_AI_URL}`;
  platformUrl = `${process.env.REACT_APP_PLATFORM_URL}`;
  logError = `error/log-error`;
}

const appConstants = {
  keys,
  urls: new AppUrls(),
};

export default appConstants;

import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
//import { UPDATE_SERVICE_PROVIDER } from "../../apollo/service-provider/service-provider.mutations";
import { IAuthProfile, IModalConfirm } from "../../interfaces";
import { ModalConfirmModel } from "../../models/shared.model";
import { loggerService, networkService } from "../../services";
import {
  useAuthStore,
  useHeaderButtonOverlay,
  useSidebarStore,
} from "../../store";
import {
  FieldError,
  Header,
  ModalConfirm,
  ProfilePicture,
} from "../shared/components";
import { useAuthUser } from "../shared/hooks";
import {
  getProfilePayload,
  getPropertyValue,
} from "../shared/utils/helper.util";

const profileSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required."),
  lastName: Yup.string().required("Last name is required."),
});

const Profile: React.FC = () => {
  const authUser = useAuthUser(),
    updateAuthUser = useAuthStore((state) => state.updateAuthUser);

  const toggleSidebar = useSidebarStore((state) => state.toggleSidebar);
  const toggleOverLay = useHeaderButtonOverlay((state) => state.toggleOverLay);

  const [modalConfirm, setModalConfirm] = React.useState<IModalConfirm>(
    () => new ModalConfirmModel()
  );

  const handleSubmit = async (
    profile: Partial<IAuthProfile>,
    { resetForm }
  ) => {
    try {
      const changes: Partial<IAuthProfile> = {
          firstName: profile.firstName,
          lastName: profile.lastName,
          profilePic: profile.profilePic,
        },
        userPayload: Partial<IAuthProfile> = {
          ...getProfilePayload(authUser),
          ...changes,
        };

      await networkService.put<IAuthProfile>(
        `/serviceProviders/${authUser.id}`,
        userPayload
      );

      // update auth store & show success message
      resetForm();
      updateAuthUser(changes);
      toast.success("Profile details updated successfully.");
    } catch (err) {
      loggerService.error("Profile", err);
      toast.error(err.mesaage);
    }
  };

  const onDeactivateProfile = React.useCallback(() => {
    setModalConfirm({
      open: true,
      title: "Disable Peofile",
      message:
        "Are you sure you want to delete/disable the service provider profile?",
      onConfirm: async () => {
        try {
          // close modal
          setModalConfirm((prevState) => ({ ...prevState, open: false }));

          // delele/decativate serviceprovider

          // await networkService.delete(
          //   `/serviceProviders/${authUser.id}`
          // );

          // update the react state

          toast.success("Profile details deleted successfully.");
        } catch (error) {
          loggerService.error(error.mesaage);
          toast.error(error.mesaage);
        }
      },
    });
  }, []);

  return (
    <div>
      <div className="flex flex-col flex-1 min-h-screen overflow-hidden bg-gray-100">
        <Header title="Profile" setSidebarOpen={toggleSidebar} children={""} />
        {/* <HeaderButton name={"Add new project"} toggleOverLay={toggleOverLay} /> */}
        {/* </Header> */}

        <main className="flex-1 px-4 py-8 overflow-y-auto lg:max-h-[calc(100vh-4rem)]">
          <Formik
            initialValues={{
              firstName: authUser.firstName || "",
              lastName: authUser.lastName || "",
              profilePic: authUser.profilePic || "",
            }}
            enableReinitialize
            validationSchema={profileSchema}
            onSubmit={handleSubmit}
          >
            {({ dirty, values, setFieldValue }) => (
              <Form className="max-w-2xl">
                <div className="flex items-center justify-between">
                  <h2 className="text-xl font-medium text-gray-800">Profile</h2>
                  <div className="flex items-center justify-center mt-4 space-x-4 sm:mt-0">
                    <button
                      type="submit"
                      disabled={!dirty}
                      className="px-6 py-2 font-medium text-center text-white capitalize transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80 disabled:bg-opacity-60"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>

                <div className="p-8 mt-6 bg-white shadow rounded-xl">
                  <div className="space-y-6 sm:flex sm:space-y-0 sm:space-x-4">
                    <div className="sm:w-1/2">
                      <label className="text-sm font-medium text-gray-400">
                        First Name
                      </label>
                      <Field
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="First name"
                        className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                      />
                      <ErrorMessage name="firstName">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                    </div>

                    <div className="sm:w-1/2">
                      <label className="text-sm font-medium text-gray-400">
                        Last Name
                      </label>
                      <Field
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="Last name"
                        className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"
                      />
                      <ErrorMessage name="lastName">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                    </div>
                  </div>

                  <div className="mt-6">
                    <label className="text-sm font-medium text-gray-400">
                      Email
                    </label>
                    <p className="block w-full p-3 mt-2 border border-gray-300 bg-slate-100 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                      {authUser.email}
                    </p>
                  </div>

                  <div className="mt-6">
                    <label className="text-sm font-medium text-gray-400">
                      Organization Name
                    </label>
                    <p className="block w-full p-3 mt-2 border border-gray-300 bg-slate-100 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                      {getPropertyValue(authUser, "organizationName", "--")}
                    </p>
                  </div>

                  <div className="mt-6">
                    <Field name="profilePic">
                      {(props: FieldProps<string>) => (
                        <ProfilePicture {...props} />
                      )}
                    </Field>
                  </div>

                  {/* <div className="mt-6 flex flex-row">
                    <div className="mt-6 basis-2/3">
                      <div>
                        <label className="text-lg font-medium text-gray-800">
                          Deactivate Profile
                        </label>
                      </div>
                      <div>
                        <label className="text-sm font-small text-gray-400">
                          Once you deactivate your account, you will need to
                          contact support to get it back
                        </label>
                      </div>
                    </div>
                    <div className="mt-6 basis-1/3">
                      <button
                        type="button"
                        id="id"
                        name="id"
                        // disabled={!value}

                        onClick={onDeactivateProfile}
                        className="flex items-center py-8 space-x-2 text-gray-400 hover:text-primary disabled:opacity-40 disabled:cursor-not-allowed"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="red"
                          // stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                        <span>Deactivate Account</span>
                      </button>
                    </div>
                  </div> */}
                </div>
              </Form>
            )}
          </Formik>
        </main>
      </div>

      <ModalConfirm {...modalConfirm} setProps={setModalConfirm} />
    </div>
  );
};

export default Profile;

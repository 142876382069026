import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { IProjectDetails } from "../interfaces";
import { loggerService, networkService } from "../services";
import { useOrganizationStore, useSidebarStore } from "../store";
import { Sidebar } from "./shared/components";
import { useAuthUser } from "./shared/hooks";

const Layout: React.FC = () => {
  const authUser = useAuthUser();
  const [sidebarOpen, toggleSidebar] = useSidebarStore((state) => [
    state.sidebarOpen,
    state.toggleSidebar,
  ]);

  const setProjects = useOrganizationStore((state) => state.setProjects);

  useEffect(() => {
    if (!authUser?.id) return;
    const getOrganizationModules = async () => {
      try {
        const projects = await networkService.get<IProjectDetails[]>(
          "/configurations/organization-modules"
        );
        setProjects(projects);
      } catch (error) {
        loggerService.error(error);
      }
    };
    getOrganizationModules();
  }, [authUser?.id, setProjects]);

  return (
    <div className="h-screen bg-gray-100 lg:overflow-hidden lg:flex font-dm-sans">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={toggleSidebar} />
      <div className="flex flex-col flex-1 min-h-screen overflow-hidden bg-gray-100 ">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;

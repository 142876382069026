import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FieldAttributes,
} from "formik";
import BinIcon from "../icons/bin.icon";

interface FormikInputProps extends FieldAttributes<any> {
  label: string | JSX.Element;
  buttonText: string | JSX.Element;
  arrayFields: Array<{ label: string; type: string; attributes?: any }>;
}

const ArrayInput = (props: FormikInputProps) => {
  return (
    <div className="w-full">
      <p className="mb-2 font-semibold">{props.label}</p>

      <FieldArray name={props.name} data-clarity-mask="True">
        {(p: FieldArrayRenderProps) => {
          return (
            <ArrayInputFormik
              {...p}
              buttonText={props.buttonText}
              arrayFields={props.arrayFields}
            />
          );
        }}
      </FieldArray>
    </div>
  );
};

export default ArrayInput;

interface IArrayInputFormikProps extends FieldArrayRenderProps {
  buttonText: string | JSX.Element;
  arrayFields: Array<{ label: string; type: string; attributes?: any }>;
}

const ArrayInputFormik: React.FC<IArrayInputFormikProps> = ({
  name,
  buttonText,
  arrayFields,
  form: { values },
  ...arrayHelpers
}) => {
  const arrayValues = values[name];

  const addItem = () => {
    const obj = arrayFields.reduce((acc, item) => {
      return { ...acc, [item.label]: "" };
    }, {});
    arrayHelpers.push(obj);
  };

  const deleteItem = (index: number) => {
    arrayHelpers.remove(index);
  };

  return (
    <div className="w-full flex flex-col gap-4">
      {arrayValues?.map((_, index) => (
        <div
          key={index}
          className={`grid w-full gap-2`}
          style={{
            gridTemplateColumns: `repeat(${arrayFields.length}, 1fr) 0.2fr`,
          }}
        >
          {arrayFields.map((item, i) => {
            return (
              <div key={i} className="w-full">
                <Field
                  name={`${name}.${index}.${item?.label}`}
                  type={item?.type}
                  placeholder={item?.label}
                  className="w-full rounded-md border border-[#C8CDF8] p-2 px-4 outline-none focus:ring-0"
                />
              </div>
            );
          })}
          <div className="col-span-1 flex items-center justify-end">
            <button
              type="button"
              className="pr-4 text-sm text-red-500"
              onClick={() => deleteItem(index)}
            >
              <BinIcon />
            </button>
          </div>
        </div>
      ))}

      <button
        type="button"
        onClick={addItem}
        className="w-full p-2 font-semibold text-primary text-right"
      >
        <p className="mr-16">{buttonText}</p>
      </button>
    </div>
  );
};

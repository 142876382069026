import React from "react";
import { useLocation } from "react-router-dom";


import { useHeaderButtonOverlay, useProjectStore, useSidebarStore } from "../../store";
import { Header, HeaderButton, NotFound } from "../shared/components";
import { networkService } from "../../services";

import NotificationProjectDetail from "./notifications-project-details.component";
import NotificationsProjects from "./notifications-projects.component";

const NotificationsProvider: React.FC = () => {
  const location = useLocation();
    const projectDetails = useProjectStore((state) => state.projects);
  const toggleSidebar = useSidebarStore((state) => state.toggleSidebar);
  const toggleOverLay = useHeaderButtonOverlay((state) => state.toggleOverLay);

  const flag =
    location.pathname === "/notifications/projects" ||
    location.pathname === "/notifications/projects/";

  const projectId = location.pathname.split("/")[3];

  const handleScheduleNotification = async () => {
    console.log("schedule notification");
  };

  return (
    <>
      {flag ? (
        <>
          <Header title="Notifications" setSidebarOpen={toggleSidebar}>
            <HeaderButton name="Add Project" toggleOverLay={toggleOverLay} />
          </Header>
          <NotificationsProjects />
        </>
      ) : (
        <>
          <Header title="Notifications" setSidebarOpen={toggleSidebar}>
            <HeaderButton
              name="Schedule New Notification"
              toggleOverLay={handleScheduleNotification}
            />
          </Header>
          <main className="flex-1 overflow-y-auto ">
            <NotificationProjectDetail />
          </main>
        </>
      )}
    </>
  );
};

export default NotificationsProvider;

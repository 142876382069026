import { AxiosRequestConfig } from "axios";
import { IAuthProfile } from "../../interfaces";
import { useAuthStore, useOrganizationStore } from "../../store";

const requestInterceptor = (config: AxiosRequestConfig) => {
  const authToken: string = useAuthStore.getState().token;
  const authUser: IAuthProfile = useAuthStore.getState().user;
  const organization_code: string =
    useOrganizationStore.getState().organization_code;

  if (authToken) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${authToken}`,
      organizationcode: process.env.REACT_APP_ORG_CODE_FOR_AI,
    });
  }

  if (authUser?.organizationId) {
    Object.assign(config.headers, {
      organizationid: authUser.organizationId,
    });
  }

  if (organization_code) {
    Object.assign(config.headers, {
      organization_code,
    });
  }

  return config;
};

export default requestInterceptor;

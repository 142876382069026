interface addOverlayLayoutProps {
  overlayOpen: boolean;
  setOverlayOpen: (open: boolean) => void;
  title: string;
  children: React.ReactNode;
}

const addOverlayLayout: React.FC<addOverlayLayoutProps> = ({
  overlayOpen,
  setOverlayOpen,
  title,
  children,
}) => {
  return (
    <div className="text-gray-800 bg-gray-100 font-dm-sans">
      <div
        onClick={() => setOverlayOpen(false)}
        className={
          "fixed inset-0 z-40 transition-opacity bg-black opacity-50 " +
          (overlayOpen ? "block" : "hidden")
        }
      ></div>

      <div
        className={
          "fixed inset-y-0 right-0 z-50 flex flex-col h-screen overflow-y-auto transition duration-200 transform bg-white border-r border-gray-200 w-full sm:w-[28rem] " +
          (overlayOpen ? "translate-x-0 ease-in" : "translate-x-full ease-out")
        }
      >
        <div className="flex items-center justify-between px-6 py-8 space-x-4 sm:space-x-0">
          <div>
            <h3 className="text-2xl font-medium text-gray-800">{title}</h3>
          </div>

          <button
            onClick={() => setOverlayOpen(false)}
            className="text-gray-600 focus:text-primary focus:outline-none"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 4.5L4.5 13.5"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.5 4.5L13.5 13.5"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>

        <main className="flex flex-col flex-1 px-6 pb-6">{children}</main>
      </div>
    </div>
  );
};

export default addOverlayLayout;

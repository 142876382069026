import moment from "moment";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Datepicker from "react-tailwindcss-datepicker";

import { loggerService } from "../../../services";
import dateService from "../../../services/date.service";
import { getAuditData } from "../audit-raw-utilities";
import { IAuditData } from "./audit-logs-data.component";

interface AuditRowInterface {
  setRawData: (rawData: IAuditData[]) => void;
  setTotalRows: (value: number) => void;
  setSelectedDateValue: (value: ISelectedDate) => void;
}

interface ISelectedDate {
  startDate: Date;
  endDate: Date;
}

export interface IDateRange {
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
}

const AuditTime: React.FC<AuditRowInterface> = ({
  setRawData,
  setTotalRows,
  setSelectedDateValue,
}) => {
  const [dateRange, setDateRange] = useState<IDateRange>({} as IDateRange);

  const [activeBtn, setActiveBtn] = useState(null);

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const [currentSelectedDates, setCurrentSelectedDates] = useState({
    startDate: null,
    endDate: null,
  });

  const handleBtns = async (id) => {
    // id 1 = pastweek, 2 = past2weeks, 3 = pastmonth, 4 = past6months
    let startDate;
    if (id === 1) {
      setActiveBtn(1);
      startDate = moment().subtract(7, "days");
    } else if (id === 2) {
      setActiveBtn(2);
      startDate = moment().subtract(2, "weeks");
    } else if (id === 3) {
      setActiveBtn(3);
      startDate = moment().subtract(1, "months");
    } else {
      setActiveBtn(4);
      startDate = moment().subtract(6, "months");
    }
    let endDate = moment().toDate();
    try {
      setSelectedDateValue({ startDate: startDate, endDate: endDate });
      setCurrentSelectedDates({ startDate: startDate, endDate: endDate });
      await getAuditData(startDate, endDate, setRawData, 1, setTotalRows);
    } catch (error) {
      loggerService.error(error);
    }
  };

  const handleDateRangeSubmit = async (values) => {
    setActiveBtn(null);
    setDateRange({
      dateRange: {
        startDate: values.startDate,
        endDate: values.endDate,
      },
    });
    setSelectedDateValue({
      startDate: values.startDate,
      endDate: values.endDate,
    });
    setCurrentSelectedDates({
      startDate: values.startDate,
      endDate: values.endDate,
    });
    try {
      await getAuditData(
        values.startDate,
        values.endDate,
        setRawData,
        1,
        setTotalRows
      );
    } catch (error) {
      loggerService.error(error);
    }
  };

  return (
    <div className="flex items-center justify-between py-8">
      <div className="flex items-center justify-center cursor-pointer w-72">
        <Datepicker
          value={value}
          startFrom={moment().subtract(1, "months").toDate()}
          maxDate={moment().toDate()}
          showFooter={true}
          placeholder={
            "dateRange" in dateRange &&
            "startDate" in dateRange.dateRange &&
            "endDate" in dateRange.dateRange
              ? dateService.convertToDDMMYYYYFormat(
                  dateRange.dateRange.startDate
                ) +
                " to " +
                dateService.convertToDDMMYYYYFormat(dateRange.dateRange.endDate)
              : dateService.convertToDDMMYYYYFormat(new Date())
          }
          onChange={handleDateRangeSubmit}
        />
      </div>
      <div className="flex items-center justify-center">
        <button
          type="button"
          onClick={() => handleBtns(1)}
          className={`${
            activeBtn === 1
              ? "bg-primary text-white"
              : "bg-transparent text-gray-900"
          } py-2.5 px-5 mr-2 mb-2 text-sm font-medium focus:outline-none rounded-lg border border-gray-300 `}
        >
          Past Week
        </button>
        <button
          type="button"
          onClick={() => handleBtns(2)}
          className={`${
            activeBtn === 2
              ? "bg-primary text-white"
              : "bg-transparent text-gray-900"
          } py-2.5 px-5 mr-2 mb-2 text-sm font-medium focus:outline-none rounded-lg border border-gray-300 `}
        >
          Past 2 Weeks
        </button>
        <button
          onClick={() => handleBtns(3)}
          type="button"
          className={`${
            activeBtn === 3
              ? "bg-primary text-white"
              : "bg-transparent text-gray-900"
          } py-2.5 px-5 mr-2 mb-2 text-sm font-medium focus:outline-none rounded-lg border border-gray-300 `}
        >
          Past Month
        </button>
        <button
          type="button"
          onClick={() => handleBtns(4)}
          className={`${
            activeBtn === 4
              ? "bg-primary text-white"
              : "bg-transparent text-gray-900"
          } py-2.5 px-5 mr-2 mb-2 text-sm font-medium focus:outline-none rounded-lg border border-gray-300 `}
        >
          Past 6 Months
        </button>
      </div>
    </div>
  );
};

export default AuditTime;

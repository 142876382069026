import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ILanguage, IModalConfirm } from "../../interfaces";
import { loggerService, networkService } from "../../services";
import GenericTable from "../shared/components/table.component";
import LanguageInput from "./components/language-input.component";
import ModalConfirm from "../shared/components/modal-confirm.component";
import { ModalConfirmModel } from "../../models";
import { useProjectData } from "../shared/hooks";

interface DeleteLanguageInterface {
  languageData: ILanguage[];
  setLanguageData: (languageData: ILanguage[]) => void;
  language: ILanguage;
}

const DeleteLanguage: React.FC<DeleteLanguageInterface> = ({
  languageData,
  setLanguageData,
  language,
}) => {
  const [modalConfirm, setModalConfirm] = React.useState<IModalConfirm>(
    () => new ModalConfirmModel()
  );

  const handleDelete = async (id) => {
    try {
      setModalConfirm({
        open: true,
        title: "Remove Language?",
        message: `By removing the language all the translations will be lost`,
        onConfirm: async () => {
          try {
            const data = await networkService.delete<ILanguage>(`/languages/${id}`);
            setLanguageData(languageData.filter((language) => language.id !== id));
            // close modal
            setModalConfirm((prevState) => ({ ...prevState, open: false }));
          } catch (error) {
            loggerService.error(error.mesaage);
          }
        },
      });

    } catch (error) {
      loggerService.error(error);
    }
  };

  return (
    <div className="flex items-center lg:justify-end">
      <button
        onClick={(e) => handleDelete(language.id)}
        className="flex items-center justify-center p-2 space-x-2 text-sm text-center text-gray-500 transition-colors duration-200 transform rounded-full focus:outline-none hover:text-red-500"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 4H3.33333H14"
            stroke="currentColor"
            strokeLinecap="round"
          />
          <path
            d="M5.33333 4.00016V2.66683C5.33333 2.31321 5.4738 1.97407 5.72385 1.72402C5.9739 1.47397 6.31304 1.3335 6.66666 1.3335H9.33333C9.68695 1.3335 10.0261 1.47397 10.2761 1.72402C10.5262 1.97407 10.6667 2.31321 10.6667 2.66683V4.00016M12.6667 4.00016V13.3335C12.6667 13.6871 12.5262 14.0263 12.2761 14.2763C12.0261 14.5264 11.687 14.6668 11.3333 14.6668H4.66666C4.31304 14.6668 3.9739 14.5264 3.72385 14.2763C3.4738 14.0263 3.33333 13.6871 3.33333 13.3335V4.00016H12.6667Z"
            stroke="currentColor"
            strokeLinecap="round"
          />
          <path
            d="M6.66667 7.3335V11.3335"
            stroke="currentColor"
            strokeLinecap="round"
          />
          <path
            d="M9.33333 7.3335V11.3335"
            stroke="currentColor"
            strokeLinecap="round"
          />
        </svg>

        <span>Remove</span>
      </button>
      <ModalConfirm {...modalConfirm} setProps={setModalConfirm} />
    </div>
  );
};

const Languages: React.FC = () => {
  const [languageData, setLanguageData] = useState<ILanguage[]>([]);

  const location = useLocation();
  const { projectData, projectId, projectDetails } = useProjectData();

  const getData = async (projectId) => {
    try {
      const data = await networkService.get<ILanguage[]>(`/languages?module=${projectId}`);
      setLanguageData(data);
    } catch (error) {
      loggerService.error(error);
    }
  };

  useEffect(() => {
    getData(projectId);
  }, [location.pathname]);

  const handleAddLanguage = async (language: ILanguage) => {
    try {
      const isLanguageAlreadyAdded = languageData.find((lang) => lang.name === language.name);
      if (!isLanguageAlreadyAdded) {
        language.module = projectId;
        delete language.id;
        const data = await networkService.post<ILanguage>(`/languages?module=${projectId}`, language);
        setLanguageData([...languageData, data]);
      } else {
        toast.error(`${language.name} is already selected`);
      }
    } catch (error) {
      loggerService.error(error);
    }
  };

  const colDefs = [{
    name: "Name",
    type: "string",
    field: "name"
  }];

  return (
    <section className="px-4 py-6 lg:px-8 sm:px-6 ">
      <div className="flex flex-col">
        <div className="-my-2  xl:-mx-8">
          <div className="block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className=" rounded-xl divide-y">
              {languageData.length > 0 && (
                <GenericTable
                  column={colDefs}
                  row={languageData.map((item) => ({
                    name: item.name,
                    remove: (
                      <DeleteLanguage
                        language={item}
                        languageData={languageData}
                        setLanguageData={setLanguageData}
                      />
                    ),
                  }))}
                  searchField="name"
                  searchTerm=""
                />
              )}
              <div className="flex items-center space-x-3 text-gray-700 mr-10 bg-white w-full py-3 px-6">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 3.75V14.25"
                    stroke="#546CCC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M3.75 9H14.25"
                    stroke="#546CCC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>

                <LanguageInput handleAddLanguage={handleAddLanguage} />
              </div>
              {/* <table className="min-w-full">
                <thead>
                  <tr>
                    <th scope="col" className="whitespace-nowrap"></th>

                    <th scope="col" className="whitespace-nowrap"></th>
                  </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-200 rounded-md">
                  {languageData.map((language, index) => (
                    <tr
                      key={index}
                      className="transition-colors duration-300 hover:bg-gray-50">
                      <td className="px-6 py-4 text-lg font-medium text-gray-800 whitespace-nowrap">
                        {language.name}
                      </td>

                      <td className="px-6 py-6 text-sm text-gray-500 whitespace-nowrap">
                        <div className="flex items-center lg:justify-end">
                          <button
                            onClick={(e) => handleDelete(language.id)}
                            className="flex items-center justify-center p-2 space-x-2 text-sm text-center text-gray-500 transition-colors duration-200 transform rounded-full focus:outline-none hover:text-red-500">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M2 4H3.33333H14"
                                stroke="currentColor"
                                strokeLinecap="round"
                              />
                              <path
                                d="M5.33333 4.00016V2.66683C5.33333 2.31321 5.4738 1.97407 5.72385 1.72402C5.9739 1.47397 6.31304 1.3335 6.66666 1.3335H9.33333C9.68695 1.3335 10.0261 1.47397 10.2761 1.72402C10.5262 1.97407 10.6667 2.31321 10.6667 2.66683V4.00016M12.6667 4.00016V13.3335C12.6667 13.6871 12.5262 14.0263 12.2761 14.2763C12.0261 14.5264 11.687 14.6668 11.3333 14.6668H4.66666C4.31304 14.6668 3.9739 14.5264 3.72385 14.2763C3.4738 14.0263 3.33333 13.6871 3.33333 13.3335V4.00016H12.6667Z"
                                stroke="currentColor"
                                strokeLinecap="round"
                              />
                              <path
                                d="M6.66667 7.3335V11.3335"
                                stroke="currentColor"
                                strokeLinecap="round"
                              />
                              <path
                                d="M9.33333 7.3335V11.3335"
                                stroke="currentColor"
                                strokeLinecap="round"
                              />
                            </svg>

                            <span>Remove</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}

                  <tr className="transition-colors duration-300 hover:bg-gray-50">
                    <td className="w-full px-6 py-6 whitespace-nowrap">
                      <div className="flex items-center space-x-3 text-gray-700 mr-10">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9 3.75V14.25"
                            stroke="#546CCC"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                          <path
                            d="M3.75 9H14.25"
                            stroke="#546CCC"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                        </svg>

                        <LanguageInput handleAddLanguage={handleAddLanguage} />
                      </div>
                    </td>

                    <td className="px-6 py-6 whitespace-nowrap"></td>
                  </tr>
                </tbody>
              </table> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Languages;
